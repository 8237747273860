import React, { Component } from 'react';
import {resolveVariables} from 'functions';

import {
    Button
} from 'library';

class TextAutocomplete extends Component {
    static component_name = 'TextAutocomplete';

    constructor(props) {
        super(props);

        this.state = {
            key_position: 0,
            show_options: false,
            search_string: ''
        };

        this.full_input_container = React.createRef();
        this.text_input = React.createRef();
        this.check_for_tab = this.check_for_tab.bind(this);
        this.check_for_blur = this.check_for_blur.bind(this);
        this.focus = this.focus.bind(this);
        this.autocomplete_select = this.autocomplete_select.bind(this);
        this.handle_change = this.handle_change.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.check_for_blur, false);
        document.addEventListener('keydown', this.check_for_tab, false);
    }
    componentWillUnmount(){
        document.removeEventListener('mousedown', this.check_for_blur, false);
        document.removeEventListener('keydown', this.check_for_tab, false);
    }

    check_for_blur(e) {
        if (this.full_input_container.current.contains(e.target)){
            return;
        }

        if (this.props.blur){
            this.props.blur(e);
        }

        this.setState({
            search_string: '',
            show_options: false
        });
    }

    focus(e){
        this.setState({
            show_options: true
        });
    }

    check_for_tab(e) {
        if (e.key === "Tab" && this.full_input_container.current.contains(document.activeElement)) {
            this.props.tab(e);
        }
    }

    autocomplete_select(e) {
        var value = e.currentTarget.getAttribute('num');

        var formState = {};
        formState[this.props.name] = value;
        this.props.setFormState(formState);
        this.setState({
            search_string: '',
            show_options: false
        });
    }

    handle_change(e){
        this.setState({
            search_string: e.currentTarget.value,
            show_options: true
        });
        //this.props.handleChange(e);
    }

    render() {
        var layout = '';
        if (this.props.layout) {
            layout = this.props.layout;
        }

        var i = 0;
        var options = [];
        var optionsJSX = null;
        if ('options' in this.props){
            for (var key in this.props.options){
                var value = this.props.options[key];
                if (value.toLowerCase().indexOf(this.state.search_string.toLowerCase()) > -1){
                    var type = "default";
                    if (i == this.state.key_position){
                        type += ' active';
                    }

                    options.push(
                        <div class="tagItem"><Button onClick={this.autocomplete_select} type={type} text={key} num={value} style={{}} /></div>
                    );
                }

                i += 1;
            }
        }
        if (this.state.show_options && options.length > 0){
            optionsJSX =
            <div className='completebox' style={{}}>
                {options}
            </div>;
        }

        var label = null;
        if (this.props.label && this.props.label != ''){
            label = <label>{this.props.label}</label>;
        }

        return (
            <div className={'search_me_input ' + this.props.className} style={{position:'relative'}} ref={this.full_input_container} >
                <div className={"form-group " + this.props.layout}>
                    {label}
                    <input type="text" className="form-control" name={this.props.name}
                        onChange={this.handle_change} value={this.state.search_string || this.props.value} onFocus={this.focus}
                        placeholder={this.props.placeholder} autoComplete="off" ref={this.text_input}
                    />
                </div>

                {optionsJSX}
            </div>
        )


    }
}

export default TextAutocomplete;

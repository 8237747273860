import React, { Component } from 'react';
import {Wrapper, Container, Header, Div, Paragraph, FormWithChildren, DateTimePicker, TextInput, Select, TextArea, UserSearchInput, SignatureInput, ImageInput, Accordion} from 'library';

function get_form_elements(){
    var elements = [
		<Accordion index={10066} header={<div className={"question-card"} name={"10066_0"} excel_reference={1} style={{fontSize: '24px'}}  >
				<Paragraph text={"QUALITY CONTROL INSPECTION"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"10067_0,10070_0,10071_0,10072_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"10067_0"} label={"Select Position Below if QC Form is Completed by a Supervisor or Manager"} options={[{"text": "Supervisor", "value": "Supervisor"}, {"text": "Area Supervisor", "value": "Area Supervisor"}, {"text": "Dir/Mgr of Operations", "value": "Dir/Mgr of Operations"}, {"text": "Vice President", "value": "Vice President"}]} radio={true} excel_reference={2}  />
			<TextInput className={"question-card"} name={"10070_0"} label={"Area:"} excel_reference={5} required={true}  />
			<TextInput className={"question-card"} name={"10071_0"} label={"Completed by:"} excel_reference={6} required={true}  />
			<TextInput className={"question-card"} name={"10072_0"} label={"Supervisor"} excel_reference={7} required={true}  />
		</Accordion>,
		<Accordion index={10073} header={<div className={"question-card"} name={"10073_0"} excel_reference={8} anchor={true} anchor_text={"MAIN ENTRANCE / LOBBY / ELEVAT"} style={{fontSize: '24px'}}  >
				<Paragraph text={"MAIN ENTRANCE / LOBBY / ELEVATORS"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"10074_0,10075_0,10076_0,10077_0,10078_0,10079_0,10080_0,10081_0,10082_0,10083_0,10084_0"} sub_total={"10088_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"10074_0"} label={"Door Glass / Side Glass - Main entrance"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={9}  />
			<Select className={"question-card"} name={"10075_0"} label={"Walk Off Mats / Corners & Edges"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={10}  />
			<Select className={"question-card"} name={"10076_0"} label={"Carpets Vacuumed  / Spot Clean Stains"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={11}  />
			<Select className={"question-card"} name={"10077_0"} label={"Floors - Sweep, Mop, Buff & Vacuum"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={12}  />
			<Select className={"question-card"} name={"10078_0"} label={"Main Elevator Cabs - Interior & Exterior Directional Plates, Tracks"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={13}  />
			<Select className={"question-card"} name={"10079_0"} label={"Escalators & Landings"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={14}  />
			<Select className={"question-card"} name={"10080_0"} label={"Ice Machine"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={15}  />
			<Select className={"question-card"} name={"10081_0"} label={"High Low Dusting / A/C Vents"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={16}  />
			<Select className={"question-card"} name={"10082_0"} label={"Trash Containers / Ash Urns"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={17}  />
			<Select className={"question-card"} name={"10083_0"} label={"Lobby Furniture & Fixtures"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={18}  />
			<Select className={"question-card"} name={"10084_0"} label={"Building Exterior (police area)"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={19}  />
			<TextInput className={"question-card"} name={"10085_0"} label={"COMMENTS "} excel_reference={20} fail_comment={true}  />
			<div className={"question-card"} name={"10086_0"} excel_reference={21}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"10087_0"} excel_reference={22}  />
			<TextInput className={"question-card"} name={"10088_0"} label={"Total Rating"} excel_reference={23} excel={'+Q9+Q10+Q11+Q12+Q13+Q14+Q15+Q16+Q17+Q18+Q19'}  />
		</Accordion>,
		<Accordion index={10089} header={<div className={"question-card"} name={"10089_0"} excel_reference={24} anchor={true} anchor_text={"COMMON AREAS"} style={{fontSize: '24px'}}  >
				<Paragraph text={"COMMON AREAS"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"10090_0,10091_0,10092_0,10093_0,10094_0,10095_0,10096_0,10097_0,10098_0"} sub_total={"10102_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"10090_0"} label={"Drinking Fountains         "} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={25}  />
			<Select className={"question-card"} name={"10091_0"} label={"Stairway"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={26}  />
			<Select className={"question-card"} name={"10092_0"} label={"Elevators, Cabs, Tracks, Panels"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={27}  />
			<Select className={"question-card"} name={"10093_0"} label={"Telephones"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={28}  />
			<Select className={"question-card"} name={"10094_0"} label={"Interior Glass"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={29}  />
			<Select className={"question-card"} name={"10095_0"} label={"High Low Dusting / A/C Vents"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={30}  />
			<Select className={"question-card"} name={"10096_0"} label={"Floors - Sweep, Mop, Buff & Vacuum"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={31}  />
			<Select className={"question-card"} name={"10097_0"} label={"Carpets Vacuumed  / Spot Clean Stains"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={32}  />
			<Select className={"question-card"} name={"10098_0"} label={"Wall Spotting / Corners & Edges"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={33}  />
			<TextInput className={"question-card"} name={"10099_0"} label={"COMMENTS"} excel_reference={34} fail_comment={true}  />
			<div className={"question-card"} name={"10100_0"} excel_reference={35}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"10101_0"} excel_reference={36}  />
			<TextInput className={"question-card"} name={"10102_0"} label={"Total Rating"} excel_reference={37} excel={'Q25+Q26+Q27+Q28+Q29+Q30+Q31+Q32+Q33'}  />
		</Accordion>,
		<Accordion index={10103} header={<div className={"question-card"} name={"10103_0"} excel_reference={38} anchor={true} anchor_text={"OFFICES / CONFERENCE ROOMS"} style={{fontSize: '24px'}}  >
				<Paragraph text={"OFFICES / CONFERENCE ROOMS"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"10104_0,10105_0,10106_0,10107_0,10108_0,10109_0,10110_0,10111_0,10112_0,10113_0,10114_0"} sub_total={"10118_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"10104_0"} label={"Trash Removed & Liner Replaced"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={39}  />
			<Select className={"question-card"} name={"10105_0"} label={"Carpets Vacuumed  / Spot Clean Stains"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={40}  />
			<Select className={"question-card"} name={"10106_0"} label={"Floors - Sweep, Mop, Buff & Vacuum"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={41}  />
			<Select className={"question-card"} name={"10107_0"} label={"Vacuum / Sweep Corners, Edges, & Under Furniture"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={42}  />
			<Select className={"question-card"} name={"10108_0"} label={"Interior Glass"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={43}  />
			<Select className={"question-card"} name={"10109_0"} label={"Telephones"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={44}  />
			<Select className={"question-card"} name={"10110_0"} label={"High Low Dusting"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={45}  />
			<Select className={"question-card"} name={"10111_0"} label={"Blinds / Window Sills Dusted"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={46}  />
			<Select className={"question-card"} name={"10112_0"} label={"Furniture, File Cabinets, Counter Tops, Etc."} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={47}  />
			<Select className={"question-card"} name={"10113_0"} label={"Wall Spotting (Finger Prints, Smudges, Etc.)"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={48}  />
			<Select className={"question-card"} name={"10114_0"} label={"A/C Vents & Return Air Vents"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={49}  />
			<TextInput className={"question-card"} name={"10115_0"} label={"COMMENTS"} excel_reference={50} fail_comment={true}  />
			<div className={"question-card"} name={"10116_0"} excel_reference={51}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"10117_0"} excel_reference={52}  />
			<TextInput className={"question-card"} name={"10118_0"} label={"Total Rating"} excel_reference={53} excel={'Q39+Q40+Q41+Q42+Q43+Q44+Q45+Q46+Q47+Q48+Q49'}  />
		</Accordion>,
		<Accordion index={10119} header={<div className={"question-card"} name={"10119_0"} excel_reference={54} anchor={true} anchor_text={"REST ROOMS / LOCKER ROOMS"} style={{fontSize: '24px'}}  >
				<Paragraph text={"REST ROOMS / LOCKER ROOMS"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"10120_0,10121_0,10122_0,10123_0,10124_0,10125_0,10126_0,10127_0,10128_0,10129_0"} sub_total={"10133_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"10120_0"} label={"Trash Removed & Liner Replaced"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={55}  />
			<Select className={"question-card"} name={"10121_0"} label={"Clean & Polish Stainless Steel Fixtures"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={56}  />
			<Select className={"question-card"} name={"10122_0"} label={"Replenish Tissue, Towels, & Soap Dispensers"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={57}  />
			<Select className={"question-card"} name={"10123_0"} label={"Mirrors & Frames"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={58}  />
			<Select className={"question-card"} name={"10124_0"} label={"High & Low Dusting"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={59}  />
			<Select className={"question-card"} name={"10125_0"} label={"Sinks, Faucets, & Counter Tops"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={60}  />
			<Select className={"question-card"} name={"10126_0"} label={"Floors Swept, Mopped"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={61}  />
			<Select className={"question-card"} name={"10127_0"} label={"Toilets / Urinals - Bowls, Seats, Rim, Plumbing Fixtures"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={62}  />
			<Select className={"question-card"} name={"10128_0"} label={"Partition Walls, Doors, Etc."} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={63}  />
			<Select className={"question-card"} name={"10129_0"} label={"A/C Vents & Return Air Vents"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={64}  />
			<TextInput className={"question-card"} name={"10130_0"} label={"COMMENTS"} excel_reference={65} fail_comment={true}  />
			<div className={"question-card"} name={"10131_0"} excel_reference={66}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"10132_0"} excel_reference={67}  />
			<TextInput className={"question-card"} name={"10133_0"} label={"Total Rating "} excel_reference={68} excel={'Q55+Q56+Q57+Q58+Q59+Q60+Q61+Q62+Q63+Q64'}  />
		</Accordion>,
		<Accordion index={10134} header={<div className={"question-card"} name={"10134_0"} excel_reference={69} anchor={true} anchor_text={" BREAK ROOMS / CAFETERIA"} style={{fontSize: '24px'}}  >
				<Paragraph text={" BREAK ROOMS / CAFETERIA"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"10135_0,10136_0,10137_0,10138_0,10139_0,10140_0,10141_0"} sub_total={"10145_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"10135_0"} label={"Floors Swept, Mopped, Vacuum & Buff"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={70}  />
			<Select className={"question-card"} name={"10136_0"} label={"High & Low Dusting"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={71}  />
			<Select className={"question-card"} name={"10137_0"} label={"Tables / Chairs"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={72}  />
			<Select className={"question-card"} name={"10138_0"} label={"A/C Vents & Return Air Vents"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={73}  />
			<Select className={"question-card"} name={"10139_0"} label={"Trash  removed & liners replaced"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={74}  />
			<Select className={"question-card"} name={"10140_0"} label={"Counter Tops, Sinks, & Back Splash Areas"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={75}  />
			<Select className={"question-card"} name={"10141_0"} label={"Wall Spotting (Finger Prints, Smudges)"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={76}  />
			<TextInput className={"question-card"} name={"10142_0"} label={"COMMENTS"} excel_reference={77} fail_comment={true}  />
			<div className={"question-card"} name={"10143_0"} excel_reference={78}  >
				<Paragraph text={"Take a Photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"10144_0"} excel_reference={79}  />
			<TextInput className={"question-card"} name={"10145_0"} label={"Total Rating"} excel_reference={80} excel={'Q70+Q71+Q72+Q73+Q74+Q75+Q76'}  />
		</Accordion>,
		<Accordion index={10146} header={<div className={"question-card"} name={"10146_0"} excel_reference={81} anchor={true} anchor_text={"JANITOR CLOSET / STORAGE AREA"} style={{fontSize: '24px'}}  >
				<Paragraph text={"JANITOR CLOSET / STORAGE AREA"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"10147_0,10148_0,10149_0,10752_0,11540_0,11541_0"} sub_total={"10153_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"10147_0"} label={"Mop Sink & Floors"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={82}  />
			<Select className={"question-card"} name={"10148_0"} label={"Supplies/Equipment (Stored neat & orderly)"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={83}  />
			<Select className={"question-card"} name={"10149_0"} label={"SDS binders in closet and chemicals labeled"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={84}  />
			<Select className={"question-card"} name={"10752_0"} label={"All chemicals in the closet are approved"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={85}  />
			<Select className={"question-card"} name={"11540_0"} label={"Have all checklists been completed? "} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={86}  />
			<Select className={"question-card"} name={"11541_0"} label={"Have all employees completed the sign in/out sheet for their shift?  "} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={87}  />
			<TextInput className={"question-card"} name={"10150_0"} label={"COMMENTS"} excel_reference={88} fail_comment={true}  />
			<div className={"question-card"} name={"10151_0"} excel_reference={89}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"10152_0"} excel_reference={90}  />
			<TextInput className={"question-card"} name={"10153_0"} label={"Rating Total"} excel_reference={91} excel={'Q82+Q83+Q84+Q85+Q86+Q87'}  />
		</Accordion>,
		<Accordion index={10156} header={<div className={"question-card"} name={"10156_0"} excel_reference={94} anchor={true} anchor_text={"CAN WE PROVIDE ADDITIONAL SERV"} style={{fontSize: '24px'}}  >
				<Paragraph text={"CAN WE PROVIDE ADDITIONAL SERVICES (TAGS)?  What extra services can we suggest to make the buildings look better?"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"10157_0,10158_0,10159_0,10160_0,10161_0,10162_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"10157_0"} label={"Do any areas need pressure washing? "} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={95}  />
			<Select className={"question-card"} name={"10158_0"} label={"Do we need to shampoo carpet before periodics are due?"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={96}  />
			<Select className={"question-card"} name={"10159_0"} label={"Do we need to strip & wax floor before periodics are due?"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={97}  />
			<Select className={"question-card"} name={"10160_0"} label={"Do we need to provide any exterior window cleaning?"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={98}  />
			<Select className={"question-card"} name={"10161_0"} label={"Can we provide a maintenance technician?"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={99}  />
			<TextInput className={"question-card"} name={"10162_0"} label={"If the answer to a question above is yes please comment below"} excel_reference={100}  />
		</Accordion>,
		<TextInput className={"question-card"} name={"10154_0"} label={"Grand Total"} excel_reference={92} anchor={true} anchor_text={"Grand Total"} excel={'Q23+Q37+Q53+Q68+Q80+Q91'}  />,
		<TextInput className={"question-card"} name={"10155_0"} label={"Percentage"} excel_reference={93} excel={'(Q92/54)*100'}  />,
    ];

    return elements;
}

export default get_form_elements;

import React from 'react';
import {injectStripe} from 'react-stripe-elements';
import {ajaxWrapper, run_functions} from 'functions';
import {Alert, FormWithChildren, TextInput, Button, Header} from 'library';


class StripeBankForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            account: null,
            loaded: false
        };


        this.get_account_link = this.get_account_link.bind(this);
        this.account_link_callback = this.account_link_callback.bind(this);
        this.verify_bank_account_callback = this.verify_bank_account_callback.bind(this);

        this.load_account_data = this.load_account_data.bind(this);
        this.account_data_callback = this.account_data_callback.bind(this);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.stripeCallback = this.stripeCallback.bind(this);
        this.stripeFailback = this.stripeFailback.bind(this);
    }

    componentDidMount(){
        this.load_account_data();
    }

    load_account_data(){
        var global_state = window.cmState.getGlobalState();
        var user = global_state.user;

        var url = '/api/stripe_manager/StripeAccount/?user_id=' + user.id + '&related=bank_accounts';
        ajaxWrapper("GET", url, {}, this.account_data_callback);
    }

    account_data_callback(value){
        if (value.length > 0){
            this.setState({account: value['0']['StripeAccount']});

            if (this.state.account.bank_accounts.length > 0) {
                var url = '/stripe/verify_bank_account/';
                ajaxWrapper("POST", url, {}, this.verify_bank_account_callback);
            }
            else {
                this.setState({laoded: true});
            }
        }
    }

    get_account_link() {
        ajaxWrapper("POST", '/stripe/account_link/', {}, this.account_link_callback);
    }

    account_link_callback(value) {
        window.location = value['url'];
    }

    verify_bank_account_callback(value) {
        console.log(value);
        var verification = value['verification']['verification'];

        this.setState({
            verification: verification,
            loaded: true
        });
    }

    handleSubmit(form_state, callback) {
        var global_state = window.cmState.getGlobalState();
        var user = global_state.user;
        var artist_name = user.first_name + ' ' + user.last_name;

        // Within the context of `Elements`, this call to createToken knows which Element to
        // tokenize, since there's only one in this group.
        this.props.stripe.createToken('bank_account', {
            country: 'US',
            currency: 'usd',
            routing_number: form_state['routing'],
            account_number: form_state['account'],
            account_holder_name: artist_name,
            account_holder_type: 'individual',
        })
        .then(function(result) {
            // Handle result.error or result.token
            console.log('Result', result);
            if ('error' in result){
                this.stripeFailback(result['error']['message']);
                callback();
                return false;
            }

            console.log("Stripe Bank Account Request", {'token': result.token.id});

            this.setState({callback: callback});
            ajaxWrapper("POST", '/stripe/bank_account/', {'token': result.token.id}, this.stripeCallback, this.stripeFailback);
        }.bind(this));

        this.setState({saving: true});
    }

    stripeCallback(value) {
        console.log("Value", value);
        if ('error' in value){
            this.stripeFailback(value['error']);
        }

        this.state.callback();
        this.load_account_data();
    }

    stripeFailback(value){
        this.setState({error: value});
    }

    render() {
        var message = null;
        if (this.state.error){
            message = <Alert type={'danger'} text={this.state.error} />;
        }

        var account_link_button = null;
        var bank_account_form = null;
        var validation = null;
        if (!(this.state.loaded)){
            validation = <div>
                <Header size={3} text={'Checking Stripe status, please wait...'} />
            </div>
        }
        else if (!(this.state.account)){
            account_link_button = <Button onClick={this.get_account_link} text={'Setup Stripe Account'} type={'success'} />;
        }
        else if (this.state.account.bank_accounts.length == 0) {
            bank_account_form = <FormWithChildren submit={this.handleSubmit} submit_text={'Submit Account Info'} >
                <div className="form-group">
                    <label>Bank Account Details</label>
                    <TextInput label={'Account Number'} name={'account'} required={true} />
                    <TextInput label={'Routing Number'} name={'routing'} required={true} />
                </div>
                <img src="https://componentmadness-images.s3.amazonaws.com/19_11_21_20_51_00_secure-stripe-payment-logo.png"
                    style={{width: "400px", float: "right", maxWidth: '100%'}} />
            </FormWithChildren>;
        }
        else if (this.state.verification && (this.state.verification.errors.length > 0 || this.state.verification.fields_needed.length > 0)) {
            validation = [];

            var errors = [];
            var fields_needed = [];

            if (this.state.verification.errors.length > 0){
                for (var i in this.state.verification.errors){
                    var error = this.state.verification.errors[i];
                    errors.push(<p>{error}</p>);
                }
                validation.push(<div>
                    <Header size={4} text={'Errors reported by Stripe'} />
                    {errors}
                </div>);
            }
            if (this.state.verification.fields_needed.length > 0){
                for (var i in this.state.verification.fields_needed){
                    var field_needed = this.state.verification.fields_needed[i];
                    fields_needed.push(<p>{field_needed}</p>);
                }
                validation.push(<div>
                    <Header size={4} text={'Outstanding Fields required by Stripe'} />
                    {fields_needed}
                </div>);
            }

            validation.push(<Button onClick={this.get_account_link} text={'Complete Stripe Account'} type={'success'} />);
        }
        else {
            validation = <div>
                <Header size={3} text={'Your account has been setup succesfully'} />
            </div>
        }


        return (
            <div className='card' >
                {message}
                {account_link_button}
                {bank_account_form}
                {validation}
            </div>
        );
    }
}


export default injectStripe(StripeBankForm);

import React, { Component } from 'react';
import {ajaxWrapper, resolveVariables} from 'functions';

import {Wrapper, PasswordResetRequest, Container, TextInput, Div, Image, Span, Link, Paragraph} from 'library';
import Navbar from 'projectLibrary/navbar.js';
import Footer from 'projectLibrary/footer.js';


class PasswordResetRequestPage extends Component {

    render() {

        return (<div>
			<Navbar />

			<Container order={"3"} style={{'padding-top': '20px'}} min_height={true} >
				<PasswordResetRequest >
					<TextInput name={"email"} required={true} placeholder={"your_email@domain.com"}  />
				</PasswordResetRequest>
			</Container>

			<Footer />
        </div>);
    }
}
export default PasswordResetRequestPage;

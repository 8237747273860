import React, { Component } from 'react';
import {Wrapper, Container, Header, Div, Paragraph, FormWithChildren, DateTimePicker, TextInput, Select, TextArea, UserSearchInput, SignatureInput, ImageInput, Accordion} from 'library';

function get_form_elements(){
    var elements = [
		<div className={"question-card"} name={"2747_0"} excel_reference={1}  >
				<Paragraph text={"Kleen-Tech PLUGGING-IN SCORECARD "} style={{margin:"0px", fontSize:"inherit"}} />
			</div>,
		<TextInput className={"question-card"} name={"2853_0"} label={"NAME "} excel_reference={2}  />,
		<TextInput className={"question-card"} name={"2854_0"} label={"REGION"} excel_reference={3}  />,
		<DateTimePicker className={"question-card"} name={"2855_0"} label={"DATE"} excel_reference={4}  />,
		<UserSearchInput className={"question-card"} name={"2856_0"} label={"Type and then CLICK on  your SUPERVISOR's e-mail address here."} excel_reference={5} required={true}  />,
		<div className={"question-card"} name={"2862_0"} excel_reference={6}  >
				<Paragraph text={"TOP 5 RESPONSIBILITIES"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>,
		<Select className={"question-card"} name={"2857_0"} label={"Manage Labor - Employee"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={7}  />,
		<Select className={"question-card"} name={"2863_0"} label={"Manage Labor - Manager"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={8}  />,
		<TextInput className={"question-card"} name={"2864_0"} label={"Manage Labor - Comments"} excel_reference={9}  />,
		<Select className={"question-card"} name={"2858_0"} label={"Consistent QCs/Checklists - Employee"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={10}  />,
		<Select className={"question-card"} name={"2865_0"} label={"Consistent QCs/Checklists - Manager"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={11}  />,
		<TextInput className={"question-card"} name={"2866_0"} label={"Consistent QCs/Checklists - Comments"} excel_reference={12}  />,
		<Select className={"question-card"} name={"2859_0"} label={"Manage Chemicals/Equipment - Employee"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={13}  />,
		<Select className={"question-card"} name={"2867_0"} label={"Manage Chemicals/Equipment - Manager"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={14}  />,
		<TextInput className={"question-card"} name={"2868_0"} label={"Manage Chemicals/Equipment - Comment"} excel_reference={15}  />,
		<Select className={"question-card"} name={"2860_0"} label={"Compliant with Customer Contacts - Employee"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={16}  />,
		<Select className={"question-card"} name={"2869_0"} label={"Compliant with Customer Contacts - Manager"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={17}  />,
		<TextInput className={"question-card"} name={"2870_0"} label={"Compliant with Customer Contacts - Comments"} excel_reference={18}  />,
		<Select className={"question-card"} name={"2861_0"} label={"Escalating, Collaborating, responding to HB Departments - Employee"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={19}  />,
		<Select className={"question-card"} name={"2871_0"} label={"Escalating, Collaborating, responding to HB Departments - Manager"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={20}  />,
		<TextInput className={"question-card"} name={"2872_0"} label={"Escalating, Collaborating, responding to HB Departments - Comments"} excel_reference={21}  />,
		<TextInput className={"question-card"} name={"2873_0"} label={"TOTAL SCORE - Employee"} excel_reference={22} excel={'Q7+Q10+Q13+Q16+Q19'}  />,
		<TextInput className={"question-card"} name={"2874_0"} label={"TOTAL SCORE - Manager"} excel_reference={23} excel={'Q8+Q11+Q14+Q17+Q20'}  />,
		<TextInput className={"question-card"} name={"2876_0"} label={"Difference"} excel_reference={24} excel={'Q22-Q23'}  />,
		<div className={"question-card"} name={"2875_0"} excel_reference={25}  >
				<Paragraph text={"(DO NOT GO TO THE FOLLOWING SECTION IF THE SCORE ABOVE DOES NOT EXCEED 40.  JUST HIT SUBMIT )"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>,
		<div className={"question-card"} name={"2749_0"} excel_reference={26}  >
				<Paragraph text={"PLUGGING IN (If responsibility does not apply score = 10)"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>,
		<div className={"question-card"} name={"2750_0"} excel_reference={27}  >
				<Paragraph text={"Management of Work Orders[Zero late WOs on WLM Agenda]"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>,
		<Select className={"question-card"} name={"2840_0"} label={"Management of Work Orders - Employee Score"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={28}  />,
		<Select className={"question-card"} name={"2752_0"} label={"Management of Work Orders - Manager's Score"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={29}  />,
		<TextInput className={"question-card"} name={"2753_0"} label={"Management of Work Orders - Comments"} excel_reference={30}  />,
		<div className={"question-card"} name={"2754_0"} excel_reference={31}  >
				<Paragraph text={"Consistent & Productive Daily Call w/ MC [Robust & 100% Completion of MC Daily Calls]"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>,
		<Select className={"question-card"} name={"2755_0"} label={"Consistent & Productive Daily Call w/ MC - Employee Score"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={32}  />,
		<Select className={"question-card"} name={"2756_0"} label={"Consistent & Productive Daily Call w/ MC - Manager's Score"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={33}  />,
		<TextInput className={"question-card"} name={"2757_0"} label={"Consistent & Productive Daily Call w/ MC - Comments"} excel_reference={34}  />,
		<div className={"question-card"} name={"2758_0"} excel_reference={35}  >
				<Paragraph text={"Meeting RTF Standards (Filing/Working)[Filing new & timely working/closing RTFs]"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>,
		<Select className={"question-card"} name={"2759_0"} label={"Meeting RTF Standards (Filing/Working) - Employee Score"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={36}  />,
		<Select className={"question-card"} name={"2760_0"} label={"Meeting RTF Standards (Filing/Working) - Manager's Score"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={37}  />,
		<TextInput className={"question-card"} name={"2761_0"} label={"Meeting RTF Standards (Filing/Working) - Comments"} excel_reference={38}  />,
		<div className={"question-card"} name={"2762_0"} excel_reference={39}  >
				<Paragraph text={"Compliant w/ Quality Control[Accomplished all scheduled QCs {self &amp; staff}]"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>,
		<Select className={"question-card"} name={"2763_0"} label={"Compliant w/ Quality Control - Employee Score"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={40}  />,
		<Select className={"question-card"} name={"2764_0"} label={"Compliant w/ Quality Control - Manager Score"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={41}  />,
		<TextInput className={"question-card"} name={"2765_0"} label={"Compliant w/ Quality Control - Comments"} excel_reference={42}  />,
		<div className={"question-card"} name={"2766_0"} excel_reference={43}  >
				<Paragraph text={"Completion Of Employee Checklists (All Accounts have EE Checklists)"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>,
		<Select className={"question-card"} name={"2767_0"} label={"Completion Of Employee Checklists - Employee Score"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={44}  />,
		<Select className={"question-card"} name={"2768_0"} label={"All Accounts have EE Checklists - Manager Score"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={45}  />,
		<TextInput className={"question-card"} name={"2769_0"} label={"All Accounts have EE Checklists - Comments"} excel_reference={46}  />,
		<div className={"question-card"} name={"2770_0"} excel_reference={47}  >
				<Paragraph text={"Participation In KEIT [100% attendance & robust participation]"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>,
		<Select className={"question-card"} name={"2771_0"} label={"Participation In KEIT - Employee Score"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={48}  />,
		<Select className={"question-card"} name={"2772_0"} label={"Participation In KEIT - Manager's Score"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={49}  />,
		<TextInput className={"question-card"} name={"2773_0"} label={"Participation In KEIT - Comments"} excel_reference={50}  />,
		<div className={"question-card"} name={"2774_0"} excel_reference={51}  >
				<Paragraph text={"LEADERSHIP (if responsibility does not apply score = 10)"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>,
		<div className={"question-card"} name={"2775_0"} excel_reference={52}  >
				<Paragraph text={"Active Participation/Presentation In WLM/DLM [Command of material - \\\"owns\\\" assigned accounts]"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>,
		<Select className={"question-card"} name={"2776_0"} label={"Active Participation/Presentation In WLM/DLM - Employee Score"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={53}  />,
		<Select className={"question-card"} name={"2777_0"} label={"Active Participation/Presentation In WLM/DLM - Manager's Score"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={54}  />,
		<TextInput className={"question-card"} name={"2778_0"} label={"Active Participation/Presentation In WLM/DLM - Comments"} excel_reference={55}  />,
		<div className={"question-card"} name={"2779_0"} excel_reference={56}  >
				<Paragraph text={"Create Great (RTF) ICAs [Focused on preventing recurrence]"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>,
		<Select className={"question-card"} name={"2780_0"} label={"Create Great (RTF) ICAs - Employee Score"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={57}  />,
		<Select className={"question-card"} name={"2781_0"} label={"Create Great (RTF) ICAs - Manager's Score"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={58}  />,
		<TextInput className={"question-card"} name={"2782_0"} label={"Create Great (RTF) ICAs - Comments"} excel_reference={59}  />,
		<div className={"question-card"} name={"2783_0"} excel_reference={60}  >
				<Paragraph text={"Supervise & Train Subordinates [Demonstrates EE oversight, direction & training]"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>,
		<Select className={"question-card"} name={"2784_0"} label={"Supervise & Train Subordinates - Employee Score"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={61}  />,
		<Select className={"question-card"} name={"2785_0"} label={"Supervise & Train Subordinates - Manager's Score"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={62}  />,
		<TextInput className={"question-card"} name={"2786_0"} label={"Supervise & Train Subordinates - Comments"} excel_reference={63}  />,
		<div className={"question-card"} name={"2787_0"} excel_reference={64}  >
				<Paragraph text={"Manage Labor Daily [Performs Daily labor review and knows who's working when & adjusts]"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>,
		<Select className={"question-card"} name={"2788_0"} label={"Manage Labor Daily - Employee Score"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={65}  />,
		<Select className={"question-card"} name={"2789_0"} label={"Manage Labor Daily - Manager's Score"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={66}  />,
		<TextInput className={"question-card"} name={"2790_0"} label={"Manage Labor Daily -Comments"} excel_reference={67}  />,
		<div className={"question-card"} name={"2791_0"} excel_reference={68}  >
				<Paragraph text={"Manage Supplies/Consumables/Equipment[Consistent inventory to keep track of what is being used before ordering supplies]"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>,
		<Select className={"question-card"} name={"2792_0"} label={"Manage Supplies/Consumables/Equipment - Employee Score"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={69}  />,
		<Select className={"question-card"} name={"2793_0"} label={"Manage Supplies/Consumables/Equipment - Manager's Score"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={70}  />,
		<TextInput className={"question-card"} name={"2794_0"} label={"Manage Supplies/Consumables/Equipment - Comments"} excel_reference={71}  />,
		<div className={"question-card"} name={"2795_0"} excel_reference={72}  >
				<Paragraph text={"CUSTOMER MANAGEMENT (If responsibility does not apply score = 10)"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>,
		<div className={"question-card"} name={"2796_0"} excel_reference={73}  >
				<Paragraph text={"Build Great Customer Relationships [Gets along great with the customers]"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>,
		<Select className={"question-card"} name={"2797_0"} label={"Build Great Customer Relationships - Employee Score"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={74}  />,
		<Select className={"question-card"} name={"2798_0"} label={"Build Great Customer Relationships - Manager's Score"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={75}  />,
		<TextInput className={"question-card"} name={"2799_0"} label={"Build Great Customer Relationships - Comments"} excel_reference={76}  />,
		<div className={"question-card"} name={"2800_0"} excel_reference={77}  >
				<Paragraph text={"Follow-up w/ External Customers [Known for accomplishing commitments]"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>,
		<Select className={"question-card"} name={"2801_0"} label={"Follow-up w/ External Customers - Employee Score"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={78}  />,
		<Select className={"question-card"} name={"2802_0"} label={"Follow-up w/ External Customers - Manager's Score"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={79}  />,
		<TextInput className={"question-card"} name={"2803_0"} label={"Follow-up w/ External Customers - Comments"} excel_reference={80}  />,
		<div className={"question-card"} name={"2804_0"} excel_reference={81}  >
				<Paragraph text={"Prompt Attention To Customer Issues [Customer describes you as \\\"highly responsive\\\"]"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>,
		<Select className={"question-card"} name={"2805_0"} label={"Prompt Attention To Customer Issues - Employee Score"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={82}  />,
		<Select className={"question-card"} name={"2806_0"} label={"Prompt Attention To Customer Issues - Manager's Score"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={83}  />,
		<TextInput className={"question-card"} name={"2807_0"} label={"Prompt Attention To Customer Issues "} excel_reference={84}  />,
		<div className={"question-card"} name={"2808_0"} excel_reference={85}  >
				<Paragraph text={"Customer Feedback Exceeds Expectations [Receives customer rating of 8 or > monthly]"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>,
		<Select className={"question-card"} name={"2809_0"} label={"Customer Feedback Exceeds Expectations - Employee Score"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={86}  />,
		<Select className={"question-card"} name={"2810_0"} label={"Customer Feedback Exceeds Expectations -   Manager's Score"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={87}  />,
		<TextInput className={"question-card"} name={"2811_0"} label={"Customer Feedback Exceeds Expectations - Comments"} excel_reference={88}  />,
		<div className={"question-card"} name={"2812_0"} excel_reference={89}  >
				<Paragraph text={"Follow-up w/ Internal Customers [Prompts Ops &amp; HB Leadership not visa-versa]"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>,
		<Select className={"question-card"} name={"2813_0"} label={"Follow-up w/ Internal Customers - Employee Score"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={90}  />,
		<Select className={"question-card"} name={"2814_0"} label={"Follow-up w/ Internal Customers -   Manager's Score"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={91}  />,
		<TextInput className={"question-card"} name={"2815_0"} label={"Follow-up w/ Internal Customers - Comments"} excel_reference={92}  />,
		<div className={"question-card"} name={"2816_0"} excel_reference={93}  >
				<Paragraph text={"COLLABORATION (If responsibility does not apply score = 10)"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>,
		<div className={"question-card"} name={"2817_0"} excel_reference={94}  >
				<Paragraph text={"Timely Payroll Submission [No reported open punches]"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>,
		<Select className={"question-card"} name={"2818_0"} label={"Timely Payroll Submission - Employee Score"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={95}  />,
		<Select className={"question-card"} name={"2819_0"} label={"Timely Payroll Submission - Manager Score"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={96}  />,
		<TextInput className={"question-card"} name={"2820_0"} label={"Timely Payroll Submission - Comments"} excel_reference={97}  />,
		<div className={"question-card"} name={"2821_0"} excel_reference={98}  >
				<Paragraph text={"Collaborate w/ HR (Includes Training) [Effectively communicates with HR regularly]"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>,
		<Select className={"question-card"} name={"2822_0"} label={"Collaborate w/ HR - Employee Score"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={99}  />,
		<Select className={"question-card"} name={"2823_0"} label={"Collaborate w/ HR - Manager Score"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={100}  />,
		<TextInput className={"question-card"} name={"2824_0"} label={"Collaborate w/ HR"} excel_reference={101}  />,
		<div className={"question-card"} name={"2825_0"} excel_reference={102}  >
				<Paragraph text={"Collaborate w/ S&P [Provides 1 good Lead/week & attends Walk-Thrus)"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>,
		<Select className={"question-card"} name={"2826_0"} label={"Collaborate w/ S&P - Employee Score"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={103}  />,
		<Select className={"question-card"} name={"2827_0"} label={"Collaborate w/ S&P - Manager's Score"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={104}  />,
		<TextInput className={"question-card"} name={"2828_0"} label={"Collaborate w/ S&P"} excel_reference={105}  />,
		<div className={"question-card"} name={"2829_0"} excel_reference={106}  >
				<Paragraph text={"Sell & Submit TAGs [Timely/accurate TAG recording/reporting @ +20%] Safeway - On Demand Work Orders"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>,
		<Select className={"question-card"} name={"2830_0"} label={"Sell & Submit TAGs - Employee Score"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={107}  />,
		<Select className={"question-card"} name={"2831_0"} label={"Sell & Submit TAGs - Manager's Score"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={108}  />,
		<TextInput className={"question-card"} name={"2832_0"} label={"Sell & Submit TAGs - Comments"} excel_reference={109}  />,
		<div className={"question-card"} name={"2833_0"} excel_reference={110}  >
				<Paragraph text={"AVERAGE TOTAL SCORE"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>,
		<TextInput className={"question-card"} name={"2834_0"} label={"AVERAGE TOTAL SCORE - Employee Score"} excel_reference={111} excel={'(Q28+Q32+Q36+Q40+Q44+Q48+Q53+Q57+Q61+Q65+Q69+Q74+Q78+Q82+Q86+Q90+Q95+Q99+Q103+Q107)/20'}  />,
		<TextInput className={"question-card"} name={"2835_0"} label={"AVERAGE TOTAL SCORE - Manager Score"} excel_reference={112} excel={'(Q29+Q33+Q37+Q41+Q45+Q49+Q54+Q58+Q62+Q66+Q70+Q75+Q79+Q83+Q87+Q91+Q96+Q100+Q104+Q108)/20'}  />,
    ];

    return elements;
}

export default get_form_elements;

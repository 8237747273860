import React, { Component } from 'react';
import {resolveVariables} from 'functions';
import {ajaxWrapper} from 'functions';
import Select from "./select.js";

class BooleanInput extends Component {
    static component_name = 'BooleanInput';

    render() {
        return (
            <Select options={[{'value':true,'text':'True'},{'value':false,'text':'False'}]} />
        )
    }

}

export default BooleanInput;

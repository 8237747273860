import React, { Component } from 'react';
import {Wrapper} from 'library';

class Sidebar extends Component {
    static component_name = 'Sidebar';
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            height: "0px"
        };
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
        window.addEventListener("focus", this.updateDimensions.bind(this));
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
        window.removeEventListener("focus", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        var that = this;
        setTimeout(function (){
            var height = String(window.outerHeight - that.props.headerHeight) + "px";
            var width = String(window.outerWidth * (that.props.widthPercent/100)) + "px";

            console.log(height, width);
            that.setState({
                height: height,
                width: width
            });
        }, 10);
    }

    toggle() {
        if (this.state.open){
            this.setState({ open: false});
            if (this.props.toggleOpen){this.props.toggleOpen(false);}
        } else {
            this.setState({ open: true});
            if (this.props.toggleOpen){this.props.toggleOpen(true);}
        }
    }

    render() {
        var position = {
            position: "fixed",
            top: this.props.headerHeight + "px",
            right: "0px",
            width: this.state.width,
            background: "white",
            boxShadow: 'rgba(0, 0, 0, 0.2) -2px 2px 5px',
            zIndex: '1000',
        }
        var toggleText = "Close";
        if (!this.state.open){
            position['right'] = "-" + this.state.width;
            toggleText = this.props.openerText;
        }

        var openerStyle = {
            position: 'absolute',
            top: this.props.openerPosition,
            right: this.state.width,
            padding: '10px',
            background: 'white',
            borderTopLeftRadius: '10px',
            borderBottomLeftRadius: '10px',
            boxShadow: 'rgba(0, 0, 0, 0.3) -4px 2px 5px'
        }

        var opener =
        <div style={openerStyle}>
            <button className="btn btn-info" onClick={this.toggle.bind(this)}>{toggleText}</button>
        </div>

        var content = this.props.content;

        return (
            <div style={position} >
                {opener}
                <div className="container" style={{height: this.state.height, overflow: "scroll"}}>
                    <br/>
                    <Wrapper content={content} loaded={this.props.loaded} />
                    <br/>
                </div>
            </div>
        );
    }
}


export default Sidebar;

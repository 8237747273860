import React, { Component } from 'react';
import {ajaxWrapper, resolveVariables} from 'functions';

import {Wrapper, LogInForm, Header, Div, Container, Span, Link, Image, Paragraph} from 'library';

import SearchBox from 'projectLibrary/process/search_box.js';
import EditProcess from 'projectLibrary/process/edit.js';


class ProcessSmall extends Component {
    constructor(props) {
        super(props);

        this.state = {
            search_terms: null,
            double_terms: null,
            search_string: '',
            double_string: '',
            search_term: ''
        };

        this.search_input = this.search_input.bind(this);
        this.load_search_terms = this.load_search_terms.bind(this);
        this.load_double_terms = this.load_double_terms.bind(this);
        this.select_search_term = this.select_search_term.bind(this);
        this.select_double_term = this.select_double_term.bind(this);
        this.load_process_page = this.load_process_page.bind(this);
    }

    componentDidMount(){}

    load_search_terms(){
        ajaxWrapper('GET', '/search_terms/'+ this.props.process.id +'/', {}, function(value){this.setState({search_terms: value});}.bind(this));
    }

    load_double_terms(){
        ajaxWrapper('GET', '/double_terms/'+ this.props.process.id +'/?search_value='+ this.state.search_term, {}, function(value){this.setState({double_terms: value});}.bind(this));
    }

    search_input(e){
        if (!(this.state.search_terms)){
            ajaxWrapper('GET', '/search_terms/'+ this.props.process.id +'/', {}, this.load_search_terms);
            return false;
        }

    	console.log(e.target.val());

        this.setState({
            search_string: e.target.val(),
            show_options: true
        });
    }

    select_search_term(text){
        if (this.props.process.double_term){
            this.setState({
                search_term: text,
                double_terms: null
            });
        }
        else {
            this.load_process_page(text);
        }

        this.props.hide_modal();
    }

    select_double_term(text){
        this.load_process_page(this.state.search_term, text);
    }

    load_process_page(search_term, double_term){
        search_term = search_term.trim();
        double_term = double_term.trim();

        var process = this.props.process;

        if (process.double_term){
            window.location = '/process/'+ process.id +'/submission/?search_value=' + search_term + '&double_value=' + double_term;
        }
        else {
            window.location = '/process/'+ process.id +'/submission/?search_value=' + search_term;
        }
    }


    render() {
        var global_state = window.cmState.getGlobalState();
        var user = global_state['user'];
        var process = this.props.process;
        var user_ids = [];
        var master_ids = [];

        for (var index=0;index<process.new_masters.length;index++){
            user_ids.push(process.new_masters[index]['user']['id']);
            master_ids.push(process.new_masters[index]['user']['id'])
        }
        for (var index=0;index<process.new_managers.length;index++){
            user_ids.push(process.new_managers[index]['user']['id']);
        }

        var edit = null;
        if (user_ids.indexOf(user.id) > -1 || user.is_staff){
            edit = <a className="btn btn-default" style={{float:'right', color: 'white'}} href={'/process/'+ process.id +'/edit/'} >Edit</a>;
        }
        var is_master = false;
        if (master_ids.indexOf(user.id) > -1){
            is_master = true;
        }

        var process_actions = null;
        if (!(process.active)){
            process_actions = <div className="alert alert-success">
                This process is not active!
            </div>;
        }
        else {
            var search = <div className='col-lg-6 col-sm-12 row'>
                <div>Type here to search for an existing process or start a new one.</div>
                <SearchBox process={process} is_master={is_master} options={this.state.search_terms} search_string={this.state.search_string} push_modal_content={this.props.push_modal_content}
                    update_search={(e) => this.setState({search_string: e})} select_search_term={this.select_search_term} load_options={this.load_search_terms} />
            </div>;

            if (process.double_term){
                search = <div className='col-lg-6 col-sm-12 row'>
                    <div>Type here to search for an existing process or start a new one.</div>
                    <SearchBox process={process} is_master={is_master} options={this.state.search_terms} search_string={this.state.search_string} push_modal_content={this.props.push_modal_content}
                        update_search={(e) => this.setState({search_string: e})} select_search_term={this.select_search_term} selected_search_term={this.state.search_term} load_options={this.load_search_terms} />
                    <SearchBox process={process} is_master={is_master} double={true} options={this.state.double_terms} search_string={this.state.double_string} selected_search_term={this.state.search_term} push_modal_content={this.props.push_modal_content}
                        update_search={(e) => this.setState({double_string: e})} select_search_term={this.select_double_term} load_options={this.load_double_terms} />
                </div>;
            }

            var public_entry = null;
            if (process.public){
                var bitly_button = <input type="text" className="form-control" value={process.bitly_url} />;
                if (!(process.bitly_url)){
                    bitly_button = [
                        <br/>,
                        <a className="btn btn-primary" href="/process/{{process.id}}/bitlyurl/" >Create Bitly URL</a>
                    ];
                }

                public_entry = <div className="col-lg-6 col-sm-12">
                    <div>Public submission URL:</div>
                    {bitly_button}
                </div>;
            }

            process_actions = <div className="alert alert-success row">
                {search}
                {public_entry}
            </div>;
        }

        return (<div>
            <div className='process anchor_tag' num={process.id} id={'process' + process.id} style={{background: 'white', paddingTop: '10px'}} >
                <div>
                    <a className="btn btn-primary" style={{float:'right'}} href={"/process/"+ process.id +"/results/"} >Results</a>
                    {edit}
                    <Header size={2} >{process.title}</Header>

                    <div>{process.description}</div>

                    {process_actions}
                </div>
            </div>

            </div>);
    }
}

export default ProcessSmall;

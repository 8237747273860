import React, { Component } from 'react';
import {ajaxWrapper, resolveVariables} from 'functions';
import $ from 'jquery';

import {Wrapper, LogInForm, Header, Div, Container, Span, Link, Image, Paragraph,
    FormWithChildren, TextInput, DateTimePicker } from 'library';


class SearchBox extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show_options: false,
            loaded: false
        };

        this.search_input = this.search_input.bind(this);
        this.blur_options = this.blur_options.bind(this);
        this.clear_search = this.clear_search.bind(this);
        this.search_term_confirm = this.search_term_confirm.bind(this);

        this.create_search_term = this.create_search_term.bind(this);
        this.select_search_term = this.select_search_term.bind(this);
        this.select_date = this.select_date.bind(this);

        this.date_ref = React.createRef();
    }

    componentDidMount() {}

    search_input(e){
        var string = e.target.value;
        console.log(string);

        if (!(this.props.options)){
            ajaxWrapper('GET', '/search_terms/'+ this.props.process.id +'/', {}, this.props.load_options);
        }

        this.props.update_search(string);

        this.setState({
            show_options: true
        });
    }

    search_term_confirm(e){
        var process = this.props.process;
        if (this.props.double || !(this.props.process.search_term)) {
            //Use REF to click sneaky calender
            $(e.currentTarget).closest('.process').find('.sneaky-date input[type=text]').eq(0).focus();
        } else {
            this.props.push_modal_content(
                <div class="confirmsearch" style={{padding:'15px'}} >
                    <FormWithChildren submit={this.create_search_term} >
                        <h2>{'Enter your ' + process.search_term + ':'}</h2><br/>
                        <TextInput name="active_search_value" default={this.props.search_string} />
                        <br/><br/>
                    </FormWithChildren>
                </div>
            );
        }
    }

    create_search_term(state){
        this.props.select_search_term(state['active_search_value']);
    }

    select_search_term(e){
        this.props.select_search_term(e.target.text);
    }

    select_date(state){
        this.props.select_search_term(state['sneaky-date']);
    }

    blur_options(){
        setTimeout(
            function(){
                this.setState({show_options:false});
            }.bind(this),
            500
        );
    }

    clear_search(){
        if (!(this.props.double)){
            this.props.select_search_term('');
        }

        this.props.update_search('');
    }


    render() {
        var process = this.props.process;
        var options = this.props.options || [];

        var option_display = null;
        if (this.state.show_options){
            var option_list = [];
            if (process.anyone_can_add_locations || this.props.is_master || this.props.double){
                option_list.push(<div class="tagItem"><a class="btn btn-primary" onClick={this.search_term_confirm}>{'Add New'}</a></div>);
            }

            var displayed_options = 0;
    		for (var index=0;index<options.length;index++){
                var option = options[index];

    			if (displayed_options < 100 && option != '' && option.toLowerCase().indexOf(this.props.search_string.toLowerCase()) >= 0){
    				displayed_options += 1;
    				option_list.push(<div class="tagItem"><a class="btn btn-default" onClick={this.select_search_term}>{option}</a></div>);
    			}
    		}

            if (!(this.props.options)){
                option_list.push(<div>Loading . . . </div>);
            }
            option_display = <div class="completebox">{option_list}</div>;
        }

        var update_functions = {
            onClick: this.search_input,
            onChange: this.search_input,
            onBlur: this.blur_options,
            onFocus: this.clear_search
        }

        var search = <div class='col-sm-12 search_me_input' >
            <div style={{padding:'0px'}}>
                <input class="form-control dateholder" type="text" name="date-selector" placeholder="Date" {...update_functions} />
                {option_display}
            </div>
        </div>;

        if (this.props.double){
            var disabled = {disabled: 'disabled'};
            if (this.props.selected_search_term){disabled = null;}

            search = <div class='col-md-6 col-sm-12 search_me_input' style={{paddingLeft: '0px'}} >
                <input class="form-control dateholder" type="text" id="" name="double-selector" placeholder="Date" {...disabled} {...update_functions} />
                {option_display}
                <DateTimePicker className={"sneaky-date"} name={"sneaky-date"} display_time={false} setFormState={this.select_date} />
            </div>;
        }
        else if (process.search_term) {
            var input_value = {};
            if (this.props.selected_search_term){
                input_value['value'] = this.props.selected_search_term;
            }
            else {
                input_value['value'] = this.props.search_string;
            }

            search = <div class='col-md-6 col-sm-12 search_me_input' style={{paddingLeft:'0px'}} >
                <input type="text" name="search_term" class="form-control" placeholder={process.search_term} autocomplete="off" {...update_functions} {...input_value} />
                {option_display}
            </div>;
        }

        return (search);
    }
}

export default SearchBox;

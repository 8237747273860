import React, { Component } from 'react';
import {CSSInput, TextInput} from 'library';

class TableData extends React.Component {
    static component_name = 'TableData';
    constructor(props) {
        super(props);

        this.config = {
            form_components: [
                <TextInput label={'data'} name={'data'} default='' />,
                <CSSInput label={'css'} name={'style'} default={{}} />,
            ],
            can_have_children: true,
        }
    }

    render() {
        var data = this.props.data || this.props.children;
        if (this.props.eval){
            try {
                data = eval(data);
            }
            catch (e){console.log(e);}

            if (data.indexOf('{') > -1 && data.indexOf('{') > -1){
                data = '';
            }
        }

        return (
            <td onClick={this.props.onClick} style={this.props.style || {}}>
                {data}
            </td>
        );
    }
}

export default TableData;

import React, { Component } from 'react';
import {resolveVariables, resolve_all_children} from 'functions';
import {TextInput, CSSInput, Json_Input} from 'library';


class GlobalStateToProps extends Component {
    static component_name = 'GlobalStateToProps';
    constructor(props) {
        super(props);
        this.config = {
            form_components: [
                <TextInput label={'class'} name={'className'} />,
                <Json_Input label={'dataMapping'} name={'dataMapping'} />,
                <CSSInput label={'css'} name={'style'} default={{}} />,
            ],
            can_have_children: true,
        }

        this.state = {
            data: {},
            loaded: false
        };
    }

    componentDidMount() {
        this.refreshData();
    }

    componentWillUnmount() {
        window.cmState.unsubscribe(this, this.props.dataUrl);
    }

    refreshData() {
        var data = {};
        var global_state = window.cmState.getGlobalState();

        if (this.props.dataMapping) {
            data = resolveVariables(this.props.dataMapping, global_state);
        }

        this.setState({
            data:data,
            loaded: true
        });
    }

    render() {
        var content = [];
        
        if (this.state.loaded){
            var data = this.state.data;
            data['refreshData'] = this.refreshData;
            data['setGlobalState'] = this.props.setGlobalState;
            content = resolve_all_children(this, data);
        }

        return (
            <div>{content}</div>
        );
    }
}

export default GlobalStateToProps;

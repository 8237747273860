import React, { Component } from 'react';
import {ajaxWrapper, resolveVariables} from 'functions';

import {Wrapper, Div, Image, Span, Link, Paragraph, Container } from 'library';

class SimpleFooter extends Component {

    render() {
        return (
            <div className='footer'><Container>
                <div className="row" >
                    <div className="col-md-3 col-sm-6">
                        <a className="navbar-brand" href="/" style={{padding: '8px 0px', height:'75px'}} >
                            <img style={{width:'auto', maxHeight: '60px'}} src="/static/images/logo.png" />
                        </a>
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <span>Pages</span>
                        <a href="/">Home</a>
                        <a href="/pricing">Pricing</a>
                        <a href="/about">About Us</a>
                        <a href="/contact">Contact Us</a>
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <span>Other</span>
                        <a href="/signup">Sign Up</a>
                        <a href="/login">Login</a>
                        <a href="/privacy">Privacy Policy</a>
                        <a href="/terms">Terms of Service</a>
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <a href="/login" className="btn btn-primary" style={{color:'white', whiteSpace: 'normal'}} >START YOUR FREE TRIAL</a>
                    </div>
                </div>

                <div className="row" style={{textAlign: 'center', padding: '20px', borderTop: 'thin solid #e8e8e8'}} ><div className="col-sm-12">
                    Copyright &copy; Dimension 5 Publishing 2016. All Rights Reserved.
                </div></div>
            </Container></div>
        );
    }
}

export default SimpleFooter;

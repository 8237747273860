import React, { Component } from 'react';
import {ajaxWrapper, resolveVariables} from 'functions';
import {Wrapper} from 'library';

class EasyLogin extends Component {
    constructor(props) {
        super(props);

        this.login_and_redirect = this.login_and_redirect.bind(this);
    }

    componentDidMount() {
        ajaxWrapper('POST', '/users/easy_login/'+ this.props.string +'/', {email:'test',password:'test'}, this.login_and_redirect);
    }

    login_and_redirect(value) {
        console.log("User",value);
        localStorage.setItem('token', value['access']);
        localStorage.setItem('refresh_token', value['refresh']);
        localStorage.setItem('token_time', new Date());

        window.location.href = '/profile/';
    }


    render() {
        return(
            <Wrapper loaded={false} />
        )
    }
}

export default EasyLogin;

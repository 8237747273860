import React, { Component } from 'react';
import {resolveVariables, format_date_string} from 'functions';
import {TextInput, Select, CSSInput, Json_Input, DisplaySize, Accordion} from 'library';
import moment from 'moment';

class MyTimeSelect extends Component {
    render() {
        return (
            <label className={"btn" + this.props.active + this.props.type} style={{'display':'block'}}>
                <input style={{display:"none"}} type="radio" name={this.props.day_name} value={this.props.value}
                    onClick={this.props.handleChange} />{this.props.text}
            </label>
        )
    }
}


class TimeListPicker extends Component {
    static component_name = 'TimeListPicker';
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);

        this.config = {
            form_components: [
                <TextInput label={'Name'} name={'name'} />,
                <TextInput label={'Label'} name={'label'} />,
                <TextInput label={'Class'} name={'className'} />,
                <TextInput label={'Select Class'} name={'selectClassName'} />,
                <TextInput label={'Key Context'} name={'key_context'} defaultoption={''} />,
                <Json_Input label={'Columns'} name={'columns'} />,
                <CSSInput label={'css'} name={'style'}/>,
            ],
            can_have_children: true,
        }

        this.state = {
            value_dict: {}
        };
    }

    componentDidUpdate(prevProps) {
        var value = this.props.value;
        var props_string = JSON.stringify(this.props.value);
        var state_string = JSON.stringify(this.state.value_dict);

        if (typeof(value) == 'string') {
            try {
                value = JSON.parse(value);
                var formState = {};
                formState[this.props.name] = value;
                this.props.setFormState(formState);
            }
            catch (e){console.log(e);}
        }
        else if (props_string != state_string) {
            this.setState({
                value_dict: value
            });
        }
    }


    handleChange(e) {
        var newState = {};
        var selection = e.target.value;
        var sub_name = e.target.name;

        var value_dict = this.props.value;
        if (!(sub_name in value_dict)){
            value_dict[sub_name] = [];
        }

        var index = value_dict[sub_name].indexOf(selection);
        if (index == -1) {
            value_dict[sub_name].push(selection);
        }
        else {
            value_dict[sub_name].splice(index, 1);
        }
        newState[this.props.name] = value_dict;

        this.props.setFormState(newState);
    }

    render() {
        var style = this.props.style;
        var mobile_style = Object.assign({}, this.props.style);

        var label = null;
        if (this.props.label && this.props.label != '') {
            label = <label style={this.props.label_style}>{this.props.label}</label>;
        }

        var options = [];
        var current_time = moment().hours(15).minutes(30).seconds(0);
        while (current_time.hours() != 20 || current_time.minutes() != 0){
            var string = current_time.format('h:mm') + 'pm';
            options.push({'text': string, 'value': string});
            current_time.add(30, 'minutes');
        }

        var weekend_options = [];
        var current_time = moment().hours(8).minutes(30).seconds(0);
        while (current_time.hours() != 17 || current_time.minutes() != 0){
            var string = current_time.format('h:mm');
            if (current_time.hours() < 12){string += 'am';}
            else {string += 'pm';}

            weekend_options.push({'text': string, 'value': string});
            current_time.add(30, 'minutes');
        }

        var value_dict = this.state.value_dict;
        if (typeof(value_dict) == 'string'){
            try{
                value_dict = JSON.parse(value_dict);
            }
            catch(e){}
        }
        else if (typeof(value_dict) == 'undefined'){
            value_dict = {};
        }

        var selects = [];
        var mobile_dropdowns = [];
        var type = " btn-outline-secondary btn-mobile";
        var mobile_type = " btn-outline-secondary btn-mobile";
        for (var day_name in this.props.columns){
            var value = value_dict[day_name];
            if (typeof(value) == 'undefined'){
                value = [];
            }

            var select = [];
            var mobile_select = [];

            var options_to_use = options;
            if (['day_5', 'day_6'].indexOf(day_name) > -1){
                options_to_use = weekend_options;
            }

            for (var index in options_to_use) {
                var active = '';
                var current_option = String(options_to_use[index]['value']);
                if (value.indexOf(current_option) > -1){
                    active = " active";
                }

                select.push(<MyTimeSelect handleChange={this.handleChange} active={active} type={type}
                    day_name={day_name} value={String(options_to_use[index]['value'])} text={options_to_use[index]['text']} />);

                mobile_select.push(<MyTimeSelect handleChange={this.handleChange} active={active} type={mobile_type}
                    day_name={day_name} value={String(options_to_use[index]['value'])} text={options_to_use[index]['text']} />);
            }

            var col_style = {padding: '10px 15px'};
            if ('day_5' == day_name){
                col_style = {
                    padding: '10px 15px',
                    background: '#f6f6f6',
                    borderLeft: 'thin solid #666',
                    borderTop: 'thin solid #666',
                    borderBottom: 'thin solid #666',
                    borderRadius: '4px 0px 0px 4px'
                };
            }
            else if ('day_6' == day_name){
                col_style = {
                    padding: '10px 15px',
                    background: '#f6f6f6',
                    borderRight: 'thin solid #666',
                    borderTop: 'thin solid #666',
                    borderBottom: 'thin solid #666',
                    borderRadius: '0px 4px 4px 0px'
                };
            }

            selects.push(<div className={'form-group ' + this.props.selectClassName} style={col_style} >
                <label>{this.props.columns[day_name]}</label>
                {select}
            </div>);

            mobile_dropdowns.push(
                <Accordion name={this.props.columns[day_name]} index={this.props.key_context + '_' + day_name} className={'col-12'} style={{padding:"0px"}} >
                    {mobile_select}
                </Accordion>
            );
        }

        return (
            <div className='col-12 row' style={{padding:'0px'}} >
                <DisplaySize display_type={['browser','tablet']}>
                    <div className={this.props.className} style={style} >
                        {label}
                        {selects}
                    </div>
                </DisplaySize>

                <DisplaySize display_type={['mobile']}>
                    <div className={this.props.className} style={mobile_style} >
                        {mobile_dropdowns}
                    </div>
                </DisplaySize>
            </div>
        )
    }
}

export default TimeListPicker;

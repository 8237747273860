import React, { Component } from 'react';
import {Wrapper, Container, Header, Div, Paragraph, FormWithChildren, DateTimePicker, TextInput, Select, TextArea, UserSearchInput, SignatureInput, ImageInput, Accordion} from 'library';

function get_form_elements(){
    var elements = [
		<Accordion open={false} index={10648} header={<div className={"question-card"} name={"10648_0"} excel_reference={1} style={{fontSize: '24px'}} >
				<Paragraph text={"QUALITY CONTROL INSPECTION"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"10649_0,10652_0,10653_0,10654_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"10649_0"} label={"Select Position Below if QC Form is Completed by a Supervisor or Manager"} options={[{"text": "Supervisor", "value": "Supervisor"}, {"text": "Area Supervisor", "value": "Area Supervisor"}, {"text": "Dir/Mgr of Operations", "value": "Dir/Mgr of Operations"}, {"text": "Vice President", "value": "Vice President"}]} radio={true} excel_reference={2}  />
			<TextInput className={"question-card"} name={"10652_0"} label={"Area:"} excel_reference={5} required={true}  />
			<TextInput className={"question-card"} name={"10653_0"} label={"Completed by:"} excel_reference={6} required={true}  />
			<TextInput className={"question-card"} name={"10654_0"} label={"Supervisor"} excel_reference={7} required={true}  />
      <TextInput className={"question-card"} name={"10999_0"} label={"If the Store Manager would like a copy of the inspection, enter their email address below:"} excel_reference={1} required={false}  />
		</Accordion>,
		<Accordion open={false} index={10655} header={<div className={"question-card"} name={"10655_0"} excel_reference={8} anchor={true} anchor_text={"General Sales Area"} style={{fontSize: '24px'}} >
				<Paragraph text={"General Sales Area"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"10656_0,10657_0,10658_0,10659_0,10660_0,10661_0"} sub_total={"10670_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"10656_0"} label={"Dust Mopping"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={9}  />
			<Select className={"question-card"} name={"10657_0"} label={"Wet Mopping"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={10}  />
			<Select className={"question-card"} name={"10658_0"} label={"Shine"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={11}  />
			<Select className={"question-card"} name={"10659_0"} label={"Wear"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={12}  />
			<Select className={"question-card"} name={"10660_0"} label={"Scraping"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={13}  />
      <Select className={"question-card"} name={"10661_0"} label={"Quarry Tile"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={14}  />
			<Select className={"question-card"} name={"10662_0"} label={"Scrub or Strip Service"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={15}  />
			<TextInput className={"question-card"} name={"10667_0"} label={"COMMENTS "} excel_reference={16} fail_comment={true}  />
			<div className={"question-card"} name={"10668_0"} excel_reference={17}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"10669_0"} excel_reference={18}  />
			<TextInput className={"question-card"} name={"10670_0"} label={"Total Rating"} excel_reference={19} excel={'+Q9+Q10+Q11+Q12+Q13+Q14+Q15'}  />
		</Accordion>,
    <Accordion open={false} index={10755} header={<div className={"question-card"} name={"10755_0"} excel_reference={10755} anchor={true} anchor_text={"Bakery Prep Area"} style={{fontSize: '24px'}}  >
				<Paragraph text={"Bakery Prep Area"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"10756_0,10757_0,10760_0,10761_0,10767_0,10768_0,10769_0,10770_0"} sub_total={"10770_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"10756_0"} label={"Dust Mopping"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={101}  />
			<Select className={"question-card"} name={"10757_0"} label={"Wet Mopping"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={102}  />
			<Select className={"question-card"} name={"10760_0"} label={"Scraping"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={103}  />
			<Select className={"question-card"} name={"10761_0"} label={"Quarry Tile"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={104}  />
			<TextInput className={"question-card"} name={"10767_0"} label={"COMMENTS "} excel_reference={105} fail_comment={true}  />
			<div className={"question-card"} name={"10768_0"} excel_reference={106}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"10769_0"} excel_reference={107}  />
			<TextInput className={"question-card"} name={"10770_0"} label={"Total Rating"} excel_reference={108} excel={'+Q101+Q102+Q103+Q104'}  />
		</Accordion>,
    <Accordion open={false} index={200} header={<div className={"question-card"} name={"200"} excel_reference={8} anchor={true} anchor_text={"Bakery Sales Area"} style={{fontSize: '24px'}}  >
				<Paragraph text={"Bakery Sales Area"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"201,202,203,204,205,206,207,208,209,210,211"} sub_total={"211"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"201"} label={"Dust Mopping"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={201}  />
			<Select className={"question-card"} name={"202"} label={"Wet Mopping"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={202}  />
			<Select className={"question-card"} name={"203"} label={"Shine"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={203}  />
			<Select className={"question-card"} name={"204"} label={"Wear"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={204}  />
			<Select className={"question-card"} name={"205"} label={"Scraping"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={205}  />
      <Select className={"question-card"} name={"206"} label={"Quarry Tile"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={206}  />
			<Select className={"question-card"} name={"207"} label={"Scrub or Strip Service"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={207}  />
			<TextInput className={"question-card"} name={"208"} label={"COMMENTS "} excel_reference={208} fail_comment={true}  />
			<div className={"question-card"} name={"209"} excel_reference={209}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"210"} excel_reference={210}  />
			<TextInput className={"question-card"} name={"211"} label={"Total Rating"} excel_reference={211} excel={'+Q201+Q202+Q203+Q204+Q205+Q206+Q207'}  />
		</Accordion>,

    <Accordion open={false} index={300} header={<div className={"question-card"} name={"300"} excel_reference={300} anchor={true} anchor_text={"Produce Prep Area"} style={{fontSize: '24px'}}  >
				<Paragraph text={"Produce Prep Area"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"301,302,303,304,305,306,307,308"} sub_total={"308"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"301"} label={"Dust Mopping"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={301}  />
			<Select className={"question-card"} name={"302"} label={"Wet Mopping"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={302}  />
			<Select className={"question-card"} name={"303"} label={"Scraping"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={303}  />
      <Select className={"question-card"} name={"304"} label={"Quarry Tile"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={304}  />
			<TextInput className={"question-card"} name={"305"} label={"COMMENTS "} excel_reference={305} fail_comment={true}  />
			<div className={"question-card"} name={"306"} excel_reference={306}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"307"} excel_reference={307}  />
			<TextInput className={"question-card"} name={"308"} label={"Total Rating"} excel_reference={308} excel={'+Q301+Q302+Q303+Q304'}  />
		</Accordion>,
    <Accordion open={false} index={310} header={<div className={"question-card"} name={"310"} excel_reference={310} anchor={true} anchor_text={"Produce Sales Area"} style={{fontSize: '24px'}}  >
				<Paragraph text={"Produce Sales Area"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"311,312,313,314,315,316,317,318,319,320,321"} sub_total={"321"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"311"} label={"Dust Mopping"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={311}  />
			<Select className={"question-card"} name={"312"} label={"Wet Mopping"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={312}  />
			<Select className={"question-card"} name={"313"} label={"Shine"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={313}  />
			<Select className={"question-card"} name={"314"} label={"Wear"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={314}  />
			<Select className={"question-card"} name={"315"} label={"Scraping"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={315}  />
      <Select className={"question-card"} name={"316"} label={"Quarry Tile"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={316}  />
			<Select className={"question-card"} name={"317"} label={"Scrub or Strip Service"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={317}  />
			<TextInput className={"question-card"} name={"318"} label={"COMMENTS "} excel_reference={318} fail_comment={true}  />
			<div className={"question-card"} name={"319"} excel_reference={319}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"320"} excel_reference={320}  />
			<TextInput className={"question-card"} name={"321"} label={"Total Rating"} excel_reference={321} excel={'+Q311+Q312+Q313+Q314+Q315+Q316+Q317'}  />
		</Accordion>,

    <Accordion open={false} index={400} header={<div className={"question-card"} name={"400"} excel_reference={400} anchor={true} anchor_text={"Deli Prep Area"} style={{fontSize: '24px'}}  >
				<Paragraph text={"Deli Prep Area"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"401,402,403,404,405,406,407,408"} sub_total={"408"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"401"} label={"Dust Mopping"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={401}  />
			<Select className={"question-card"} name={"402"} label={"Wet Mopping"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={402}  />
			<Select className={"question-card"} name={"403"} label={"Scraping"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={403}  />
      <Select className={"question-card"} name={"404"} label={"Quarry Tile"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={404}  />
			<TextInput className={"question-card"} name={"405"} label={"COMMENTS "} excel_reference={405} fail_comment={true}  />
			<div className={"question-card"} name={"406"} excel_reference={406}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"407"} excel_reference={407}  />
			<TextInput className={"question-card"} name={"408"} label={"Total Rating"} excel_reference={408} excel={'+Q401+Q402+Q403+Q404'}  />
		</Accordion>,
    <Accordion open={false} index={410} header={<div className={"question-card"} name={"410"} excel_reference={410} anchor={true} anchor_text={"Deli Sales Area"} style={{fontSize: '24px'}}  >
				<Paragraph text={"Deli Sales Area"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"411,412,413,414,415,416,417,418,419,420,421"} sub_total={"421"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"411"} label={"Dust Mopping"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={411}  />
			<Select className={"question-card"} name={"412"} label={"Wet Mopping"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={412}  />
			<Select className={"question-card"} name={"413"} label={"Shine"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={413}  />
			<Select className={"question-card"} name={"414"} label={"Wear"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={414}  />
			<Select className={"question-card"} name={"415"} label={"Scraping"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={415}  />
      <Select className={"question-card"} name={"416"} label={"Quarry Tile"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={416}  />
			<Select className={"question-card"} name={"417"} label={"Scrub or Strip Service"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={417}  />
			<TextInput className={"question-card"} name={"418"} label={"COMMENTS "} excel_reference={418} fail_comment={true}  />
			<div className={"question-card"} name={"419"} excel_reference={419}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"420"} excel_reference={420}  />
			<TextInput className={"question-card"} name={"421"} label={"Total Rating"} excel_reference={421} excel={'+Q411+Q412+Q413+Q414+Q415+Q416+Q417'}  />
		</Accordion>,

    <Accordion open={false} index={500} header={<div className={"question-card"} name={"500"} excel_reference={500} anchor={true} anchor_text={"Prepared Food Prep Area"} style={{fontSize: '24px'}}  >
				<Paragraph text={"Prepared Food Prep Area"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"501,502,503,504,505,506,507,508"} sub_total={"508"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"501"} label={"Dust Mopping"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={501}  />
			<Select className={"question-card"} name={"502"} label={"Wet Mopping"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={502}  />
			<Select className={"question-card"} name={"503"} label={"Scraping"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={503}  />
      <Select className={"question-card"} name={"504"} label={"Quarry Tile"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={504}  />
			<TextInput className={"question-card"} name={"505"} label={"COMMENTS "} excel_reference={505} fail_comment={true}  />
			<div className={"question-card"} name={"506"} excel_reference={506}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"507"} excel_reference={507}  />
			<TextInput className={"question-card"} name={"508"} label={"Total Rating"} excel_reference={508} excel={'+Q501+Q502+Q503+Q504'}  />
		</Accordion>,
    <Accordion open={false} index={510} header={<div className={"question-card"} name={"510"} excel_reference={510} anchor={true} anchor_text={"Prepared Food Sales Area"} style={{fontSize: '24px'}}  >
				<Paragraph text={"Prepared Food Sales Area"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"511,512,513,514,515,516,517,518,519,520,521"} sub_total={"521"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"511"} label={"Dust Mopping"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={511}  />
			<Select className={"question-card"} name={"512"} label={"Wet Mopping"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={512}  />
			<Select className={"question-card"} name={"513"} label={"Shine"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={513}  />
			<Select className={"question-card"} name={"514"} label={"Wear"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={514}  />
			<Select className={"question-card"} name={"515"} label={"Scraping"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={515}  />
      <Select className={"question-card"} name={"516"} label={"Quarry Tile"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={516}  />
			<Select className={"question-card"} name={"517"} label={"Scrub or Strip Service"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={517}  />
			<TextInput className={"question-card"} name={"518"} label={"COMMENTS "} excel_reference={518} fail_comment={true}  />
			<div className={"question-card"} name={"519"} excel_reference={519}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"520"} excel_reference={520}  />
			<TextInput className={"question-card"} name={"521"} label={"Total Rating"} excel_reference={521} excel={'+Q511+Q512+Q513+Q514+Q515+Q516+Q517'}  />
		</Accordion>,

    <Accordion open={false} index={600} header={<div className={"question-card"} name={"600"} excel_reference={600} anchor={true} anchor_text={"Seafood Prep Area"} style={{fontSize: '24px'}}  >
				<Paragraph text={"Seafood Prep Area"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"601,602,603,604,605,606,607,608"} sub_total={"608"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"601"} label={"Dust Mopping"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={601}  />
			<Select className={"question-card"} name={"602"} label={"Wet Mopping"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={602}  />
			<Select className={"question-card"} name={"603"} label={"Scraping"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={603}  />
      <Select className={"question-card"} name={"604"} label={"Quarry Tile"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={604}  />
			<TextInput className={"question-card"} name={"605"} label={"COMMENTS "} excel_reference={605} fail_comment={true}  />
			<div className={"question-card"} name={"606"} excel_reference={606}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"607"} excel_reference={607}  />
			<TextInput className={"question-card"} name={"608"} label={"Total Rating"} excel_reference={608} excel={'+Q601+Q602+Q603+Q604'}  />
		</Accordion>,
    <Accordion open={false} index={610} header={<div className={"question-card"} name={"610"} excel_reference={610} anchor={true} anchor_text={"Seafood Sales Area"} style={{fontSize: '24px'}}  >
				<Paragraph text={"Seafood Sales Area"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"611,612,613,614,615,616,617,618,619,620,621"} sub_total={"621"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"611"} label={"Dust Mopping"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={611}  />
			<Select className={"question-card"} name={"612"} label={"Wet Mopping"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={612}  />
			<Select className={"question-card"} name={"613"} label={"Shine"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={613}  />
			<Select className={"question-card"} name={"614"} label={"Wear"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={614}  />
			<Select className={"question-card"} name={"615"} label={"Scraping"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={615}  />
      <Select className={"question-card"} name={"616"} label={"Quarry Tile"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={616}  />
			<Select className={"question-card"} name={"617"} label={"Scrub or Strip Service"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={617}  />
			<TextInput className={"question-card"} name={"618"} label={"COMMENTS "} excel_reference={618} fail_comment={true}  />
			<div className={"question-card"} name={"619"} excel_reference={619}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"620"} excel_reference={620}  />
			<TextInput className={"question-card"} name={"621"} label={"Total Rating"} excel_reference={621} excel={'+Q611+Q612+Q613+Q614+Q615+Q616+Q617'}  />
		</Accordion>,

    <Accordion open={false} index={700} header={<div className={"question-card"} name={"700"} excel_reference={700} anchor={true} anchor_text={"Meat Room Area"} style={{fontSize: '24px'}}  >
				<Paragraph text={"Meat Room Area"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"701,702,703,704,705,706,707,708"} sub_total={"708"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"701"} label={"Dust Mopping"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={701}  />
			<Select className={"question-card"} name={"702"} label={"Wet Mopping"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={702}  />
			<Select className={"question-card"} name={"703"} label={"Scraping"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={703}  />
      <Select className={"question-card"} name={"704"} label={"Quarry Tile"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={704}  />
			<TextInput className={"question-card"} name={"705"} label={"COMMENTS "} excel_reference={705} fail_comment={true}  />
			<div className={"question-card"} name={"706"} excel_reference={706}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"707"} excel_reference={707}  />
			<TextInput className={"question-card"} name={"708"} label={"Total Rating"} excel_reference={708} excel={'+Q701+Q702+Q703+Q704'}  />
		</Accordion>,
    <Accordion open={false} index={710} header={<div className={"question-card"} name={"710"} excel_reference={710} anchor={true} anchor_text={"Meat Room Sales Area"} style={{fontSize: '24px'}}  >
				<Paragraph text={"Meat Room Sales Area"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"711,712,713,714,715,716,717,718,719,720,721"} sub_total={"721"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"711"} label={"Dust Mopping"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={711}  />
			<Select className={"question-card"} name={"712"} label={"Wet Mopping"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={712}  />
			<Select className={"question-card"} name={"713"} label={"Shine"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={713}  />
			<Select className={"question-card"} name={"714"} label={"Wear"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={714}  />
			<Select className={"question-card"} name={"715"} label={"Scraping"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={715}  />
      <Select className={"question-card"} name={"716"} label={"Quarry Tile"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={716}  />
			<Select className={"question-card"} name={"717"} label={"Scrub or Strip Service"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={717}  />
			<TextInput className={"question-card"} name={"718"} label={"COMMENTS "} excel_reference={718} fail_comment={true}  />
			<div className={"question-card"} name={"719"} excel_reference={719}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"720"} excel_reference={720}  />
			<TextInput className={"question-card"} name={"721"} label={"Total Rating"} excel_reference={721} excel={'+Q711+Q712+Q713+Q714+Q715+Q716+Q717'}  />
		</Accordion>,

    <Accordion open={false} index={800} header={<div className={"question-card"} name={"800"} excel_reference={800} anchor={true} anchor_text={"Restroom"} style={{fontSize: '24px'}}  >
				<Paragraph text={"Restroom"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"801,802,803,804,805,806,807,808,809,810,811,812,813,814"} sub_total={"814"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"801"} label={"Floors Swept & Mopped"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={801}  />
			<Select className={"question-card"} name={"802"} label={"Trash Removed"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={802}  />
			<Select className={"question-card"} name={"803"} label={"Fixtures Clean/Polished"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={803}  />
			<Select className={"question-card"} name={"804"} label={"Supplies Stocked"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={804}  />
			<Select className={"question-card"} name={"805"} label={"Mirrors & Frames"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={805}  />
      <Select className={"question-card"} name={"806"} label={"High & Low Dusting"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={806}  />
			<Select className={"question-card"} name={"807"} label={"Sinks, Fountains, Counter Tops"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={807}  />
      <Select className={"question-card"} name={"808"} label={"Toilets/Urinals Clean"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={808}  />
      <Select className={"question-card"} name={"809"} label={"Partition Walls/Doors"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={809}  />
      <Select className={"question-card"} name={"810"} label={"Vents"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={810}  />
			<TextInput className={"question-card"} name={"811"} label={"COMMENTS "} excel_reference={811} fail_comment={true}  />
			<div className={"question-card"} name={"812"} excel_reference={812}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"813"} excel_reference={813}  />
			<TextInput className={"question-card"} name={"814"} label={"Total Rating"} excel_reference={814} excel={'+Q801+Q802+Q803+Q804+Q805+Q806+Q807+Q808+Q809+Q810'}  />
		</Accordion>,

    <Accordion open={false} index={900} header={<div className={"question-card"} name={"900"} excel_reference={900} anchor={true} anchor_text={"Back Room"} style={{fontSize: '24px'}}  >
				<Paragraph text={"Back Room"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"901,902,903,904,905,906,907,908"} sub_total={"908"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"901"} label={"Dust Mopping"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={901}  />
			<Select className={"question-card"} name={"902"} label={"Wet Mopping"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={902}  />
			<Select className={"question-card"} name={"903"} label={"Scraping"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={903}  />
      <Select className={"question-card"} name={"904"} label={"Quarry Tile"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={904}  />
			<TextInput className={"question-card"} name={"905"} label={"COMMENTS "} excel_reference={905} fail_comment={true}  />
			<div className={"question-card"} name={"906"} excel_reference={906}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"907"} excel_reference={907}  />
			<TextInput className={"question-card"} name={"908"} label={"Total Rating"} excel_reference={908} excel={'+Q901+Q902+Q903+Q904'}  />
		</Accordion>,

    <Accordion open={false} index={1000} header={<div className={"question-card"} name={"1000"} excel_reference={1000} anchor={true} anchor_text={"Entrance"} style={{fontSize: '24px'}}  >
				<Paragraph text={"Entrance"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"901,902,903,904,905,906,907,908"} sub_total={"1007"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"1001"} label={"Carpet Tiles"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={1001}  />
			<Select className={"question-card"} name={"1002"} label={"Quarry Tile"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={1002}  />
			<Select className={"question-card"} name={"1003"} label={"Scrub or Strip Service"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={1003}  />
			<TextInput className={"question-card"} name={"1004"} label={"COMMENTS "} excel_reference={1005} fail_comment={true}  />
			<div className={"question-card"} name={"1005"} excel_reference={1006}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"1006"} excel_reference={1007}  />
			<TextInput className={"question-card"} name={"1007"} label={"Total Rating"} excel_reference={1008} excel={'+Q1001+Q1002+Q1003'}  />
		</Accordion>,

    <Accordion open={false} index={1100} header={<div className={"question-card"} name={"1100"} excel_reference={1100} anchor={true} anchor_text={"Break Room"} style={{fontSize: '24px'}}  >
				<Paragraph text={"Break Room"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"1101,1102,1103,1104,1105,1106,1107,1108"} sub_total={"1108"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"1101"} label={"Dust Mopping"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={1101}  />
			<Select className={"question-card"} name={"1102"} label={"Wet Mopping"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={1102}  />
			<Select className={"question-card"} name={"1103"} label={"Scraping"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={1103}  />
      <Select className={"question-card"} name={"1104"} label={"Scrub or Strip Service"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={1104}  />
			<TextInput className={"question-card"} name={"1105"} label={"COMMENTS "} excel_reference={1105} fail_comment={true}  />
			<div className={"question-card"} name={"1106"} excel_reference={1106}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"1107"} excel_reference={1107}  />
			<TextInput className={"question-card"} name={"1108"} label={"Total Rating"} excel_reference={1108} excel={'+Q1101+Q1102+Q1103+Q1104'}  />
		</Accordion>,

    <Accordion open={false} index={1200} header={<div className={"question-card"} name={"1200"} excel_reference={1200} anchor={true} anchor_text={"Manager's Office"} style={{fontSize: '24px'}}  >
				<Paragraph text={"Manager's Office"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"1201,1202,1203,1204,1205"} sub_total={"1205"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"1201"} label={"Scrub or Strip Service"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={1201}  />
			<TextInput className={"question-card"} name={"1202"} label={"COMMENTS "} excel_reference={1202} fail_comment={true}  />
			<div className={"question-card"} name={"1203"} excel_reference={1203}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"1204"} excel_reference={1204}  />
			<TextInput className={"question-card"} name={"1205"} label={"Total Rating"} excel_reference={1205} excel={'+Q1201'}  />
		</Accordion>,

    	<TextInput className={"question-card"} name={"1206"} label={"Manager's Opinion "} excel_reference={1206} required={false}  />,

		<Accordion open={false} index={10738} header={<div className={"question-card"} name={"10738_0"} excel_reference={94} anchor={true} anchor_text={"CAN WE PROVIDE ADDITIONAL SERV"} style={{fontSize: '24px'}}  >
				<Paragraph text={"CAN WE PROVIDE ADDITIONAL SERVICES (TAGS)?  Would the manager like a quote for any of the following?"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"10739_0,10740_0,10741_0,10742_0,10744_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"10739_0"} label={"Power washing restrooms? "} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={95}  />
			<Select className={"question-card"} name={"10740_0"} label={"Power washing exterior?"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={96}  />
			<Select className={"question-card"} name={"10741_0"} label={"Carpet Extraction?"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={97}  />
			<Select className={"question-card"} name={"10742_0"} label={"Office Floors?"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={98}  />
			<TextInput className={"question-card"} name={"10744_0"} label={"If the answer to a question above is yes, please comment below to describe the service you recommend"} excel_reference={100}  />
		</Accordion>,
		<TextInput className={"question-card"} name={"10736_0"} label={"Grand Total"} excel_reference={1392} anchor={true} anchor_text={"Grand Total"} excel={'Q19+Q108+Q211+Q308+Q321+Q408+Q421+Q508+Q521+Q608+Q621+Q708+Q721+Q814+Q908+Q1008+Q1108+Q1205'}  />,
		<TextInput className={"question-card"} name={"10737_0"} label={"Percentage"} excel_reference={1393} excel={'(Q1392/95)*100'}  />,
    ];

    return elements;
}

export default get_form_elements;

import React, { Component } from 'react';
import {Wrapper, Container, Header, Div, Paragraph, FormWithChildren, DateTimePicker, TextInput, Select, TextArea, UserSearchInput, SignatureInput, ImageInput, Accordion} from 'library';

function get_form_elements(){
    var elements = [
		<Accordion index={7177} header={<div className={"question-card"} name={"7177_0"} excel_reference={1} style={{fontSize: '24px'}}  >
				<Paragraph text={"PLUGGING-IN SCORECARD"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"7178_0,7179_0,7180_0,7181_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<TextInput className={"question-card"} name={"7178_0"} label={"NAME "} excel_reference={2}  />
			<TextInput className={"question-card"} name={"7179_0"} label={"REGION"} excel_reference={3}  />
			<DateTimePicker className={"question-card"} name={"7180_0"} label={"DATE"} excel_reference={4}  />
			<UserSearchInput className={"question-card"} name={"7181_0"} label={"Click on Supervisor E-mail address here"} excel_reference={5}  />
			<div className={"question-card"} name={"7182_0"} excel_reference={6} style={{fontSize: '14px'}}  >
				<Paragraph text={"IF RESPONSIBILITY DOES NOT APPLY SCORE = 10"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
		</Accordion>,
		<Accordion index={7183} header={<div className={"question-card"} name={"7183_0"} excel_reference={7} style={{fontSize: '24px'}}  >
				<Paragraph text={"PLUGGING IN"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"7184_0,7185_0,7186_0,7187_0,7188_0,7189_0,7190_0,7191_0,7192_0,7193_0,7194_0,7195_0,7196_0,7197_0,7237_0,7198_0,7199_0,7200_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"7184_0"} label={"Foreman - Execution of Work Orders (Robust creation, of recurring & one-time WOs)"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={8}  />
			<Select className={"question-card"} name={"7185_0"} label={"Manager - Execution of Work Orders (Robust creation, of recurring & one-time WOs)"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={9}  />
			<TextInput className={"question-card"} name={"7186_0"} label={"Comments"} excel_reference={10}  />
			<Select className={"question-card"} name={"7187_0"} label={"Foreman - Consistent & Productive Daily Call w/ MC & Supervisor(Robust &amp; 100% Completion of Daily Calls)"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={11}  />
			<Select className={"question-card"} name={"7188_0"} label={"Supervisor - Consistent & Productive Daily Call w/ MC & Supervisor[Robust & 100% Completion of Daily Calls]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={12}  />
			<TextInput className={"question-card"} name={"7189_0"} label={"Comments"} excel_reference={13}  />
			<Select className={"question-card"} name={"7190_0"} label={"Foreman - Meeting RTF Standards through daily call (Filing/Working) [Take care of Immediate Fix & report all issues]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={14}  />
			<Select className={"question-card"} name={"7191_0"} label={"Manager - Meeting RTF Standards through daily call (Filing/Working) [Take care of Immediate Fix & report all issues]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={15}  />
			<TextInput className={"question-card"} name={"7192_0"} label={"Comments"} excel_reference={16}  />
			<Select className={"question-card"} name={"7193_0"} label={"Foreman - Budget Awareness[Know daily hours, escalate to Supervisor a daily status report]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={17}  />
			<Select className={"question-card"} name={"7194_0"} label={"Manager - Budget Awareness[Know daily hours, escalate to Supervisor a daily status report]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={18}  />
			<TextInput className={"question-card"} name={"7195_0"} label={"Comments"} excel_reference={19}  />
			<Select className={"question-card"} name={"7196_0"} label={"Foreman - Supplies Inventory[Identify needs of Supplies through robust inventory]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={20}  />
			<Select className={"question-card"} name={"7197_0"} label={"Manager - Supplies Inventory[Identify needs of Supplies through robust inventory]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={21}  />
			<TextInput className={"question-card"} name={"7237_0"} label={"Comments"} excel_reference={22}  />
			<Select className={"question-card"} name={"7198_0"} label={"Foreman - Checklists[All sites completing checklist & QC's compliant with # required]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={23}  />
			<Select className={"question-card"} name={"7199_0"} label={"Manager - Checklists[All sites completing checklist & QC's compliant with # required]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={24}  />
			<TextInput className={"question-card"} name={"7200_0"} label={"Comments"} excel_reference={25}  />
		</Accordion>,
		<Accordion index={7201} header={<div className={"question-card"} name={"7201_0"} excel_reference={26} style={{fontSize: '24px'}}  >
				<Paragraph text={"LEADERSHIP"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"7202_0,7203_0,7204_0,7205_0,7207_0,7208_0,7209_0,7210_0,7211_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"7202_0"} label={"Foreman - Active Participation in Daily Calls w/ Supervisor[ \\\"Owns\\\" assigned accounts]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={27}  />
			<Select className={"question-card"} name={"7203_0"} label={"Manager - Active Participation in Daily Calls w/ Supervisor[ \\\"Owns\\\" assigned accounts]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={28}  />
			<TextInput className={"question-card"} name={"7204_0"} label={"Comments"} excel_reference={29}  />
			<Select className={"question-card"} name={"7205_0"} label={"Foreman - Supervise & Train Subordinates[Demonstrates EE oversight, direction & training]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={30}  />
			<Select className={"question-card"} name={"7207_0"} label={"Manager - Supervise & Train Subordinates[Demonstrates EE oversight, direction & training]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={31}  />
			<TextInput className={"question-card"} name={"7208_0"} label={"Comments"} excel_reference={32}  />
			<Select className={"question-card"} name={"7209_0"} label={"Foreman - Manage Employees and their work schedules[Always knows who's working when & adjusts to meet budget  communicates standard to EEs to redirect on  job behavior/work performance]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={33}  />
			<Select className={"question-card"} name={"7210_0"} label={"Manager - Manage Employees and their work schedules[Always knows who's working when & adjusts to meet budget  communicates standard to EEs to redirect on  job behavior/work performance]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={34}  />
			<TextInput className={"question-card"} name={"7211_0"} label={"Comments"} excel_reference={35}  />
		</Accordion>,
		<Accordion index={7212} header={<div className={"question-card"} name={"7212_0"} excel_reference={36} style={{fontSize: '24px'}}  >
				<Paragraph text={"CUSTOMER MANAGEMENT"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"7213_0,7214_0,7215_0,7216_0,7217_0,7218_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"7213_0"} label={"Foreman - Build Great Customer Relationships REMOVE[Gets along great with the customers/Customer Satisfaction based on complaints]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={37}  />
			<Select className={"question-card"} name={"7214_0"} label={"Manager - Build Great Customer Relationships REMOVE[Gets along great with the customers/Customer Satisfaction based on complaints]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={38}  />
			<TextInput className={"question-card"} name={"7215_0"} label={"Comments"} excel_reference={39}  />
			<Select className={"question-card"} name={"7216_0"} label={"Foreman - Prompt Completion of Customer Requests[Quickly performs requests by customer]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={40}  />
			<Select className={"question-card"} name={"7217_0"} label={"Manager - Prompt Completion of Customer Requests[Quickly performs requests by customer]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={41}  />
			<TextInput className={"question-card"} name={"7218_0"} label={"Comments"} excel_reference={42}  />
		</Accordion>,
		<Accordion index={7219} header={<div className={"question-card"} name={"7219_0"} excel_reference={43} style={{fontSize: '24px'}}  >
				<Paragraph text={"COLLABORATION"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"7220_0,7221_0,7222_0,7223_0,7224_0,7225_0,7226_0,7227_0,7228_0,7229_0,7230_0,7231_0,7232_0,7233_0,7234_0,7235_0,7236_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"7220_0"} label={"Foreman - Communication of Work Orders, Broken Equipment,  supplies, etc. (Reports during daily call)[Escalation to Supervisor IF over budget, OT, additional resources required]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={44}  />
			<Select className={"question-card"} name={"7221_0"} label={"Manager - Communication of Work Orders, Broken Equipment,  supplies, etc. (Reports during daily call)[Escalation to Supervisor IF over budget, OT, additional resources required]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={45}  />
			<TextInput className={"question-card"} name={"7222_0"} label={"Comments"} excel_reference={46}  />
			<Select className={"question-card"} name={"7223_0"} label={"Foreman - Compliance w/ Training [No late Training Rosters]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={47}  />
			<Select className={"question-card"} name={"7224_0"} label={"Manager - Compliance w/ Training [No late Training Rosters]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={48}  />
			<TextInput className={"question-card"} name={"7225_0"} label={"Comments"} excel_reference={49}  />
			<Select className={"question-card"} name={"7226_0"} label={"Foreman - RTFs [Collaborate with Supervisor on immediate fix and preventing recurrence of issue]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={50}  />
			<Select className={"question-card"} name={"7227_0"} label={"Manager - RTFs [Collaborate with Supervisor on immediate fix and preventing recurrence of issue]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={51}  />
			<TextInput className={"question-card"} name={"7228_0"} label={"Comments"} excel_reference={52}  />
			<Select className={"question-card"} name={"7229_0"} label={"Foreman - Clocking in/out [Researches open punches and provides Supervisor with actual times]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={53}  />
			<Select className={"question-card"} name={"7230_0"} label={"Manager - Clocking in/out [Researches open punches and provides Supervisor with actual times]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={54}  />
			<TextInput className={"question-card"} name={"7231_0"} label={"Comments"} excel_reference={55}  />
			<Select className={"question-card"} name={"7232_0"} label={"Foreman - Labor/Staffing/Personnel[Collaborate for absentism, call offs and employee issues]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={56}  />
			<Select className={"question-card"} name={"7233_0"} label={"Manager - Labor/Staffing/Personnel[Collaborate for absentism, call offs and employee issues]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={57}  />
			<TextInput className={"question-card"} name={"7234_0"} label={"Comments"} excel_reference={58}  />
			<TextInput className={"question-card"} name={"7235_0"} label={" Foreman - AVERAGE TOTAL SCORE"} excel_reference={59} excel={'(+Q8+Q11+Q14+Q17+Q20+Q23+Q27+Q30+Q33+Q37+Q40+Q44+Q47+Q50+Q53+Q56)/16'}  />
			<TextInput className={"question-card"} name={"7236_0"} label={"Manager - AVERAGE TOTAL SCORE"} excel_reference={60} excel={'(+Q9+Q12+Q15+Q18+Q21+Q24+Q28+Q31+Q34+Q38+Q41+Q45+Q48+Q51+Q54+Q57)/16'}  />
		</Accordion>,
    ];

    return elements;
}

export default get_form_elements;

import React, { Component } from 'react';
import {Wrapper, Container, Header, Div, Paragraph, FormWithChildren, DateTimePicker, TextInput, Select, TextArea, UserSearchInput, SignatureInput, ImageInput, Accordion} from 'library';

function get_form_elements(){
    var elements = [
		<Accordion index={8366} header={<div className={"question-card"} name={"8366_0"} excel_reference={1} style={{fontSize: '24px'}}  >
				<Paragraph text={"Presidential Hotline  "} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"8368_0,8370_0,8382_0,8372_0,8381_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<TextInput className={"question-card"} name={"8368_0"} label={"1. Thank you for contacting the President and sharing your thoughts. Please explain your question, concern, or comment below. Gracias por ponerse en contacto con el Presidente y compartir sus pensamientos. Por favor, explique su pregunta, preocupaci\u00f3n, o comentario "} excel_reference={2}  />
			<Select className={"question-card"} name={"8370_0"} label={"2. Which company does this pertain to? \u00bfDe cual empresa se relaciona? "} options={[{"text": "HB Management Group", "value": "HB Management Group"}, {"text": "Kleen-Tech Services", "value": "Kleen-Tech Services"}, {"text": "Multiple Companies", "value": "Multiple Companies"}]} radio={true} excel_reference={3}  />
			<Select className={"question-card"} name={"8382_0"} label={"3. Is there a specific department that should receive visibility? \u00bfHay un departamento espec\u00edfico que debe ser informado? "} options={[{"text": "\u2022 Accounting / Departamento de Contabilidad", "value": "\u2022 Accounting / Departamento de Contabilidad"}, {"text": "\u2022 Executive / Departamento de Ejecutivo", "value": "\u2022 Executive / Departamento de Ejecutivo"}, {"text": "\u2022 Human Resources / Departamento de Recursos Humanos", "value": "\u2022 Human Resources / Departamento de Recursos Humanos"}, {"text": "\u2022 IT / Departamento de Tecnolog\u00eda de la Informaci\u00f3n", "value": "\u2022 IT / Departamento de Tecnolog\u00eda de la Informaci\u00f3n"}, {"text": "\u2022 Mission Control", "value": "\u2022 Mission Control"}, {"text": "\u2022 Operations / Operaciones", "value": "\u2022 Operations / Operaciones"}, {"text": "\u2022 Payroll / Departamento de N\u00f3minas", "value": "\u2022 Payroll / Departamento de N\u00f3minas"}, {"text": "\u2022 Quality / Departamento de Calidad", "value": "\u2022 Quality / Departamento de Calidad"}, {"text": "\u2022 Sales and Proposals / Departamento de Ventas y Propuestas", "value": "\u2022 Sales and Proposals / Departamento de Ventas y Propuestas"}, {"text": "\u2022 I would only like the President to have visibility / Solamente quiero el Presidente ver este comentario", "value": "\u2022 I would only like the President to have visibility / Solamente quiero el Presidente ver este comentario"}]} radio={true} excel_reference={4}  />
			<TextInput className={"question-card"} name={"8372_0"} label={"4. Please provide your name below, or leave this blank to remain anonymous  Por favor proporcione su nombre, o puede permanecer an\u00f3nimo  "} excel_reference={5}  />
			<TextInput className={"question-card"} name={"8381_0"} label={"5. To receive a follow up, please provide your phone number or e-mail address below  Por favor proporcione su n\u00famero de tel\u00e9fono o direcci\u00f3n de correo electr\u00f3nico a continuaci\u00f3n para que pueda contactar con usted para dar seguimiento "} excel_reference={6}  />
		</Accordion>,
    ];

    return elements;
}

export default get_form_elements;

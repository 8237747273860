import React, { Component } from 'react';
import {resolveVariables} from 'functions';
import {ajaxWrapper, ajaxWrapperFile} from 'functions';
import {TextInput, Select, CSSInput, Button} from 'library';


class DropboxFileInput extends Component {
    static component_name = 'DropboxFileInput';

    constructor(props) {
        super(props);
        this.config = {
            form_components: [
                <TextInput label={'name'} name={'name'} default={'Default Text'} />,
                <TextInput label={'label'} name={'label'} />,
                <Select label={'required'} name={'required'} boolean={true} />,
                <TextInput label={'class'} name={'className'} />,
                <CSSInput label={'css'} name={'style'} />,
            ],
        }

        this.state ={
            files:null,
            uploading: false,
        }

        this.onChange = this.onChange.bind(this);
        this.file_upload = this.file_upload.bind(this);
        this.file_upload_callback = this.file_upload_callback.bind(this);
    }

    onChange(e) {
        this.setState({files:e.target.files});
    }

    file_upload() {
        var files = this.state.files;
        var url = '/dropbox/upload_file/';

        const formData = new FormData();
        for (var index in files) {
            if (index != 'length' && index != 'item') {
                formData.append('files[]',files[index]);
            }
        }
        formData.append('id', this.props.id);

        ajaxWrapperFile("POST", url, formData, this.file_upload_callback);
        this.setState({uploading: true});
    }

    file_upload_callback(value) {
        this.setState({
            message: value,
            uploading: false
        });
        window.cmState.setGlobalState('new_files','');
    }

    render() {
        var input = <input onChange={this.onChange} type="file" className="form-control-file" id="exampleFormControlFile1" name={this.props.name} />;

        var label = null;
        if (this.props.label) {
          var label = <label style={this.props.label_style || {}} htmlFor="exampleFormControlFile1">{this.props.label}</label>;
        }

        var upload = <Button type='primary' onClick={this.file_upload} text='Upload' />;
        if (this.state.uploading){
            upload = <Button type='primary' disabled="disabled" className='disabled' text='Upload' />;
        }

        return (
            <div className={"form-group " + this.props.class} style={this.props.style}>
                {label}
                {input}
                <br/>
                {upload}
            </div>
        )
    }
}

export default DropboxFileInput;

import React, { Component } from 'react';
import {resolveVariables} from 'functions';
import {Div, NumberInput, CSSInput, AddChildComponent, Select} from 'library';

class Container extends React.Component {
    static component_name = 'Container';
    constructor(props) {
        super(props);
        this.state = {width:0, height:0}
        this.config = {
            form_components: [
                <Select label={'Min Height as Page Height?'} name='min_height' boolean={true} />,
                <CSSInput label={'css'} name={'style'} default={{}} />,
            ],
            can_have_children: true,
        }

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    render() {
        var className = "container ";
        if (this.props.className) {
            className += this.props.className;
        }

        var style = Object.assign({}, this.props.style);
        if (this.props.min_height) {
            style['minHeight'] = this.state.height - 150;
        }

        return (
            <Div className={className} style={style}>
              {this.props.children}
            </Div>
        );
    }
}


export default Container;

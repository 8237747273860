import React, { Component } from 'react';
import {resolveVariables} from 'functions';
import {
    TextInput, Select, CSSInput
} from 'library';

class Checkbox extends Component {
    static component_name = 'Checkbox';

    constructor(props) {
        super(props);
        this.state = {options:[]}

        this.config = {
            form_components: [
                <TextInput label={'name'} name={'name'} default={'Default Text'} />,
                <TextInput label={'label'} name={'label'} />,
                <TextInput label={'value'} name={'secret_value'} />,
                <Select label={'required'} name={'required'} boolean={true} defaultoption={false} />,
                <CSSInput label={'css'} name={'style'} default={{}} />,
            ],
        }

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        if (this.props.checkGroup){
            var value = this.props.value;
        }
        else {
            var value = this.props.secret_value;
        }
        if (!(e.target.checked)){
            value = undefined;
        }

        var state = {};
        if (this.props.checkGroup){
            state[this.props.value] = value;
        } else {
            state[this.props.name] = value;
        }

        if (this.props.setParentState){
            this.props.setParentState(state);
        }
        else {
            this.props.setFormState(state);
        }
    }

    render() {
        var checked = false;
        if (this.props.checked === true || this.props.value === true || this.props.value == this.props.secret_value) {
            checked = true
        }
        //using this instead of indexOf because I was having trouble with "4" and 4.
        else if (this.props.checkList) {
            for (var index in this.props.checkList) {
                if (this.props.checkList[index] == this.props.value) {
                    checked = true;
                }
            }
        }

        var value = this.props.secret_value;
        if (!(value)){
            value = this.props.value;
        }

        return (
            <div className="custom-control custom-checkbox" style={this.props.style}>
                <input type="checkbox" className="custom-control-input" checked={checked} id={this.props.name + "-" + value}
                    name={this.props.name} onChange={this.handleChange} value={value}/>
                <label className="custom-control-label" htmlFor={this.props.name + "-" + value}>{this.props.label}</label>
            </div>
        )
    }
}

export default Checkbox;

import React, { Component } from 'react';
import {ajaxWrapper, resolveVariables} from 'functions';

import {Wrapper, Instance, Header, FormWithChildren, TextInput, Container, If, CSSInput} from 'library';

class EditCSSClass extends Component {

    render() {

        return (<div>
				<If logic={[['exists', '{params.1}']]} order={7} delete={""} parent={null} children={""} required={""} form_child_update_key={null} >
					<Container >
						<Header size={3} text={"Add/Edit CSS Class"} form_child_update_key={null}  />
						<Instance dataUrl={"/api/modelWebsite/cssclass/{params.1}/"} objectName={"cssclass"} form_child_update_key={null} >
							<FormWithChildren deleteUrl={resolveVariables({"text":"/api/modelWebsite/cssclass/{props.id}/delete/"}, this)["text"]} submitUrl={resolveVariables({"text":"/api/modelWebsite/cssclass/{props.id}/"}, this)["text"]} redirectUrl={"/css/"} submit_text={"Save"} deleteRedirectUrl={"/css/"} form_child_update_key={null} >
								<TextInput name={"name"} label={"Name"} default={resolveVariables({"text":"{props.name}"}, this)["text"]} form_child_update_key={null}  />
								<CSSInput name={"attributes"} label={"CSS"} default={resolveVariables({"text":"{props.attributes}"}, this)["text"]} form_child_update_key={null}  />
							</FormWithChildren>
						</Instance>
					</Container>
				</If>

				<If order={8} delete={""} parent={null} children={""} required={""} anti_logic={[['exists', '{params.1}']]} form_child_update_key={null} >
					<Container >
						<Header size={3} text={"Add CSS Class"} form_child_update_key={null}  />
						<FormWithChildren submitUrl={"/api/modelWebsite/cssclass/"} redirectUrl={"/css/"} submit_text={"Save"} deleteRedirectUrl={"/css/"} form_child_update_key={null} >
							<TextInput name={"name"} label={"Name"} form_child_update_key={null}  />
							<CSSInput name={"attributes"} label={"CSS"} form_child_update_key={null}  />
						</FormWithChildren>
					</Container>
				</If>
        </div>);
    }
}
export default EditCSSClass;

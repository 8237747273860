import React, { Component } from 'react';
import { ajaxWrapper, resolveVariables, format_date } from 'functions';

import {
    Wrapper,
    Container,
    Header,
    Div,
    Paragraph,
    FormWithChildren,
    TextInput,
    Select,
    TextArea,
    Button,
    ListWithChildren,
    TextAutocomplete,
    Table,
    TableRow,
    TableHeader,
    TableData,
    DateTimePicker,
} from 'library';

import Navbar from 'projectLibrary/navbar.js';
import Footer from 'projectLibrary/footer.js';

import custom_form_lookup from 'pages/custom_functions/custom_form_lookup.js';
import $ from 'jquery';

class Results extends Component {
    constructor(props) {
        super(props);

        var now = new Date();
        var double_value = format_date(now, 'mm/dd/yyyy');

        this.state = {
            search_values: {},
            users: [],
            process: {
                forms: [{ processform: {} }],
            },
            submission_url: '',
        };

        this.load_process = this.load_process.bind(this);
        this.load_search_values = this.load_search_values.bind(this);
        this.load_users = this.load_users.bind(this);
        this.get_submissions = this.get_submissions.bind(this);
    }

    componentDidMount() {
        ajaxWrapper(
            'GET',
            '/api/home/process/' + this.props.process_id + '/?related=forms',
            {},
            this.load_process,
        );
        ajaxWrapper(
            'GET',
            '/search_terms/' + this.props.process_id + '/',
            {},
            this.load_search_values,
        );
        ajaxWrapper(
            'GET',
            '/process_users/' + this.props.process_id + '/',
            {},
            this.load_users,
        );
    }

    load_process(value) {
        var process = value[0]['process'];
        this.setState({ process: process });

        this.get_submissions('', {});
    }

    load_search_values(value) {
        var values = {};
        for (var index = 0; index < value.length; index++) {
            values[value[index]] = value[index];
        }

        this.setState({ search_values: values });
    }

    load_users(value) {
        var users = [];
        for (var index = 0; index < value.length; index++) {
            users.push({
                text: value[index],
                value: value[index],
            });
        }

        this.setState({ users: users });
    }

    load_double_terms = (new_search_value) => {
        ajaxWrapper(
            'GET',
            '/double_terms/' +
                this.props.process_id +
                '/?search_value=' +
                new_search_value,
            {},
            function (value) {
                var values = {};
                for (var index = 0; index < value.length; index++) {
                    values[value[index]] = value[index];
                }

                this.setState({
                    double_values: values,
                    search_value: new_search_value,
                });
            }.bind(this),
        );
    };

    get_submissions(name, state) {
        var global_state = window.cmState.getGlobalState();
        var new_state = Object.assign(
            global_state['result_filters'] || {},
            state,
        );

        var url =
            '/api/home/formsubmission/?related=owner&process=' +
            this.props.process_id +
            '&archived=false&limit=100&order_by=-created_at';

        if ('start_date' in new_state) {
            var start_date = format_date(
                new Date(new_state['start_date']),
                'yyyy-mm-dd',
            );
            url += '&created_at__gte=' + start_date;
        }
        if ('end_date' in new_state) {
            var end_date = format_date(
                new Date(new_state['end_date']),
                'yyyy-mm-dd',
            );
            url += '&created_at__lte=' + end_date;
        }
        if ('search_value' in new_state) {
            url += '&search_value=' + new_state['search_value'];
        }
        if ('double_value' in new_state) {
            url += '&double_value=' + new_state['double_value'];
        }
        if ('user' in new_state) {
            url += '&owner__email=' + new_state['user'];
        }

        var timestamp = Date.now().toString();
        url += '&' + timestamp;

        if (
            'search_value' in new_state &&
            new_state['search_value'] &&
            this.state.search_value != new_state['search_value']
        ) {
            this.load_double_terms(new_state['search_value']);
        }

        this.setState({
            submission_url: url,
        });
    }

    render() {
        var process = this.state.process;
        var form = process.forms[0].processform;
        var search_term = process.search_term || 'Search Term';
        var double_term = 'Date';

        return (
            <div style={{ background: '#f6f6f6', paddingTop: '69px' }}>
                <Navbar
                    style={{
                        position: 'fixed',
                        width: '100%',
                        zIndex: '10',
                        top: '0px',
                    }}
                />

                <Container style={{ background: 'white' }} min_height={true}>
                    <div
                        className="row"
                        style={{ position: 'relative', paddingTop: '15px' }}
                    >
                        <div className="transitions col-12">
                            <Header size={1} text={process.title} />
                            <br />
                            <br />
                        </div>
                        <div className="transitions col-12">
                            <FormWithChildren
                                globalStateName="result_filters"
                                setGlobalState={this.get_submissions}
                                autoSetGlobalState={true}
                                className={'row'}
                            >
                                <Header
                                    size={3}
                                    text={'Filter Results'}
                                    className="col-12"
                                />
                                <TextAutocomplete
                                    label={search_term}
                                    className={'col-lg-4'}
                                    options={this.state.search_values}
                                    name={'search_value'}
                                />
                                <TextAutocomplete
                                    label={double_term}
                                    className={'col-lg-4'}
                                    options={this.state.double_values}
                                    name={'double_value'}
                                />
                                <Select
                                    label={'User'}
                                    className={'col-lg-4'}
                                    name={'user'}
                                    options={this.state.users}
                                />
                                <DateTimePicker
                                    label="Start Date"
                                    className={'col-lg-6'}
                                    name={'start_date'}
                                    display_time={false}
                                />
                                <DateTimePicker
                                    label="End Date"
                                    className={'col-lg-6'}
                                    name={'end_date'}
                                    display_time={false}
                                />
                                ,
                            </FormWithChildren>
                            <br />
                            <br />
                        </div>
                        <div className="transitions col-12">
                            <Header size={3} text={'Submissions'} />
                            <Table>
                                <TableRow>
                                    <TableHeader data={'Location'} />
                                    <TableHeader data={'Date'} />
                                    <TableHeader data={'User'} />
                                    <TableHeader data={'View'} />
                                    <TableHeader data={'Archive'} />
                                </TableRow>
                                <ListWithChildren
                                    dataUrl={this.state.submission_url}
                                    nowrapper={true}
                                    objectName={'formsubmission'}
                                >
                                    <TableRow>
                                        <TableData
                                            data={
                                                resolveVariables(
                                                    {
                                                        text: '{props.search_value}',
                                                    },
                                                    this,
                                                )['text']
                                            }
                                        />
                                        <TableData
                                            data={
                                                resolveVariables(
                                                    {
                                                        text: '{props.double_value}',
                                                    },
                                                    this,
                                                )['text']
                                            }
                                        />
                                        <TableData
                                            data={
                                                resolveVariables(
                                                    {
                                                        text: '"{props.owner.email}".split(\'@\')[0]',
                                                    },
                                                    this,
                                                )['text']
                                            }
                                            eval={true}
                                        />

                                        <TableData>
                                            <Button
                                                href={
                                                    resolveVariables(
                                                        {
                                                            text:
                                                                '/process/' +
                                                                process.id +
                                                                '/review/?search_value={props.search_value}&double_value={props.double_value}',
                                                        },
                                                        this,
                                                    )['text']
                                                }
                                                text={
                                                    <i class="fas fa-file-alt"></i>
                                                }
                                                type={'primary'}
                                            />
                                        </TableData>

                                        <TableData>
                                            <FormWithChildren
                                                submitUrl={
                                                    resolveVariables(
                                                        {
                                                            text: '/api/home/formsubmission/{props.id}/',
                                                        },
                                                        this,
                                                    )['text']
                                                }
                                                defaults={{ archived: true }}
                                                submit_text={
                                                    <i class="fas fa-trash-alt"></i>
                                                }
                                                redirect={this.get_submissions}
                                            />
                                        </TableData>
                                    </TableRow>
                                </ListWithChildren>
                            </Table>
                            <div className="col-12">
                                <br />
                                <br />
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        );
    }
}

export default Results;

import React, { Component } from 'react';
import moment from 'moment';
import 'rc-time-picker/assets/index.css';
import {resolveVariables, format_date_string} from 'functions';
import Datetime from 'react-datetime';
import {TextInput, Select} from 'library';
import TimePicker from 'rc-time-picker';


class TimePicker2 extends Component {
    static component_name = 'TimePicker';
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);

            this.config = {
                form_components: [
                    <TextInput label={'Name'} name={'name'} />,
                    <TextInput label={'Label'} name={'label'} />,
                ],
                can_have_children: true,
            }
    }

    componentDidUpdate (prevProps, prevState, snapshot){
        if (this.props.logic != prevProps.logic){
            this.setState({logic:this.props.logic});
        }
    }

    handleChange(e) {
        var value = e.format('HH:mm');

        var newState = {}
        newState[this.props.name] = value;
        this.props.setFormState(newState);
    }

    render() {
        var key = 'blank';
        var default_moment = moment().hours(12).minutes(0).seconds(0);
        if (this.props.name && this.props.name in this.props && this.props[this.props.name]){
            default_moment = moment(this.props[this.props.name], ['H:m']);
            key = this.props[this.props.name];
        }

        var label = null;
        if (this.props.label && this.props.label != '') {
            label = <label style={this.props.label_style}>{this.props.label}</label>;
        }

        return (
            <div className='form-group'>
                {label}
                <div className={'form-control'}>
                    <TimePicker key={key} use12Hours defaultValue={default_moment} style={{'width':'100%'}} onChange={this.handleChange} showSecond={false} />
                </div>
            </div>
        )
    }
}

export default TimePicker2;

import React from 'react';
import {resolveVariables, get_children} from 'functions';

function resolve_all_children(component, data, form_state) {
    var context = component.props;
    var top_children = get_children(context);
    var components = [];

    for (var index in top_children) {
        var child_component = top_children[index];
        if (!(child_component)){ continue;}

        var dataMapping = Object.assign({}, data);
        var children = child_component.props.children;
        for (var prop_name in child_component.props) {
            if (['logic', 'anti_logic', 'dataUrl', 'optionsUrl'].indexOf(prop_name) > -1){
                continue;
            }
            dataMapping[prop_name] = child_component.props[prop_name];
        }

        var full_data = Object.assign({'props':data}, window.cmState.getGlobalState());

        dataMapping = resolveVariables(dataMapping, full_data);

        if (form_state){
            dataMapping = get_form_props(form_state, child_component, dataMapping, index, component.props.autoFocus);
        }

        var mapped_children = resolve_all_children(child_component, data, form_state);
        if (mapped_children.length > 0){
            dataMapping['children'] = mapped_children;
        }

        var componentInstance = React.cloneElement(child_component, dataMapping);
        components.push(componentInstance);
    }

    return components;
}


function get_form_props(state, component, data, index, autoFocus) {
    if (component.props) {
        var value = state[component.props.name];

        data['value'] = value;
        if (index == 0 && autoFocus) {
          data['autoFocus'] = true;
        }
    }

    return data
}

export default resolve_all_children;

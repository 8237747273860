import React, { Component } from 'react';
import {Wrapper, Container, Header, Div, Paragraph, FormWithChildren, DateTimePicker, TextInput, Select, TextArea, UserSearchInput, SignatureInput, ImageInput, Accordion} from 'library';

function get_form_elements(){
    var elements = [
		<Accordion index={13479} header={<div className={"question-card"} name={"13479_0"} excel_reference={1} style={{fontSize: '24px'}}  >
				<Paragraph text={"Checklist Upload"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"13480_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<TextInput className={"question-card"} name={"13480_0"} label={"For the week ending:"} excel_reference={2} style={{fontSize: '14px'}}  />
			<div className={"question-card"} name={"13481_0"} excel_reference={3}  >
				<Paragraph text={"Take an upload a picture of your completed checklists"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"13482_0"} excel_reference={4}  />
		</Accordion>,
    ];

    return elements;
}

export default get_form_elements;

import React, { Component } from 'react';
import {ajaxWrapper, resolveVariables} from 'functions';

import {Wrapper, LogInForm, Header, Div, Container, Span, Link, Image,
    Paragraph, Button, If, Alert, FormWithChildren, TextInput, Checkbox,
    Select, TextArea, ImageInput, ListWithChildren, CopyToClipboard,
    PageBreak } from 'library';
import Navbar from 'projectLibrary/navbar.js';
import Footer from 'projectLibrary/footer.js';


class ProcessUser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            position: this.props.position
        };

        this.change_position = this.change_position.bind(this);
        this.remove_user = this.remove_user.bind(this);
    }

    change_position(name, state){
        var data = {};

        var new_position = state['position'];
        if (new_position == 'User'){
            data['new_users[]'] = JSON.stringify([this.props.id]);
        }
        else if (new_position == 'Manager'){
            data['new_managers[]'] = JSON.stringify([this.props.id]);
        }
        else if (new_position == 'Master'){
            data['new_masters[]'] = JSON.stringify([this.props.id]);
        }
        if (new_position != this.props.position){
            ajaxWrapper('POST', '/api/home/process/'+ this.props.process.id +'/', data, this.remove_user);
        }
    }

    remove_user(e){
        var data = {};
        if (this.props.position == 'User'){
            data['new_users[]__remove'] = JSON.stringify([this.props.id]);
        }
        else if (this.props.position == 'Manager'){
            data['new_managers[]__remove'] = JSON.stringify([this.props.id]);
        }
        else if (this.props.position == 'Master'){
            data['new_masters[]__remove'] = JSON.stringify([this.props.id]);
        }

        ajaxWrapper('POST', '/api/home/process/'+ this.props.process.id +'/', data, this.props.refresh_data);
    }

    render() {
        var positions = [
            {"text": "User", "value": 'User'},
            {"text": "Manager", "value": 'Manager'},
            {"text": "Master", "value": 'Master'}
        ];

        return (
            <div class="col-xs-12 usersmall">
                <div class="useractions">
                    <FormWithChildren defaults={this.state} autoSetGlobalState={true} globalStateName={'position'} setGlobalState={this.change_position} >
                        <a class="btn btn-danger userprocessremove" style={{color:'white', float:'right'}} onClick={this.remove_user} >Remove</a>
                        <Select className='normal-radios' style={{float:'right', paddingRight:'10px'}} radio={true} not_vertical={true} name={"position"} options={positions}  />,
                    </FormWithChildren>
                </div>
                <h3>{this.props.email}</h3>
                <div class="row col-12" style={{padding:'0px'}} >
                    <div class="col-6" ><div>Created: {this.props.date_joined}</div></div>
                </div>
            </div>
        )
    }
}


class EmailUser extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };

        this.remove_user = this.remove_user.bind(this);
    }

    remove_user(e){
        var data = {};
        data['new_email_users[]__remove'] = JSON.stringify([this.props.id]);

        ajaxWrapper('POST', '/api/home/processform/'+ this.props.form.id +'/', data, this.props.refresh_data);
    }

    render() {
        return (
            <div class="col-xs-12 usersmall">
                <div class="useractions">
                    <a class="btn btn-danger userprocessremove" style={{color:'white', float:'right'}} onClick={this.remove_user} >Remove</a>
                </div>
                <h3>{this.props.email}</h3>
                <div class="row col-12" style={{padding:'0px'}} >
                    <div class="col-6" ><div>Created: {this.props.date_joined}</div></div>
                </div>
            </div>
        )
    }
}


class SearchTerm extends Component {
    constructor(props) {
        super(props);

        this.remove_term = this.remove_term.bind(this);
    }


    remove_term(e){
        ajaxWrapper('POST', '/api/remove_location/'+ this.props.process.id +'/', {'name': this.props.name}, this.props.refresh_data);
    }

    render() {
        return (
            <div class="col-xs-12 usersmall">
                <div class="useractions">
                    <a class="btn btn-danger userprocessremove" style={{color:'white', float:'right'}} onClick={this.remove_term} >Archive</a>
                </div>
                <h3>{this.props.name}</h3>
            </div>
        )
    }
}



class Edit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            process: {
                new_masters: [],
                new_managers: [],
                new_users: [],
            },
            search_terms: []
        };

        this.refresh_data = this.refresh_data.bind(this);
        this.load_process = this.load_process.bind(this);
        this.load_search_terms = this.load_search_terms.bind(this);
        this.change_success = this.change_success.bind(this);

        this.new_locations_callback = this.new_locations_callback.bind(this);
    }

    componentDidMount(){
        this.refresh_data();
    }


    change_success(){
        this.setState({message: 'Changes submitted successfully!'});
        this.refresh_data();
    }

    refresh_data(){
        ajaxWrapper('GET', '/api/home/process/'+ this.props.id +'/?related=forms,forms__new_email_users,new_masters,new_managers,new_users', {}, this.load_process);
        ajaxWrapper('GET', '/search_terms/'+ this.props.id +'/', {}, this.load_search_terms);
    }

    load_process(value){
        this.setState({
            process: value[0]['process']
        });
    }

    load_search_terms(value){
        var terms = [];
        for (var i=0;i<value.length;i++){
            terms.push({'search': {'name': value[i]}});
        }
        this.setState({
            search_terms: terms
        });
    }

    new_locations_callback(){
        this.setState({
            new_locations_message: 'Saved Successfully!'
        });
        this.refresh_data();
    }

    render() {
        var process = this.state.process;

        var header = <h2>Create Process</h2>;
        if (process){
            header = <h2>Edit Process Details</h2>;
        }

        var users = [];
        for (var index=0;index<process.new_masters.length;index++){
            var user = process.new_masters[index];
            user['user']['position'] = 'Master';
            users.push(user);
        }
        for (var index=0;index<process.new_managers.length;index++){
            var user = process.new_managers[index];
            user['user']['position'] = 'Manager';
            users.push(user);
        }
        for (var index=0;index<process.new_users.length;index++){
            var user = process.new_users[index];
            user['user']['position'] = 'User';
            users.push(user);
        }

        var email_users = [];
        var form = {};
        if (process.forms){
            form = process.forms[0]['processform'];
            for (var item of process.forms[0]['processform']['new_email_users']){
                email_users.push(item);
            }
        }

        var delete_props = {
            deleteUrl: '/api/home/Process/'+ process.id +'/delete/',
            deleteText: 'Archive Process'
        };
        var activate_form = null;
        if (!(process.active)){
            delete_props = {};
            activate_form = <Alert type={'warning'} style={{marginTop: '10px'}} ><FormWithChildren key='edit_title' defaults={{active: true}} redirect={this.change_success}
                submitUrl={'/api/home/Process/'+ process.id +'/'} submitButtonType={'default'} submit_text={'Reactivate Process'} >
                <Header size={4} text={'This process is currently archived!'} />
            </FormWithChildren></Alert>;
        }

        var new_locations_message = null;
        if (this.state.new_locations_message){
            new_locations_message = <Alert text={this.state.new_locations_message} type='success' />;
        }

        return (<div>
			<Navbar />

            <Container style={{background: 'white'}} min_height={true} >
                <br/>
                <a href="/profile" style={{display:'inline-block', padding:'5px'}} ><span class="fa fa-arrow-left" style={{marginRight:'10px'}} ></span>  Return to Profile</a>
                <Header size={2} text={'Edit Process'} />

                <If logic={[['exists', this.state.message]]}>
                  <Alert type='default' text={this.state.message} />
                </If>

                <FormWithChildren key='edit_title' defaults={{title: process.title}} redirect={this.change_success}
                    submitUrl={'/api/home/Process/'+ process.id +'/'} submitButtonType={'default'} {...delete_props} >
                    <TextInput name='title' label='Title:' />
                </FormWithChildren>
                {activate_form}

                <br/><br/>
                <PageBreak />
                <br/>

                <Header size={3} text={'Users'} />
                <div className='formlist' >
                    <ListWithChildren dataList={users} objectName={"user"} nowrapper={true} >
                        <ProcessUser key={resolveVariables({"text":"{props.id}"}, this)["text"]} process={process} refresh_data={this.refresh_data} />
                    </ListWithChildren>
                </div>
                <br/>

                <Header size={3} text={'Add New Users'} />
                <div className='formlist' >
                    <FormWithChildren key='create_children' submitUrl={'/create_children/'+ process.id +'/'} submit_text='Submit Emails' submitButtonType='default' redirect={this.refresh_data} >
                        <TextArea name="emails" placeholder="Enter emails separated by commas" />
                    </FormWithChildren>
                </div>

                <br/><br/>

                <PageBreak />
                <br/>

                <Header size={3} text={'Locations'} />
                <Alert type='info' text='Archiving Locations will also archive associated submissions' />
                <div className='formlist' >
                    <ListWithChildren dataList={this.state.search_terms} objectName={"search"} nowrapper={true} >
                        <SearchTerm key={resolveVariables({"text":"{props.name}"}, this)["text"]} process={process} refresh_data={this.refresh_data} />
                    </ListWithChildren>
                </div>
                <br/>

                <Header size={3} text={'Add New Locations'} />
                <div className='formlist' >
                    <FormWithChildren key='who_can_create_locations' submitUrl={'/api/home/Process/'+ process.id +'/'} submit_text='Save'
                        defaults={{anyone_can_add_locations: process.anyone_can_add_locations}} redirect={this.new_locations_callback} >
                        <p>Who can add new locations?</p>
                        <Select name='anyone_can_add_locations' radio={true} not_vertical={true}
                            options={[{'text':'Anyone can add locations','value':'true'}, {'text':'Only masters can add locations','value':'false'}]} />
                    </FormWithChildren>
                    {new_locations_message}
                </div>
                <br/>

                <div className='formlist' >
                    <FormWithChildren key='create_locations' submitUrl={'/api/create_locations/'+ process.id +'/'} submit_text='Submit Locations' redirect={this.refresh_data} >
                        <TextArea name="locations" placeholder="Enter locations separated by commas" />
                    </FormWithChildren>
                </div>


                <br/><br/>
                <PageBreak />
                <br/>

                <Header size={3} text={'Email Every Submission'} />
                <div className='formlist' >
                    <ListWithChildren dataList={email_users} objectName={"user"} nowrapper={true} >
                        <EmailUser key={resolveVariables({"text":"{props.id}"}, this)["text"]} form={form} refresh_data={this.refresh_data} />
                    </ListWithChildren>
                </div>
                <br/>

                <Header size={3} text={'Add New Emails'} />
                <div className='formlist' >
                    <FormWithChildren key='create_children' submitUrl={'/create_email_users/'+ form.id +'/'} submit_text='Submit Emails' submitButtonType='default' redirect={this.refresh_data} >
                        <TextArea name="emails" placeholder="Enter emails separated by commas" />
                    </FormWithChildren>
                </div>



                <br/><br/><br/><br/>
            </Container>

            <Footer />
        </div>);
    }
}

export default Edit;

import React, { Component } from 'react';
import {Wrapper, Container, Header, Div, Paragraph, FormWithChildren, DateTimePicker, TextInput, Select, TextArea, UserSearchInput, SignatureInput, ImageInput, Accordion} from 'library';

function get_form_elements(){
    var elements = [
		<Accordion index={13144} header={<div className={"question-card"} name={"13144_0"} excel_reference={1} style={{fontSize: '24px'}}  >
				<Paragraph text={"Plugging- In Scorecard"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"13145_0,13146_0,13147_0,13148_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<TextInput className={"question-card"} name={"13145_0"} label={"Name"} excel_reference={2}  />
			<TextInput className={"question-card"} name={"13146_0"} label={"Region"} excel_reference={3}  />
			<DateTimePicker className={"question-card"} name={"13147_0"} label={"Date"} excel_reference={4}  />
			<UserSearchInput className={"question-card"} name={"13148_0"} label={"Type and then CLICK on your SUPERVISOR's e-mail address here"} excel_reference={5}  />
		</Accordion>,
		<Accordion index={13149} header={<div className={"question-card"} name={"13149_0"} excel_reference={6} style={{fontSize: '24px'}}  >
				<Paragraph text={"Plugging-In Score"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"13151_0,13152_0,13153_0,13155_0,13156_0,13157_0,13159_0,13160_0,13161_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<div className={"question-card"} name={"13150_0"} excel_reference={7} style={{fontSize: '14px'}}  >
				<Paragraph text={"Budget Compliance"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"13151_0"} label={"Employee - Leads direct reports to maintain budget compliance (labor, supplies, etc.). Plugs direct reports into Gap Process."} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={8}  />
			<Select className={"question-card"} name={"13152_0"} label={"Supervisor - Leads direct reports to maintain budget compliance (labor, supplies, etc.). Plugs direct reports into Gap Process."} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={9}  />
			<TextArea className={"question-card"} name={"13153_0"} label={"Comments"} excel_reference={10}  />
			<div className={"question-card"} name={"13154_0"} excel_reference={11} style={{fontSize: '14px'}}  >
				<Paragraph text={"Contract Compliance"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"13155_0"} label={"Employee - Holds direct reports accountable to contractual requirements via SPTs. Manages account changes through timely SOAs."} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={12}  />
			<Select className={"question-card"} name={"13156_0"} label={"Supervisor - Holds direct reports accountable to contractual requirements via SPTs. Manages account changes through timely SOAs."} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={13}  />
			<TextArea className={"question-card"} name={"13157_0"} label={"Comments"} excel_reference={14}  />
			<div className={"question-card"} name={"13158_0"} excel_reference={15} style={{fontSize: '14px'}}  >
				<Paragraph text={"Organizational Management"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"13159_0"} label={"Employee - Ensure staffing levels meet Organizational Standards. Hold direct reports accountable to certification trainings and performance standards."} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={16}  />
			<Select className={"question-card"} name={"13160_0"} label={"Supervisor - Ensure staffing levels meet Organizational Standards. Hold direct reports accountable to certification trainings and performance standards."} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={17}  />
			<TextArea className={"question-card"} name={"13161_0"} label={"Comments"} excel_reference={18}  />
		</Accordion>,
		<Accordion index={13162} header={<div className={"question-card"} name={"13162_0"} excel_reference={19} style={{fontSize: '24px'}}  >
				<Paragraph text={"Own Customer Management Score"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"13164_0,13165_0,13166_0,13168_0,13169_0,13170_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<div className={"question-card"} name={"13163_0"} excel_reference={20} style={{fontSize: '14px'}}  >
				<Paragraph text={"Customer Satisfaction"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"13164_0"} label={"Employee - Manages low customers scores timely. Holds direct reports accountable to reporting account changes."} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={21}  />
			<Select className={"question-card"} name={"13165_0"} label={"Supervisor - Manages low customers scores timely. Holds direct reports accountable to reporting account changes."} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={22}  />
			<TextArea className={"question-card"} name={"13166_0"} label={"Comments"} excel_reference={23}  />
			<div className={"question-card"} name={"13167_0"} excel_reference={24} style={{fontSize: '14px'}}  >
				<Paragraph text={"Account Changes"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"13168_0"} label={"Employee - Coordinates and supports phase in/out of accounts. Holds direct reports accountable to reporting account changes."} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={25}  />
			<Select className={"question-card"} name={"13169_0"} label={"Supervisor - Coordinates and supports phase in/out of accounts. Holds direct reports accountable to reporting account changes."} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={26}  />
			<TextArea className={"question-card"} name={"13170_0"} label={"Comments"} excel_reference={27}  />
		</Accordion>,
		<Accordion index={13171} header={<div className={"question-card"} name={"13171_0"} excel_reference={28} style={{fontSize: '24px'}}  >
				<Paragraph text={"Collaboration "} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"13173_0,13174_0,13175_0,13177_0,13178_0,13179_0,13181_0,13182_0,13183_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<div className={"question-card"} name={"13172_0"} excel_reference={29} style={{fontSize: '14px'}}  >
				<Paragraph text={"Weekly LEADERSHIP Meetings (WLMs)"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"13173_0"} label={"Employee - Promotes and encourages productive actions in WLMs. Trains and collaborates direct reports to be plugged into SPTs (as active participants in WLM)."} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={30}  />
			<Select className={"question-card"} name={"13174_0"} label={"Supervisor - Promotes and encourages productive actions in WLMs. Trains and collaborates direct reports to be plugged into SPTs (as active participants in WLM)."} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={31}  />
			<TextArea className={"question-card"} name={"13175_0"} label={"Comments"} excel_reference={32}  />
			<div className={"question-card"} name={"13176_0"} excel_reference={33} style={{fontSize: '14px'}}  >
				<Paragraph text={"Supervision & Oversight of Supervisors"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"13177_0"} label={"Employee - Advocates for positive change using Plugged In Scorecard to promote training. Advocates direct reports to take ownership of staff and plugging into SPTs. Approves ECs/terminations with HR assistance. Regularly reviews daily call template and updates to promote meaningful DCs."} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={34}  />
			<Select className={"question-card"} name={"13178_0"} label={"Supervisor - Advocates for positive change using Plugged In Scorecard to promote training. Advocates direct reports to take ownership of staff and plugging into SPTs. Approves ECs/terminations with HR assistance. Regularly reviews daily call template and updates to promote meaningful DCs."} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={35}  />
			<TextArea className={"question-card"} name={"13179_0"} label={"Comments"} excel_reference={36}  />
			<div className={"question-card"} name={"13180_0"} excel_reference={37} style={{fontSize: '14px'}}  >
				<Paragraph text={"Raise the Flag "} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"13181_0"} label={"Employee - Actively creates RTFs. Advocates and follows up on RTFs and final RCA/ICA. Plug in direct reports for personal creation and commitment to actions through RTF. Verifies and assesses direct reports related to RTF minimum/maximum quantity goals."} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={38}  />
			<Select className={"question-card"} name={"13182_0"} label={"Supervisor - Actively creates RTFs. Advocates and follows up on RTFs and final RCA/ICA. Plug in direct reports for personal creation and commitment to actions through RTF. Verifies and assesses direct reports related to RTF minimum/maximum quantity goals."} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={39}  />
			<TextArea className={"question-card"} name={"13183_0"} label={"Comments"} excel_reference={40}  />
		</Accordion>,
		<Accordion index={13184} header={<div className={"question-card"} name={"13184_0"} excel_reference={41} style={{fontSize: '24px'}}  >
				<Paragraph text={"LEADERSHIP"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"13186_0,13187_0,13188_0,13190_0,13191_0,13192_0,13193_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<div className={"question-card"} name={"13185_0"} excel_reference={42} style={{fontSize: '14px'}}  >
				<Paragraph text={"LEADERSHIP Development"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"13186_0"} label={"Employee - Leads team to embrace and apply the LEADERSHIP development section of Strategic plan. Coaches and develops Supervisors to get direct reports plugged in."} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={43}  />
			<Select className={"question-card"} name={"13187_0"} label={"Supervisor - Leads team to embrace and apply the LEADERSHIP development section of Strategic plan. Coaches and develops Supervisors to get direct reports plugged in."} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={44}  />
			<TextArea className={"question-card"} name={"13188_0"} label={"Comments"} excel_reference={45}  />
			<div className={"question-card"} name={"13189_0"} excel_reference={46} style={{fontSize: '14px'}}  >
				<Paragraph text={"LEADERSHIP Assessments"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"13190_0"} label={"Employee - Continually advocates for positive change by conducting LEADERSHIP assessments. Ensuring we have the right people (yes, plug them in, no - develop or replace)"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={47}  />
			<Select className={"question-card"} name={"13191_0"} label={"Supervisor -  Continually advocates for positive change by conducting LEADERSHIP assessments. Ensuring we have the right people (yes, plug them in, no - develop or replace)"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={48}  />
			<TextArea className={"question-card"} name={"13192_0"} label={"Comments"} excel_reference={49}  />
		</Accordion>,
		<Accordion index={13194} header={<div className={"question-card"} name={"13194_0"} excel_reference={51} style={{fontSize: '24px'}}  >
				<Paragraph text={"Strategic Plan Awareness"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"13196_0,13197_0,13198_0,13199_0,13200_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<div className={"question-card"} name={"13195_0"} excel_reference={52} style={{fontSize: '14px'}}  >
				<Paragraph text={"Meeting or Exceeding  Established Goals (by assigned region(s)/customer(s)/direct report(s)"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"13196_0"} label={"Employee - Make area Supervisors/Supervisors, Leads and Foreman aware of how they contribute to the accomplishment of their applicable Strategic Plan goals. Assigned region(s) and direct reports meet or exceed all goals established in the annual Strategic Plan including growth, operating margin, pluggin in, and customer satisfaction."} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={53}  />
			<Select className={"question-card"} name={"13197_0"} label={"Supervisor - Make Area Supervisors/Supervisors, Leads and Foreman aware of how they contribute to the accomplishment of their applicable Strategic Plan goals. Assigned region(s) and direct reports meet or exceed all goals established in the annual Strategic Plan including growth, operating margin, pluggin in, and customer satisfaction."} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={54}  />
			<TextArea className={"question-card"} name={"13198_0"} label={"Comments"} excel_reference={55}  />
			<TextInput className={"question-card"} name={"13199_0"} label={"Average Employee Score"} excel_reference={56} excel={'(+Q8+Q12+Q16+Q21+Q25+Q30+Q34+Q38+Q43+Q47+Q53)/11'}  />
			<TextInput className={"question-card"} name={"13200_0"} label={"Average Supervisor Score"} excel_reference={57} excel={'(+Q9+Q13+Q17+Q22+Q26+Q31+Q35+Q39+Q44+Q48+Q54)/11'}  />
		</Accordion>,
    ];

    return elements;
}

export default get_form_elements;

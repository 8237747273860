import {ajaxWrapper} from 'functions';


function submit_pending_submissions(value, callback){
    var global_state = window.cmState.getGlobalState();
    var submissions = localStorage.getItem('pending_submissions');
    if (submissions){submissions = JSON.parse(submissions);}
    if (submissions.length){ submissions = {}; }

    if (submissions && Object.keys(submissions).length > 0){
        var submission_id = Object.keys(submissions)[0];
        var submission = submissions[submission_id];
        window.cmState.setGlobalState('pending_submission', submission_id);
        if (callback){
            window.cmState.setGlobalState('pending_callback', callback);
        }

        ajaxWrapper('POST', submission.url, submission.data, email_followup);
    }
    else {
        if (callback){
            callback();
        }
        else {
            global_state['pending_callback']();
        }

        localStorage.removeItem('pending_submissions');
    }
}

function email_followup(value){
    var global_state = window.cmState.getGlobalState();

    var url =  '/email_followup/'+ global_state.pending_submission +'/';
    ajaxWrapper('POST', url, {}, pending_success);
}

function pending_success(value){
    var global_state = window.cmState.getGlobalState();
    var pending_submission = global_state['pending_submission'];
    var submissions = localStorage.getItem('pending_submissions');

    if (submissions){
        submissions = JSON.parse(submissions);
        delete submissions[pending_submission];
        localStorage.setItem('pending_submissions', JSON.stringify(submissions));
    }

    submit_pending_submissions();
}


export default submit_pending_submissions;

import React, { Component } from 'react';
import {Wrapper, Container, Header, Div, Paragraph, FormWithChildren, DateTimePicker, TextInput, Select, TextArea, UserSearchInput, SignatureInput, ImageInput, Accordion} from 'library';

function get_form_elements(){
    var elements = [
		<Accordion index={5413} header={<div className={"question-card"} name={"5413_0"} excel_reference={1} style={{fontSize: '24px'}}  >
				<Paragraph text={"QUALITY CONTROL INSPECTION"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"5414_0,5417_0,5418_0,5419_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"5414_0"} label={"Select Position Below if QC Form is Completed by a Supervisor or Manager"} options={[{"text": "Supervisor", "value": "Supervisor"}, {"text": "Area Supervisor", "value": "Area Supervisor"}, {"text": "Dir/Mgr of Operations", "value": "Dir/Mgr of Operations"}, {"text": "Vice President", "value": "Vice President"}]} radio={true} excel_reference={2}  />
			<TextInput className={"question-card"} name={"5417_0"} label={"Area:"} excel_reference={5} required={true}  />
			<TextInput className={"question-card"} name={"5418_0"} label={"Completed by:"} excel_reference={6} required={true}  />
			<TextInput className={"question-card"} name={"5419_0"} label={"Supervisor"} excel_reference={7} required={true}  />
			<div className={"question-card"} name={"5420_0"} excel_reference={8}  >
				<Paragraph text={"5 \u2013 Perfect (exceeds contract requirements/no issues)   "} style={{margin:"0px", fontSize:"inherit"}} />
				<Paragraph text={"4 \u2013 Good (meets contract requirements)   "} style={{margin:"0px", fontSize:"inherit"}} />
				<Paragraph text={"3 \u2013 Fair (a couple {1 or 2} issues, Work Order required)  "} style={{margin:"0px", fontSize:"inherit"}} />
				<Paragraph text={"2 \u2013 Poor (multiple {3 to 5} issues, Work Order required)   "} style={{margin:"0px", fontSize:"inherit"}} />
				<Paragraph text={"1 \u2013 Bad (numerous {more than 5} issues, Work Order required)   "} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
		</Accordion>,
		<Accordion index={5421} header={<div className={"question-card"} name={"5421_0"} excel_reference={9} anchor={true} anchor_text={"MAIN ENTRANCE/LOBBY"} style={{fontSize: '24px'}}  >
				<Paragraph text={"MAIN ENTRANCE/LOBBY"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"5422_0,5423_0,5424_0,5425_0"} sub_total={"5430_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"5422_0"} label={"Door Glass / Side Glass - Main entrance"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={10}  />
			<Select className={"question-card"} name={"5423_0"} label={"Floors - Corners &amp; Edges / Walk-Off Mats"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={11}  />
			<Select className={"question-card"} name={"5424_0"} label={"Trash Containers / Ash Urns"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={12}  />
			<Select className={"question-card"} name={"5425_0"} label={"Lobby Furniture &amp; Fixtures"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={13}  />
			<TextInput className={"question-card"} name={"5426_0"} label={"COMMENTS"} excel_reference={14} fail_comment={true}  />
			<div className={"question-card"} name={"5427_0"} excel_reference={15}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"5428_0"} excel_reference={16}  />
			<Select className={"question-card"} name={"5429_0"} label={"Create Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={17}  />
			<TextInput className={"question-card"} name={"5430_0"} label={"Total Rating - Maximum Score 20"} excel_reference={18} excel={'Q10+Q11+Q12+Q13'}  />
		</Accordion>,
		<Accordion index={5431} header={<div className={"question-card"} name={"5431_0"} excel_reference={19} anchor={true} anchor_text={"COMMON AREAS"} style={{fontSize: '24px'}}  >
				<Paragraph text={"COMMON AREAS"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"5432_0,5433_0,5434_0,5435_0,5436_0,5437_0,5438_0,5439_0,5440_0,5441_0"} sub_total={"5446_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"5432_0"} label={"Stairway/Ramp; Landings"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={20}  />
			<Select className={"question-card"} name={"5433_0"} label={"Interior/Exterior Glass"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={21}  />
			<Select className={"question-card"} name={"5434_0"} label={"High Dusting"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={22}  />
			<Select className={"question-card"} name={"5435_0"} label={"Low Dusting"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={23}  />
			<Select className={"question-card"} name={"5436_0"} label={"A/C Vents &amp; Return Air Vents"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={24}  />
			<Select className={"question-card"} name={"5437_0"} label={"Carpets Vacuumed / Spot Clean Stains"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={25}  />
			<Select className={"question-card"} name={"5438_0"} label={"Elevators/Tracks/Walls"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={26}  />
			<Select className={"question-card"} name={"5439_0"} label={"Hard Surface Floors (Swept, Mopped, Buffed)"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={27}  />
			<Select className={"question-card"} name={"5440_0"} label={"Corners, Edges, Baseboards"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={28}  />
			<Select className={"question-card"} name={"5441_0"} label={"Wall Spotting"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={29}  />
			<TextInput className={"question-card"} name={"5442_0"} label={"COMMENTS"} excel_reference={30} fail_comment={true}  />
			<div className={"question-card"} name={"5443_0"} excel_reference={31}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"5444_0"} excel_reference={32}  />
			<Select className={"question-card"} name={"5445_0"} label={"Create Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={33}  />
			<TextInput className={"question-card"} name={"5446_0"} label={"Total Rating - Maximum Score 50"} excel_reference={34} excel={'+Q20+Q21+Q22+Q23+Q24+Q25+Q26+Q27+Q28+Q29'}  />
		</Accordion>,
		<Accordion index={5447} header={<div className={"question-card"} name={"5447_0"} excel_reference={35} anchor={true} anchor_text={"OFFICES/ADMIN. AREAS"} style={{fontSize: '24px'}}  >
				<Paragraph text={"OFFICES/ADMIN. AREAS"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"5448_0,5449_0,5450_0,5451_0,5452_0,5453_0,5454_0,5455_0"} sub_total={"5460_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"5448_0"} label={"Trash Removed &amp; Liner Replaced"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={36}  />
			<Select className={"question-card"} name={"5449_0"} label={"Carpets Vacuumed &amp; Stains Spot Cleaned"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={37}  />
			<Select className={"question-card"} name={"5450_0"} label={"Vacuum Corners, Edges, &amp; Under Furniture"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={38}  />
			<Select className={"question-card"} name={"5451_0"} label={"Interior Glass"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={39}  />
			<Select className={"question-card"} name={"5452_0"} label={"High Dusting"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={40}  />
			<Select className={"question-card"} name={"5453_0"} label={"Low Dusting"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={41}  />
			<Select className={"question-card"} name={"5454_0"} label={"Blinds / Window Sills Dusted"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={42}  />
			<Select className={"question-card"} name={"5455_0"} label={"Hard Surface Floors (Swept, Mopped, Buffed)"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={43}  />
			<TextInput className={"question-card"} name={"5456_0"} label={"COMMENTS"} excel_reference={44} fail_comment={true}  />
			<div className={"question-card"} name={"5457_0"} excel_reference={45}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"5458_0"} excel_reference={46}  />
			<Select className={"question-card"} name={"5459_0"} label={"Create Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={47}  />
			<TextInput className={"question-card"} name={"5460_0"} label={"Total Rating - Maximum Score 40"} excel_reference={48} excel={'+Q36+Q37+Q38+Q39+Q40+Q41+Q42+Q43'}  />
		</Accordion>,
		<Accordion index={5461} header={<div className={"question-card"} name={"5461_0"} excel_reference={49} anchor={true} anchor_text={"RESTROOMS"} style={{fontSize: '24px'}}  >
				<Paragraph text={"RESTROOMS"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"5462_0,5463_0,5464_0,5465_0,5466_0,5467_0,5468_0,5469_0,5470_0,5471_0,5472_0,5473_0,5474_0"} sub_total={"5493_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"5462_0"} label={"Trash Removed &amp; Liner Replaced"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={50}  />
			<Select className={"question-card"} name={"5463_0"} label={"Clean & Polish Stainless Steel Fixtures"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={51}  />
			<Select className={"question-card"} name={"5464_0"} label={"Replenish Tissue, Towels, &amp; Soap Dispensers"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={52}  />
			<Select className={"question-card"} name={"5465_0"} label={"Mirrors &amp; Frames"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={53}  />
			<Select className={"question-card"} name={"5466_0"} label={"Showers"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={54}  />
			<Select className={"question-card"} name={"5467_0"} label={"High Dusting"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={55}  />
			<Select className={"question-card"} name={"5468_0"} label={"Low Dusting"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={56}  />
			<Select className={"question-card"} name={"5469_0"} label={"Sinks, Faucets, &amp; Counter Tops"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={57}  />
			<Select className={"question-card"} name={"5470_0"} label={"Floors Swept, Mopped, &amp; Scrubbed"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={58}  />
			<Select className={"question-card"} name={"5471_0"} label={"Toilets - Bowls, Seats, Rim, Plumbing Fixtures"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={59}  />
			<Select className={"question-card"} name={"5472_0"} label={"Urinals - Bowl, Rim, Plumbing Fixtures"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={60}  />
			<Select className={"question-card"} name={"5473_0"} label={"Partition Walls, Doors, Etc."} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={61}  />
			<Select className={"question-card"} name={"5474_0"} label={"A/C Vents &amp; Return Air Vents"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={62}  />
			<TextInput className={"question-card"} name={"5475_0"} label={"COMMENTS"} excel_reference={63} fail_comment={true}  />
			<div className={"question-card"} name={"5476_0"} excel_reference={64}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"5477_0"} excel_reference={65}  />
			<Select className={"question-card"} name={"5478_0"} label={"Create Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={66}  />
			<TextInput className={"question-card"} name={"5479_0"} label={"Total Rating - Maximum Score 65"} excel_reference={67} excel={'+Q50+Q51+Q52+Q53+Q54+Q55+Q56+Q57+Q58+Q59+Q60+Q61+Q62'}  />
			<Select className={"question-card"} name={"5481_0"} label={"Floors Swept, Mopped, Vacuum &amp; Buffed"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={69}  />
			<Select className={"question-card"} name={"5482_0"} label={"High Dusting"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={70}  />
			<Select className={"question-card"} name={"5483_0"} label={"Low Dusting"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={71}  />
			<Select className={"question-card"} name={"5484_0"} label={"Tables / Chairs"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={72}  />
			<Select className={"question-card"} name={"5485_0"} label={"A/C Vents &amp; Return Air Vents"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={73}  />
			<Select className={"question-card"} name={"5486_0"} label={"Trash Removed &amp; Liner Replaced"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={74}  />
			<Select className={"question-card"} name={"5487_0"} label={"Counter Tops, Sinks, &amp; Back Splash Areas"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={75}  />
			<Select className={"question-card"} name={"5488_0"} label={"Wall Spotting (Finger Prints, Smudges)"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={76}  />
			<TextInput className={"question-card"} name={"5489_0"} label={"COMMENTS"} excel_reference={77} fail_comment={true}  />
			<div className={"question-card"} name={"5490_0"} excel_reference={78}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"5491_0"} excel_reference={79}  />
			<Select className={"question-card"} name={"5492_0"} label={"Create Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={80}  />
			<TextInput className={"question-card"} name={"5493_0"} label={"Total Rating - Maximum Score 40"} excel_reference={81} excel={'+Q69+Q70+Q71+Q72+Q73+Q74+Q75+Q76'}  />
		</Accordion>,
		<Accordion index={5494} header={<div className={"question-card"} name={"5494_0"} excel_reference={82} anchor={true} anchor_text={"CHECKLISTS"} style={{fontSize: '24px'}}  >
				<Paragraph text={"CHECKLISTS"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"5495_0"} sub_total={"5500_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"5495_0"} label={"Are the Checklists on Site and being Utilized?"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={83}  />
			<TextInput className={"question-card"} name={"5496_0"} label={"COMMENTS"} excel_reference={84} fail_comment={true}  />
			<div className={"question-card"} name={"5497_0"} excel_reference={85}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"5498_0"} excel_reference={86}  />
			<Select className={"question-card"} name={"5499_0"} label={"Create Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={87}  />
			<TextInput className={"question-card"} name={"5500_0"} label={"Total Rating - Maximum Score 5"} excel_reference={88} excel={'+Q83'}  />
		</Accordion>,
		<Accordion index={5501} header={<div className={"question-card"} name={"5501_0"} excel_reference={89} anchor={true} anchor_text={"JANITOR CLOSET/STORAGE AREA"} style={{fontSize: '24px'}}  >
				<Paragraph text={"JANITOR CLOSET/STORAGE AREA"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"5502_0,5503_0,5504_0"} sub_total={"5509_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"5502_0"} label={"Mop Sink &amp; Floors"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={90}  />
			<Select className={"question-card"} name={"5503_0"} label={"Supplies/Equipment (Stored neat &amp; orderly)"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={91}  />
			<Select className={"question-card"} name={"5504_0"} label={"MSDS Binder up to date"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={92}  />
			<TextInput className={"question-card"} name={"5505_0"} label={"COMMENTS"} excel_reference={93} fail_comment={true}  />
			<div className={"question-card"} name={"5506_0"} excel_reference={94}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"5507_0"} excel_reference={95}  />
			<Select className={"question-card"} name={"5508_0"} label={"Create Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={96}  />
			<TextInput className={"question-card"} name={"5509_0"} label={"Total Rating - Maximum Score - 15"} excel_reference={97} excel={'+Q90+Q91+Q92'}  />
			<Select className={"question-card"} name={"8922_0"} label={"DID YOU REQUEST ANY WORK ORDERS? "} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={99} required={true}  />
		</Accordion>,
		<Accordion index={5512} header={<div className={"question-card"} name={"5512_0"} excel_reference={101} anchor={true} anchor_text={"ADDITIONAL SERVICES"} style={{fontSize: '24px'}}  >
				<Paragraph text={"ADDITIONAL SERVICES"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"5514_0,5515_0,5516_0,5517_0,5518_0,5519_0,5520_0,5521_0,5522_0,5523_0,5524_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<div className={"question-card"} name={"5513_0"} excel_reference={102}  >
				<Paragraph text={"Can we provide additional services (TAGs)?  Can we provide additional services (TAGs)?  "} style={{margin:"0px", fontSize:"inherit"}} />
				<Paragraph text={"What extra services can we suggest to make the buildings look better?  "} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<TextInput className={"question-card"} name={"5514_0"} label={"If you had extra time, what cleaning tasks or projects would you do?  "} excel_reference={103}  />
			<Select className={"question-card"} name={"5515_0"} label={"Do any areas need pressure washing?  "} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={104}  />
			<TextInput className={"question-card"} name={"5516_0"} label={"If the answer to a question is yes please comment below"} excel_reference={105}  />
			<Select className={"question-card"} name={"5517_0"} label={"Do we need to shampoo carpet before periodics are due?  "} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={106}  />
			<TextInput className={"question-card"} name={"5518_0"} label={"If the answer to a question is yes please comment below"} excel_reference={107}  />
			<Select className={"question-card"} name={"5519_0"} label={"Do we need to strip &amp; wax the floors before periodics are due?  "} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={108}  />
			<TextInput className={"question-card"} name={"5520_0"} label={"If the answer to a question is yes please comment below"} excel_reference={109}  />
			<Select className={"question-card"} name={"5521_0"} label={"Do we need to provide any exterior window cleaning?  "} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={110}  />
			<TextInput className={"question-card"} name={"5522_0"} label={"If the answer to a question is yes please comment below"} excel_reference={111}  />
			<Select className={"question-card"} name={"5523_0"} label={"Can we provide a maintenance technician?  "} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={112}  />
			<TextInput className={"question-card"} name={"5524_0"} label={"If the answer to a question is yes please comment below"} excel_reference={113}  />
		</Accordion>,
		<TextInput className={"question-card"} name={"5510_0"} label={"RATING TOTAL"} excel_reference={98} anchor={true} anchor_text={"RATING TOTAL"} excel={'+Q18+Q34+Q48+Q67+Q81+Q88+Q97'}  />,
		<TextInput className={"question-card"} name={"5511_0"} label={"Percentage"} excel_reference={100} excel={'+Q98/235*100'}  />,
    ];

    return elements;
}

export default get_form_elements;

import React, { Component } from 'react';
import {Wrapper, Container, Header, Div, Paragraph, FormWithChildren, DateTimePicker, TextInput, Select, TextArea, UserSearchInput, SignatureInput, ImageInput, Accordion} from 'library';

function get_form_elements(){
    var elements = [
		<Accordion index={8598} header={<div className={"question-card"} name={"8598_0"} excel_reference={1} style={{fontSize: '24px'}}  >
				<Paragraph text={"Quality Control Inspection"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"8599_0,8602_0,8603_0,8604_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"8599_0"} label={"Select Position Below If QC Form is Completed by a Supervisor or Manager"} options={[{"text": "Supervisor", "value": "Supervisor"}, {"text": "Area Supervisor", "value": "Area Supervisor"}, {"text": "Dir/Mgr of Operations", "value": "Dir/Mgr of Operations"}, {"text": "Vice President", "value": "Vice President"}]} radio={true} excel_reference={2}  />
			<TextInput className={"question-card"} name={"8602_0"} label={"Area"} excel_reference={5} required={true}  />
			<TextInput className={"question-card"} name={"8603_0"} label={"Completed by:"} excel_reference={6} required={true}  />
			<TextInput className={"question-card"} name={"8604_0"} label={"Supervisor"} excel_reference={7} required={true}  />
			<div className={"question-card"} name={"8605_0"} excel_reference={8}  >
				<Paragraph text={"5 \u2013 Perfect (exceeds contract requirements/no issues)   "} style={{margin:"0px", fontSize:"inherit"}} />
				<Paragraph text={"4 \u2013 Good (meets contract requirements)   "} style={{margin:"0px", fontSize:"inherit"}} />
				<Paragraph text={"3 \u2013 Fair (a couple {1 or 2} issues, Work Order required)  "} style={{margin:"0px", fontSize:"inherit"}} />
				<Paragraph text={"2 \u2013 Poor (multiple {3 to 5} issues, Work Order required)   "} style={{margin:"0px", fontSize:"inherit"}} />
				<Paragraph text={"1 \u2013 Bad (numerous {more than 5} issues, Work Order required)   "} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
		</Accordion>,
		<Accordion index={8606} header={<div className={"question-card"} name={"8606_0"} excel_reference={9} anchor={true} anchor_text={"MAIN ENTRANCE / LOBBY / ELEVAT"} style={{fontSize: '24px'}}  >
				<Paragraph text={"MAIN ENTRANCE / LOBBY / ELEVATORS"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"8607_0,8608_0,8609_0,8610_0,8611_0,8612_0,8613_0,8614_0,8615_0,8616_0,8617_0"} sub_total={"8622_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"8607_0"} label={"Door Glass / Side Glass - Main entrance"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={10}  />
			<Select className={"question-card"} name={"8608_0"} label={"Walk Off Mats / Corners & Edges"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={11}  />
			<Select className={"question-card"} name={"8609_0"} label={"Floors - Sweep, Mop, Buff & Vacuum"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={12}  />
			<Select className={"question-card"} name={"8610_0"} label={"Carpets Vacuumed  / Spot Clean Stains"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={13}  />
			<Select className={"question-card"} name={"8611_0"} label={"Main Elevator Cabs - Interior & Exterior Directional Plates, Tracks"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={14}  />
			<Select className={"question-card"} name={"8612_0"} label={"Escalators & Landings"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={15}  />
			<Select className={"question-card"} name={"8613_0"} label={"Ice Machine"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={16}  />
			<Select className={"question-card"} name={"8614_0"} label={"High Low Dusting / A/C Vents"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={17}  />
			<Select className={"question-card"} name={"8615_0"} label={"Trash Containers / Ash Urns"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={18}  />
			<Select className={"question-card"} name={"8616_0"} label={"Lobby Furniture & Fixtures"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={19}  />
			<Select className={"question-card"} name={"8617_0"} label={"Building Exterior (police area)"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={20}  />
			<TextInput className={"question-card"} name={"8618_0"} label={"COMMENTS"} excel_reference={21} fail_comment={true}  />
			<div className={"question-card"} name={"8619_0"} excel_reference={22}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"8620_0"} excel_reference={23}  />
			<Select className={"question-card"} name={"8621_0"} label={"Create Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={24}  />
			<TextInput className={"question-card"} name={"8622_0"} label={"Total Rating - Maximum Score - 55"} excel_reference={25} excel={'+Q10+Q11+Q12+Q13+Q14+Q15+Q16+Q17+Q18+Q19+Q20'}  />
		</Accordion>,
		<Accordion index={8623} header={<div className={"question-card"} name={"8623_0"} excel_reference={26} anchor={true} anchor_text={"COMMON AREAS"} style={{fontSize: '24px'}}  >
				<Paragraph text={"COMMON AREAS"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"8624_0,8625_0,8626_0,8627_0,8628_0,8629_0,8630_0,8631_0,8632_0"} sub_total={"8637_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"8624_0"} label={"Drinking Fountains         "} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={27}  />
			<Select className={"question-card"} name={"8625_0"} label={"Stairway"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={28}  />
			<Select className={"question-card"} name={"8626_0"} label={"Elevators, Cabs, Tracks, Panels"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={29}  />
			<Select className={"question-card"} name={"8627_0"} label={"Telephones"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={30}  />
			<Select className={"question-card"} name={"8628_0"} label={"Interior Glass"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={31}  />
			<Select className={"question-card"} name={"8629_0"} label={"High Low Dusting / A/C Vents"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={32}  />
			<Select className={"question-card"} name={"8630_0"} label={"Floors - Sweep, Mop, Buff & Vacuum"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={33}  />
			<Select className={"question-card"} name={"8631_0"} label={"Carpets Vacuumed  / Spot Clean Stains"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={34}  />
			<Select className={"question-card"} name={"8632_0"} label={"Wall Spotting / Corners & Edges"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={35}  />
			<TextInput className={"question-card"} name={"8633_0"} label={"COMMENTS"} excel_reference={36} fail_comment={true}  />
			<div className={"question-card"} name={"8634_0"} excel_reference={37}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"8635_0"} excel_reference={38}  />
			<Select className={"question-card"} name={"8636_0"} label={"Create Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={39}  />
			<TextInput className={"question-card"} name={"8637_0"} label={"Total Rating - Maximum Score - 45"} excel_reference={40} excel={'+Q27+Q28+Q29+Q30+Q31+Q32+Q33+Q34+Q35'}  />
		</Accordion>,
		<Accordion index={8638} header={<div className={"question-card"} name={"8638_0"} excel_reference={41} anchor={true} anchor_text={"OFFICES / CONFERENCE ROOMS"} style={{fontSize: '24px'}}  >
				<Paragraph text={"OFFICES / CONFERENCE ROOMS"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"8639_0,8640_0,8641_0,8642_0,8643_0,8644_0,8645_0,8646_0,8647_0,8648_0,8649_0"} sub_total={"8654_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"8639_0"} label={"Trash Removed & Liner Replaced"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={42}  />
			<Select className={"question-card"} name={"8640_0"} label={"Carpets Vacuumed  / Spot Clean Stains"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={43}  />
			<Select className={"question-card"} name={"8641_0"} label={"Floors - Sweep, Mop, Buff & Vacuum"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={44}  />
			<Select className={"question-card"} name={"8642_0"} label={"Vacuum / Sweep Corners, Edges, & Under Furniture"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={45}  />
			<Select className={"question-card"} name={"8643_0"} label={"Interior Glass"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={46}  />
			<Select className={"question-card"} name={"8644_0"} label={"Telephones"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={47}  />
			<Select className={"question-card"} name={"8645_0"} label={"High Low Dusting"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={48}  />
			<Select className={"question-card"} name={"8646_0"} label={"Blinds / Window Sills Dusted"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={49}  />
			<Select className={"question-card"} name={"8647_0"} label={"Furniture, File Cabinets, Counter Tops, Etc."} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={50}  />
			<Select className={"question-card"} name={"8648_0"} label={"Wall Spotting (Finger Prints, Smudges, Etc.)"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={51}  />
			<Select className={"question-card"} name={"8649_0"} label={"A/C Vents & Return Air Vents"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={52}  />
			<TextInput className={"question-card"} name={"8650_0"} label={"COMMENTS"} excel_reference={53} fail_comment={true}  />
			<div className={"question-card"} name={"8651_0"} excel_reference={54}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"8652_0"} excel_reference={55}  />
			<Select className={"question-card"} name={"8653_0"} label={"Create Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={56}  />
			<TextInput className={"question-card"} name={"8654_0"} label={"Total Rating - Maximum Score - 55"} excel_reference={57} excel={'+Q42+Q43+Q44+Q45+Q46+Q47+Q48+Q49+Q50+Q51+Q52'}  />
		</Accordion>,
		<Accordion index={8655} header={<div className={"question-card"} name={"8655_0"} excel_reference={58} anchor={true} anchor_text={"REST ROOMS / LOCKER ROOMS"} style={{fontSize: '24px'}}  >
				<Paragraph text={"REST ROOMS / LOCKER ROOMS"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"8656_0,8657_0,8658_0,8659_0,8660_0,8661_0,8662_0,8663_0,8664_0,8665_0,8666_0"} sub_total={"8671_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"8656_0"} label={"Trash Removed & Liner Replaced"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={59}  />
			<Select className={"question-card"} name={"8657_0"} label={"Clean & Polish Stainless Steel Fixtures"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={60}  />
			<Select className={"question-card"} name={"8658_0"} label={"Replenish Tissue, Towels, & Soap Dispensers"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={61}  />
			<Select className={"question-card"} name={"8659_0"} label={"Mirrors & Frames"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={62}  />
			<Select className={"question-card"} name={"8660_0"} label={"High & Low Dusting"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={63}  />
			<Select className={"question-card"} name={"8661_0"} label={"Sinks, Faucets, & Counter Tops"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={64}  />
			<Select className={"question-card"} name={"8662_0"} label={"Floors Swept, Mopped"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={65}  />
			<Select className={"question-card"} name={"8663_0"} label={"Toilets / Urinals - Bowls, Seats, Rim, Plumbing Fixtures"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={66}  />
			<Select className={"question-card"} name={"8664_0"} label={"Partition Walls, Doors, Etc."} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={67}  />
			<Select className={"question-card"} name={"8665_0"} label={"A/C Vents & Return Air Vents"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={68}  />
			<Select className={"question-card"} name={"8666_0"} label={"Do the shower curtains need to be replaced? "} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={69}  />
			<TextInput className={"question-card"} name={"8667_0"} label={"COMMENTS"} excel_reference={70} fail_comment={true}  />
			<div className={"question-card"} name={"8668_0"} excel_reference={71}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"8669_0"} excel_reference={72}  />
			<Select className={"question-card"} name={"8670_0"} label={"Create Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={73}  />
			<TextInput className={"question-card"} name={"8671_0"} label={"Total Rating - Maximum Score - 50"} excel_reference={74} excel={'+Q59+Q60+Q61+Q62+Q63+Q64+Q65+Q66+Q67+Q68'}  />
		</Accordion>,
		<Accordion index={8672} header={<div className={"question-card"} name={"8672_0"} excel_reference={75} anchor={true} anchor_text={" BREAK ROOMS / CAFETERIA"} style={{fontSize: '24px'}}  >
				<Paragraph text={" BREAK ROOMS / CAFETERIA"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"8673_0,8674_0,8675_0,8676_0,8677_0,8678_0,8679_0"} sub_total={"8684_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"8673_0"} label={"Floors Swept, Mopped, Vacuum & Buff"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={76}  />
			<Select className={"question-card"} name={"8674_0"} label={"High & Low Dusting"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={77}  />
			<Select className={"question-card"} name={"8675_0"} label={"Tables / Chairs"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={78}  />
			<Select className={"question-card"} name={"8676_0"} label={"A/C Vents & Return Air Vents"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={79}  />
			<Select className={"question-card"} name={"8677_0"} label={"Trash  removed & liners replaced"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={80}  />
			<Select className={"question-card"} name={"8678_0"} label={"Counter Tops, Sinks, & Back Splash Areas"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={81}  />
			<Select className={"question-card"} name={"8679_0"} label={"Wall Spotting (Finger Prints, Smudges)"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={82}  />
			<TextInput className={"question-card"} name={"8680_0"} label={"COMMENTS"} excel_reference={83} fail_comment={true}  />
			<ImageInput className={"question-card"} name={"8682_0"} excel_reference={85}  />
			<Select className={"question-card"} name={"8683_0"} label={"Create Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={86}  />
			<TextInput className={"question-card"} name={"8684_0"} label={"Total Rating - Maximum Score - 35"} excel_reference={87} excel={'+Q76+Q77+Q78+Q79+Q80+Q81+Q82'}  />
		</Accordion>,
		<Accordion index={8685} header={<div className={"question-card"} name={"8685_0"} excel_reference={88} anchor={true} anchor_text={"JANITOR CLOSET / STORAGE AREA"} style={{fontSize: '24px'}}  >
				<Paragraph text={"JANITOR CLOSET / STORAGE AREA"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"8686_0,8687_0"} sub_total={"8692_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"8686_0"} label={"Mop Sink & Floors"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={89}  />
			<Select className={"question-card"} name={"8687_0"} label={"Supplies/Equipment (Stored neat & orderly)"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={90}  />
			<TextInput className={"question-card"} name={"8688_0"} label={"COMMENTS"} excel_reference={91} fail_comment={true}  />
			<div className={"question-card"} name={"8689_0"} excel_reference={92}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"8690_0"} excel_reference={93}  />
			<Select className={"question-card"} name={"8691_0"} label={"Create Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={94}  />
			<TextInput className={"question-card"} name={"8692_0"} label={"Total Rating - Maximum Score - 10"} excel_reference={95} excel={'+Q89+Q90'}  />
			<Select className={"question-card"} name={"8916_0"} label={"DID YOU REQUEST ANY WORK ORDERS? "} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={97} required={true}  />
		</Accordion>,
		<Accordion index={8695} header={<div className={"question-card"} name={"8695_0"} excel_reference={99} anchor={true} anchor_text={"CAN WE PROVIDE ADDITIONAL SERV"} style={{fontSize: '24px'}}  >
				<Paragraph text={"CAN WE PROVIDE ADDITIONAL SERVICES (TAGS)?  What extra services can we suggest to make the buildings look better?"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"8696_0,8697_0,8698_0,8699_0,8700_0,13201_0,13202_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"8696_0"} label={"Do any areas need pressure washing? "} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={100}  />
			<Select className={"question-card"} name={"8697_0"} label={"Do we need to shampoo carpet before periodics are due?"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={101}  />
			<Select className={"question-card"} name={"8698_0"} label={"Do we need to strip & wax floor before periodics are due?"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={102}  />
			<Select className={"question-card"} name={"8699_0"} label={"Do we need to provide any exterior window cleaning?"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={103}  />
			<Select className={"question-card"} name={"8700_0"} label={"Can we provide a maintenance technician?"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={104}  />
			<SignatureInput className={"question-card"} name={"13201_0"} label={"Quality Control Signature"} excel_reference={105}  />
			<SignatureInput className={"question-card"} name={"13202_0"} label={"City Of Lubbock Signature"} excel_reference={106}  />
		</Accordion>,
		<TextInput className={"question-card"} name={"8693_0"} label={"Total Score"} excel_reference={96} anchor={true} anchor_text={"Total Score"} excel={'+Q25+Q40+Q57+Q74+Q87+Q95'}  />,
		<TextInput className={"question-card"} name={"8694_0"} label={"Percentage "} excel_reference={98} excel={'+(Q96/250)*100'}  />,
    ];

    return elements;
}

export default get_form_elements;

import React, { Component } from 'react';
import {Wrapper, Container, Header, Div, Paragraph, FormWithChildren, DateTimePicker, TextInput, Select, TextArea, UserSearchInput, SignatureInput, ImageInput, Accordion} from 'library';

function get_form_elements(){
    var elements = [
		<Accordion index={6869} header={<div className={"question-card"} name={"6869_0"} excel_reference={1} style={{fontSize: '24px'}}  >
				<Paragraph text={"QUALITY CONTROL INSPECTION"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"6870_0,6873_0,6874_0,6875_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"6870_0"} label={"Select Position Below if QC Form is Completed by a Supervisor or Manager "} options={[{"text": "Supervisor", "value": "Supervisor"}, {"text": "Area Supervisor", "value": "Area Supervisor"}, {"text": "Dir/Mgr of Operations", "value": "Dir/Mgr of Operations"}, {"text": "Vice President", "value": "Vice President"}]} radio={true} excel_reference={2}  />
			<TextInput className={"question-card"} name={"6873_0"} label={"Area: "} excel_reference={5} required={true}  />
			<TextInput className={"question-card"} name={"6874_0"} label={"Completed by: "} excel_reference={6} required={true}  />
			<TextInput className={"question-card"} name={"6875_0"} label={"Supervisor "} excel_reference={7} required={true}  />
			<div className={"question-card"} name={"6876_0"} excel_reference={8}  >
				<Paragraph text={"5 \u2013 Perfect (exceeds contract requirements/no issues)   "} style={{margin:"0px", fontSize:"inherit"}} />
				<Paragraph text={"4 \u2013 Good (meets contract requirements)   "} style={{margin:"0px", fontSize:"inherit"}} />
				<Paragraph text={"3 \u2013 Fair (a couple {1 or 2} issues, Work Order required)  "} style={{margin:"0px", fontSize:"inherit"}} />
				<Paragraph text={"2 \u2013 Poor (multiple {3 to 5} issues, Work Order required)   "} style={{margin:"0px", fontSize:"inherit"}} />
				<Paragraph text={"1 \u2013 Bad (numerous {more than 5} issues, Work Order required)   "} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
		</Accordion>,
		<Accordion index={6880} header={<div className={"question-card"} name={"6880_0"} excel_reference={9} anchor={true} anchor_text={"Miscellaneous  "} style={{fontSize: '24px'}}  >
				<Paragraph text={"Miscellaneous  "} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"6881_0,6882_0,6883_0,6884_0,6885_0,6920_0,6888_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"6881_0"} label={"Employees clocked in timely "} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={10}  />
			<Select className={"question-card"} name={"6882_0"} label={"Employees in uniform  "} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={11}  />
			<Select className={"question-card"} name={"6883_0"} label={"Chemical inventory adequate  "} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={12}  />
			<Select className={"question-card"} name={"6884_0"} label={"Safety standards met "} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={13}  />
			<TextInput className={"question-card"} name={"6885_0"} label={"Comments"} excel_reference={14} style={{fontSize: '14px'}}  />
			<Select className={"question-card"} name={"6920_0"} label={"Create a Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={15}  />
			<div className={"question-card"} name={"6886_0"} excel_reference={16}  >
				<Paragraph text={"Take an upload a picture to show situation "} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"6887_0"} excel_reference={17}  />
			<TextInput className={"question-card"} name={"6888_0"} label={"Total "} excel_reference={18} excel={'+Q10+Q11+Q12+Q13'}  />
		</Accordion>,
		<Accordion index={6889} header={<div className={"question-card"} name={"6889_0"} excel_reference={19} anchor={true} anchor_text={"Dog Kennels  "} style={{fontSize: '24px'}}  >
				<Paragraph text={"Dog Kennels  "} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"6890_0,6891_0,6892_0,6893_0,6919_0,6896_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"6890_0"} label={"Kennel interior \u2013 floors "} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={20}  />
			<Select className={"question-card"} name={"6891_0"} label={"Kennel interior \u2013 walls  "} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={21}  />
			<Select className={"question-card"} name={"6892_0"} label={"Food and water dishes  "} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={22}  />
			<TextInput className={"question-card"} name={"6893_0"} label={"Comments "} excel_reference={23}  />
			<div className={"question-card"} name={"6894_0"} excel_reference={24}  >
				<Paragraph text={"Take an upload a picture to show situation "} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"6919_0"} label={"Create a Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={25}  />
			<ImageInput className={"question-card"} name={"6895_0"} excel_reference={26}  />
			<TextInput className={"question-card"} name={"6896_0"} label={"Total "} excel_reference={27} excel={'+Q20+Q21+Q22'}  />
		</Accordion>,
		<Accordion index={6897} header={<div className={"question-card"} name={"6897_0"} excel_reference={28} anchor={true} anchor_text={"Cat Adoption  "} style={{fontSize: '24px'}}  >
				<Paragraph text={"Cat Adoption  "} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"6898_0,6899_0,6900_0,6901_0,6902_0,6918_0,6905_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"6898_0"} label={"Kennel interior \u2013 floors  "} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={29}  />
			<Select className={"question-card"} name={"6899_0"} label={"Kennel interior \u2013 walls  "} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={30}  />
			<Select className={"question-card"} name={"6900_0"} label={"Litter Box "} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={31}  />
			<Select className={"question-card"} name={"6901_0"} label={"Food and water dishes "} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={32}  />
			<TextInput className={"question-card"} name={"6902_0"} label={"Comments "} excel_reference={33}  />
			<Select className={"question-card"} name={"6918_0"} label={"Create a Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={34}  />
			<div className={"question-card"} name={"6903_0"} excel_reference={35}  >
				<Paragraph text={"Take an upload a picture to show situation "} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"6904_0"} excel_reference={36}  />
			<TextInput className={"question-card"} name={"6905_0"} label={"Total "} excel_reference={37} excel={'+Q29+Q30+Q31+Q32'}  />
		</Accordion>,
		<Accordion index={6906} header={<div className={"question-card"} name={"6906_0"} excel_reference={38} anchor={true} anchor_text={"Cat Holding Kennels  "} style={{fontSize: '24px'}}  >
				<Paragraph text={"Cat Holding Kennels  "} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"6907_0,6908_0,6909_0,6910_0,6911_0,6917_0,6914_0,8924_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"6907_0"} label={"Interior floors "} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={39}  />
			<Select className={"question-card"} name={"6908_0"} label={"Interior walls  "} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={40}  />
			<Select className={"question-card"} name={"6909_0"} label={"Litter Box "} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={41}  />
			<Select className={"question-card"} name={"6910_0"} label={"Food and water dishes  "} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={42}  />
			<TextInput className={"question-card"} name={"6911_0"} label={"Comments "} excel_reference={43}  />
			<Select className={"question-card"} name={"6917_0"} label={"Create a Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={44}  />
			<div className={"question-card"} name={"6912_0"} excel_reference={45}  >
				<Paragraph text={"Take an upload a picture to show situation "} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"6913_0"} excel_reference={46}  />
			<TextInput className={"question-card"} name={"6914_0"} label={"Total "} excel_reference={47} excel={'+Q39+Q40+Q41+Q42'}  />
			<Select className={"question-card"} name={"8924_0"} label={"DID YOU REQUEST ANY WORK ORDERS? "} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={49} required={true}  />
		</Accordion>,
		<TextInput className={"question-card"} name={"6915_0"} label={"Grand Total "} excel_reference={48} anchor={true} anchor_text={"Grand Total "} excel={'+Q18+Q27+Q37+Q47'}  />,
		<TextInput className={"question-card"} name={"6916_0"} label={"Percentage"} excel_reference={50} excel={'+Q48/75*100'}  />,
    ];

    return elements;
}

export default get_form_elements;

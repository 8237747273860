import React, { Component } from 'react';
import {Wrapper, Container, Header, Div, Paragraph, FormWithChildren, DateTimePicker, TextInput, Select, TextArea, UserSearchInput, SignatureInput, ImageInput, Accordion} from 'library';

function get_form_elements(){
    var elements = [
		<Accordion index={12661} header={<div className={"question-card"} name={"12661_0"} excel_reference={1} style={{fontSize: '24px'}}  >
				<Paragraph text={"QUALITY CONTROL INSPECTION"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"12662_0,12665_0,12666_0,12667_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"12662_0"} label={"Select Position Below if QC Form is Completed by a Supervisor or Manager"} options={[{"text": "Supervisor", "value": "Supervisor"}, {"text": "Area Supervisor", "value": "Area Supervisor"}, {"text": "Dir/Mgr of Operations", "value": "Dir/Mgr of Operations"}, {"text": "Vice President", "value": "Vice President"}]} radio={true} excel_reference={2}  />
			<TextInput className={"question-card"} name={"12665_0"} label={"Area:"} excel_reference={5} required={true}  />
			<TextInput className={"question-card"} name={"12666_0"} label={"Completed by:"} excel_reference={6} required={true}  />
			<TextInput className={"question-card"} name={"12667_0"} label={"Supervisor"} excel_reference={7} required={true}  />
		</Accordion>,
		<Accordion index={12668} header={<div className={"question-card"} name={"12668_0"} excel_reference={8} anchor={true} anchor_text={"MAIN ENTRANCE / LOBBY / ELEVAT"} style={{fontSize: '24px'}}  >
				<Paragraph text={"MAIN ENTRANCE / LOBBY / ELEVATORS"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"12669_0,12670_0,12671_0,12672_0,12673_0,12674_0,12675_0,12676_0,12677_0,12678_0,12679_0"} sub_total={"12683_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"12669_0"} label={"Door Glass / Side Glass - Main entrance"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={9}  />
			<Select className={"question-card"} name={"12670_0"} label={"Walk Off Mats / Corners & Edges"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={10}  />
			<Select className={"question-card"} name={"12671_0"} label={"Carpets Vacuumed  / Spot Clean Stains"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={11}  />
			<Select className={"question-card"} name={"12672_0"} label={"Floors - Sweep, Mop, Buff & Vacuum"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={12}  />
			<Select className={"question-card"} name={"12673_0"} label={"Main Elevator Cabs - Interior & Exterior Directional Plates, Tracks"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={13}  />
			<Select className={"question-card"} name={"12674_0"} label={"Escalators & Landings"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={14}  />
			<Select className={"question-card"} name={"12675_0"} label={"Ice Machine"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={15}  />
			<Select className={"question-card"} name={"12676_0"} label={"High Low Dusting / A/C Vents"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={16}  />
			<Select className={"question-card"} name={"12677_0"} label={"Trash Containers / Ash Urns"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={17}  />
			<Select className={"question-card"} name={"12678_0"} label={"Lobby Furniture & Fixtures"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={18}  />
			<Select className={"question-card"} name={"12679_0"} label={"Building Exterior (police area)"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={19}  />
			<TextInput className={"question-card"} name={"12680_0"} label={"COMMENTS "} excel_reference={20} fail_comment={true}  />
			<div className={"question-card"} name={"12681_0"} excel_reference={21}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"12682_0"} excel_reference={22}  />
			<TextInput className={"question-card"} name={"12683_0"} label={"Total Rating"} excel_reference={23} excel={'+Q9+Q10+Q11+Q12+Q13+Q14+Q15+Q16+Q17+Q18+Q19'}  />
		</Accordion>,
		<Accordion index={12684} header={<div className={"question-card"} name={"12684_0"} excel_reference={24} anchor={true} anchor_text={"COMMON AREAS"} style={{fontSize: '24px'}}  >
				<Paragraph text={"COMMON AREAS"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"12685_0,12686_0,12687_0,12688_0,12689_0,12690_0,12691_0,12692_0,12693_0"} sub_total={"12697_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"12685_0"} label={"Drinking Fountains         "} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={25}  />
			<Select className={"question-card"} name={"12686_0"} label={"Stairway"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={26}  />
			<Select className={"question-card"} name={"12687_0"} label={"Elevators, Cabs, Tracks, Panels"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={27}  />
			<Select className={"question-card"} name={"12688_0"} label={"Telephones"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={28}  />
			<Select className={"question-card"} name={"12689_0"} label={"Interior Glass"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={29}  />
			<Select className={"question-card"} name={"12690_0"} label={"High Low Dusting / A/C Vents"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={30}  />
			<Select className={"question-card"} name={"12691_0"} label={"Floors - Sweep, Mop, Buff & Vacuum"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={31}  />
			<Select className={"question-card"} name={"12692_0"} label={"Carpets Vacuumed  / Spot Clean Stains"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={32}  />
			<Select className={"question-card"} name={"12693_0"} label={"Wall Spotting / Corners & Edges"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={33}  />
			<TextInput className={"question-card"} name={"12694_0"} label={"COMMENTS"} excel_reference={34} fail_comment={true}  />
			<div className={"question-card"} name={"12695_0"} excel_reference={35}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"12696_0"} excel_reference={36}  />
			<TextInput className={"question-card"} name={"12697_0"} label={"Total Rating"} excel_reference={37} excel={'Q25+Q26+Q27+Q28+Q29+Q30+Q31+Q32+Q33'}  />
		</Accordion>,
		<Accordion index={12698} header={<div className={"question-card"} name={"12698_0"} excel_reference={38} anchor={true} anchor_text={"OFFICES / CONFERENCE ROOMS"} style={{fontSize: '24px'}}  >
				<Paragraph text={"OFFICES / CONFERENCE ROOMS"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"12699_0,12700_0,12701_0,12702_0,12703_0,12704_0,12705_0,12706_0,12707_0,12708_0,12709_0"} sub_total={"12713_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"12699_0"} label={"Trash Removed & Liner Replaced"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={39}  />
			<Select className={"question-card"} name={"12700_0"} label={"Carpets Vacuumed  / Spot Clean Stains"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={40}  />
			<Select className={"question-card"} name={"12701_0"} label={"Floors - Sweep, Mop, Buff & Vacuum"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={41}  />
			<Select className={"question-card"} name={"12702_0"} label={"Vacuum / Sweep Corners, Edges, & Under Furniture"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={42}  />
			<Select className={"question-card"} name={"12703_0"} label={"Interior Glass"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={43}  />
			<Select className={"question-card"} name={"12704_0"} label={"Telephones"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={44}  />
			<Select className={"question-card"} name={"12705_0"} label={"High Low Dusting"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={45}  />
			<Select className={"question-card"} name={"12706_0"} label={"Blinds / Window Sills Dusted"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={46}  />
			<Select className={"question-card"} name={"12707_0"} label={"Furniture, File Cabinets, Counter Tops, Etc."} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={47}  />
			<Select className={"question-card"} name={"12708_0"} label={"Wall Spotting (Finger Prints, Smudges, Etc.)"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={48}  />
			<Select className={"question-card"} name={"12709_0"} label={"A/C Vents & Return Air Vents"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={49}  />
			<TextInput className={"question-card"} name={"12710_0"} label={"COMMENTS"} excel_reference={50} fail_comment={true}  />
			<div className={"question-card"} name={"12711_0"} excel_reference={51}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"12712_0"} excel_reference={52}  />
			<TextInput className={"question-card"} name={"12713_0"} label={"Total Rating"} excel_reference={53} excel={'Q39+Q40+Q41+Q42+Q43+Q44+Q45+Q46+Q47+Q48+Q49'}  />
		</Accordion>,
		<Accordion index={12714} header={<div className={"question-card"} name={"12714_0"} excel_reference={54} anchor={true} anchor_text={"REST ROOMS / LOCKER ROOMS"} style={{fontSize: '24px'}}  >
				<Paragraph text={"REST ROOMS / LOCKER ROOMS"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"12715_0,12716_0,12717_0,12718_0,12719_0,12720_0,12721_0,12722_0,12723_0,12724_0"} sub_total={"12728_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"12715_0"} label={"Trash Removed & Liner Replaced"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={55}  />
			<Select className={"question-card"} name={"12716_0"} label={"Clean & Polish Stainless Steel Fixtures"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={56}  />
			<Select className={"question-card"} name={"12717_0"} label={"Replenish Tissue, Towels, & Soap Dispensers"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={57}  />
			<Select className={"question-card"} name={"12718_0"} label={"Mirrors & Frames"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={58}  />
			<Select className={"question-card"} name={"12719_0"} label={"High & Low Dusting"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={59}  />
			<Select className={"question-card"} name={"12720_0"} label={"Sinks, Faucets, & Counter Tops"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={60}  />
			<Select className={"question-card"} name={"12721_0"} label={"Floors Swept, Mopped"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={61}  />
			<Select className={"question-card"} name={"12722_0"} label={"Toilets / Urinals - Bowls, Seats, Rim, Plumbing Fixtures"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={62}  />
			<Select className={"question-card"} name={"12723_0"} label={"Partition Walls, Doors, Etc."} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={63}  />
			<Select className={"question-card"} name={"12724_0"} label={"A/C Vents & Return Air Vents"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={64}  />
			<TextInput className={"question-card"} name={"12725_0"} label={"COMMENTS"} excel_reference={65} fail_comment={true}  />
			<div className={"question-card"} name={"12726_0"} excel_reference={66}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"12727_0"} excel_reference={67}  />
			<TextInput className={"question-card"} name={"12728_0"} label={"Total Rating "} excel_reference={68} excel={'Q55+Q56+Q57+Q58+Q59+Q60+Q61+Q62+Q63+Q64'}  />
		</Accordion>,
		<Accordion index={12729} header={<div className={"question-card"} name={"12729_0"} excel_reference={69} anchor={true} anchor_text={" BREAK ROOMS / CAFETERIA"} style={{fontSize: '24px'}}  >
				<Paragraph text={" BREAK ROOMS / CAFETERIA"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"12730_0,12731_0,12732_0,12733_0,12734_0,12735_0,12736_0"} sub_total={"12740_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"12730_0"} label={"Floors Swept, Mopped, Vacuum & Buff"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={70}  />
			<Select className={"question-card"} name={"12731_0"} label={"High & Low Dusting"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={71}  />
			<Select className={"question-card"} name={"12732_0"} label={"Tables / Chairs"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={72}  />
			<Select className={"question-card"} name={"12733_0"} label={"A/C Vents & Return Air Vents"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={73}  />
			<Select className={"question-card"} name={"12734_0"} label={"Trash  removed & liners replaced"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={74}  />
			<Select className={"question-card"} name={"12735_0"} label={"Counter Tops, Sinks, & Back Splash Areas"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={75}  />
			<Select className={"question-card"} name={"12736_0"} label={"Wall Spotting (Finger Prints, Smudges)"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={76}  />
			<TextInput className={"question-card"} name={"12737_0"} label={"COMMENTS"} excel_reference={77} fail_comment={true}  />
			<div className={"question-card"} name={"12738_0"} excel_reference={78}  >
				<Paragraph text={"Take a Photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"12739_0"} excel_reference={79}  />
			<TextInput className={"question-card"} name={"12740_0"} label={"Total Rating"} excel_reference={80} excel={'Q70+Q71+Q72+Q73+Q74+Q75+Q76'}  />
		</Accordion>,
		<Accordion index={12741} header={<div className={"question-card"} name={"12741_0"} excel_reference={81} anchor={true} anchor_text={"JANITOR CLOSET / STORAGE AREA"} style={{fontSize: '24px'}}  >
				<Paragraph text={"JANITOR CLOSET / STORAGE AREA"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"12742_0,12743_0,12744_0,12745_0,12746_0,12747_0"} sub_total={"12751_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"12742_0"} label={"Mop Sink & Floors"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={82}  />
			<Select className={"question-card"} name={"12743_0"} label={"Supplies/Equipment (Stored neat & orderly)"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={83}  />
			<Select className={"question-card"} name={"12744_0"} label={"SDS binders in closet and chemicals labeled"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={84}  />
			<Select className={"question-card"} name={"12745_0"} label={"All chemicals in the closet are approved"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={85}  />
			<Select className={"question-card"} name={"12746_0"} label={"Have all checklists been completed? "} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={86}  />
			<Select className={"question-card"} name={"12747_0"} label={"Have all employees completed the sign in/out sheet for their shift?  "} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={87}  />
			<TextInput className={"question-card"} name={"12748_0"} label={"COMMENTS"} excel_reference={88} fail_comment={true}  />
			<div className={"question-card"} name={"12749_0"} excel_reference={89}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"12750_0"} excel_reference={90}  />
			<TextInput className={"question-card"} name={"12751_0"} label={"Rating Total"} excel_reference={91} excel={'Q82+Q83+Q84+Q85+Q86+Q87'}  />
		</Accordion>,
		<Accordion index={12754} header={<div className={"question-card"} name={"12754_0"} excel_reference={94} anchor={true} anchor_text={"CAN WE PROVIDE ADDITIONAL SERV"} style={{fontSize: '24px'}}  >
				<Paragraph text={"CAN WE PROVIDE ADDITIONAL SERVICES (TAGS)?  What extra services can we suggest to make the buildings look better?"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"12755_0,12756_0,12757_0,12758_0,12759_0,12760_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"12755_0"} label={"Do any areas need pressure washing? "} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={95}  />
			<Select className={"question-card"} name={"12756_0"} label={"Do we need to shampoo carpet before periodics are due?"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={96}  />
			<Select className={"question-card"} name={"12757_0"} label={"Do we need to strip & wax floor before periodics are due?"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={97}  />
			<Select className={"question-card"} name={"12758_0"} label={"Do we need to provide any exterior window cleaning?"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={98}  />
			<Select className={"question-card"} name={"12759_0"} label={"Can we provide a maintenance technician?"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={99}  />
			<TextInput className={"question-card"} name={"12760_0"} label={"If the answer to a question above is yes please comment below"} excel_reference={100}  />
		</Accordion>,
		<TextInput className={"question-card"} name={"12752_0"} label={"Grand Total"} excel_reference={92} anchor={true} anchor_text={"Grand Total"} excel={'Q23+Q37+Q53+Q68+Q80+Q91'}  />,
		<TextInput className={"question-card"} name={"12753_0"} label={"Percentage"} excel_reference={93} excel={'(Q92/54)*100'}  />,
    ];

    return elements;
}

export default get_form_elements;

import React, { Component } from 'react';
import {Wrapper, Container, Header, Div, Paragraph, FormWithChildren, DateTimePicker, TextInput, Select, TextArea, UserSearchInput, SignatureInput, ImageInput, Accordion} from 'library';

function get_form_elements(){
    var elements = [
		<Accordion index={7010} header={<div className={"question-card"} name={"7010_0"} excel_reference={1} style={{fontSize: '24px'}}  >
				<Paragraph text={"Plugging In Scorecard"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"7011_0,7012_0,7013_0,7014_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<TextInput className={"question-card"} name={"7011_0"} label={"Name"} excel_reference={2}  />
			<TextInput className={"question-card"} name={"7012_0"} label={"Region"} excel_reference={3}  />
			<DateTimePicker className={"question-card"} name={"7013_0"} label={"Date"} excel_reference={4}  />
			<UserSearchInput className={"question-card"} name={"7014_0"} label={"Click on Supervisor E-mail address here"} excel_reference={5}  />
		</Accordion>,
		<Accordion index={7015} header={<div className={"question-card"} name={"7015_0"} excel_reference={6} style={{fontSize: '24px'}}  >
				<Paragraph text={"Plugging In"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"7017_0,7018_0,7019_0,7021_0,7022_0,7023_0,7025_0,7026_0,7077_0,7028_0,7029_0,7030_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<div className={"question-card"} name={"7016_0"} excel_reference={7}  >
				<Paragraph text={"Budget Compliance"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"7017_0"} label={"Employee - Maintains compliance - and holds direct reports in assigned region(s) accountable to maintaining compliance - with all budgets (labor, supplies, equipment, etc.), approves POs, overtime, and requests for repairs, purchases, and vehicle maintenance.  Ops Commitment Budgets meet Required Budgets or are actively being reduced (with documentation on WLM). "} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={8}  />
			<Select className={"question-card"} name={"7018_0"} label={"Supervisor - Maintains compliance - and holds direct reports in assigned region(s) accountable to maintaining compliance - with all budgets (labor, supplies, equipment, etc.), approves POs, overtime, and requests for repairs, purchases, and vehicle maintenance.  Ops Commitment Budgets meet Required Budgets or are actively being reduced (with documentation on WLM). "} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={9}  />
			<TextInput className={"question-card"} name={"7019_0"} label={"Comments"} excel_reference={10}  />
			<div className={"question-card"} name={"7020_0"} excel_reference={11}  >
				<Paragraph text={"Contract Compliance"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"7021_0"} label={"Employee - Meets all contractual requirements, creates &amp; assigns work orders, green program implementation/execution when applicable"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={12}  />
			<Select className={"question-card"} name={"7022_0"} label={"Supervisor - Meets all contractual requirements, creates &amp; assigns work orders, green program implementation/execution when applicable"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={13}  />
			<TextInput className={"question-card"} name={"7023_0"} label={"Comments"} excel_reference={14}  />
			<div className={"question-card"} name={"7024_0"} excel_reference={15}  >
				<Paragraph text={"Growth"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"7025_0"} label={"Employee - Promotes, prices, and approves TAG work, meets growth goals in assigned region(s), participates in the Lead Generation Process, provides monthly marketing leads, holds Area Supervisors and Supervisors accountable to providing quality marketing leads on a monthly basis"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={16}  />
			<Select className={"question-card"} name={"7026_0"} label={"Supervisor - Promotes, prices, and approves TAG work, meets growth goals in assigned region(s), participates in the Lead Generation Process, provides monthly marketing leads, holds Area Supervisors and Supervisors accountable to providing quality marketing leads on a monthly basis"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={17}  />
			<TextInput className={"question-card"} name={"7077_0"} label={"Comments"} excel_reference={18}  />
			<div className={"question-card"} name={"7027_0"} excel_reference={19}  >
				<Paragraph text={"Organizational Management"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"7028_0"} label={"Employee - Ensures staffing levels in assigned region(s) meet Organizational Standards with clear reporting lines and defined areas of responsibility by position. Holds direct reports accountable to conducting all Supervisor and Lead certification trainings and holding employees accountable to performance standards detailed in their job description"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={20}  />
			<Select className={"question-card"} name={"7029_0"} label={"Supervisor - Ensures staffing levels in assigned region(s) meet Organizational Standards with clear reporting lines and defined areas of responsibility by position. Holds direct reports accountable to conducting all Supervisor and Lead certification trainings and holding employees accountable to performance standards detailed in their job description"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={21}  />
			<TextInput className={"question-card"} name={"7030_0"} label={"Comments"} excel_reference={22}  />
		</Accordion>,
		<Accordion index={7031} header={<div className={"question-card"} name={"7031_0"} excel_reference={23} style={{fontSize: '24px'}}  >
				<Paragraph text={"Own Customer Management"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"7033_0,7034_0,7035_0,7037_0,7038_0,7039_0,7041_0,7042_0,7043_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<div className={"question-card"} name={"7032_0"} excel_reference={24}  >
				<Paragraph text={"Customer Relationships"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"7033_0"} label={"Employee - Establishes meaningful relationships with all levels of the region's customer organizations, to include regular and recurring contact with customers, and then plugging in those customers' feedback into actions within our systems, processes and tools."} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={25}  />
			<Select className={"question-card"} name={"7034_0"} label={"Supervisor - Establishes meaningful relationships with all levels of the region's customer organizations, to include regular and recurring contact with customers, and then plugging in those customers' feedback into actions within our systems, processes and tools."} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={26}  />
			<TextInput className={"question-card"} name={"7035_0"} label={"Comments"} excel_reference={27}  />
			<div className={"question-card"} name={"7036_0"} excel_reference={28}  >
				<Paragraph text={"Customer Satisfaction"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"7037_0"} label={"Employee - Receives feedback from customers via the online survey each month and collaborates with direct reports to reach goal of 100% customer participation in assigned region(s)"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={29}  />
			<Select className={"question-card"} name={"7038_0"} label={"Supervisor - Receives feedback from customers via the online survey each month and collaborates with direct reports to reach goal of 100% customer participation in assigned region(s)"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={30}  />
			<TextInput className={"question-card"} name={"7039_0"} label={"Comments"} excel_reference={31}  />
			<div className={"question-card"} name={"7040_0"} excel_reference={32}  >
				<Paragraph text={"Account Changes"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"7041_0"} label={"Employee - Coordinates and supports phase in/out of accounts throughout the Enterprise, submits SOA changes timely, and holds direct reports accountable to providing timely notice of account changes in the assigned region(s)"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={33}  />
			<Select className={"question-card"} name={"7042_0"} label={"Supervisor - Coordinates and supports phase in/out of accounts throughout the Enterprise, submits SOA changes timely, and holds direct reports accountable to providing timely notice of account changes in the assigned region(s)"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={34}  />
		</Accordion>,
		<Accordion index={7044} header={<div className={"question-card"} name={"7044_0"} excel_reference={36} style={{fontSize: '24px'}}  >
				<Paragraph text={"Collaboration"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"7046_0,7047_0,7048_0,7050_0,7051_0,7052_0,7054_0,7055_0,7056_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<div className={"question-card"} name={"7045_0"} excel_reference={37} style={{fontSize: '14px'}}  >
				<Paragraph text={"Weekly LEADERSHIP Meetings (WLMs)"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"7046_0"} label={"Employee - Attends weekly as an active participant, trains and collaborates with direct reports to promote meaningful engagement in WLM (active participation &amp; collaboration rather than checking the boxes)"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={38}  />
			<Select className={"question-card"} name={"7047_0"} label={"Attends weekly as an active participant, trains and collaborates with direct reports to promote meaningful engagement in WLM (active participation &amp; collaboration rather than checking the boxes)"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={39}  />
			<TextInput className={"question-card"} name={"7048_0"} label={"Comments"} excel_reference={40}  />
			<div className={"question-card"} name={"7049_0"} excel_reference={41}  >
				<Paragraph text={"Supervision & Oversight of Supervisors"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"7050_0"} label={"Employee - Completes monthly Scorecard with each Supervisor, provides &amp; receives mid-year and annual feedback, supports operations in ownership of staffing and hiring, approves time off requests for direct reports, approves standard ECs and terminations with HR assistance, escalates non-standard ECs and terminations to DO as appropriate, reviews payroll validation and overtime timesheets, and closing punches. QCs completed in each assigned region per the standard with actions created to correct deficiencies and prevent recurrence. Direct reports meet or exceed the manual punch edit goal in each region. Makes contact with direct reports daily (in person or by phone) to receive updates on budget compliance, customer satisfaction, operational commitments, and review any outstanding actions or RTFs. Conducts regular review and update of questions reviewed in Daily Calls throughout assigned region(s) - and holds direct reports accountable to the same review/update - to facilitate site-specific questions with actionable commitments that contribute to customer satisfaction and streamlined operations. "} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={42}  />
			<Select className={"question-card"} name={"7051_0"} label={"Supervisor - Completes monthly Scorecard with each Supervisor, provides &amp; receives mid-year and annual feedback, supports operations in ownership of staffing and hiring, approves time off requests for direct reports, approves standard ECs and terminations with HR assistance, escalates non-standard ECs and terminations to DO as appropriate, reviews payroll validation and overtime timesheets, and closing punches. QCs completed in each assigned region per the standard with actions created to correct deficiencies and prevent recurrence. Direct reports meet or exceed the manual punch edit goal in each region. Makes contact with direct reports daily (in person or by phone) to receive updates on budget compliance, customer satisfaction, operational commitments, and review any outstanding actions or RTFs. Conducts regular review and update of questions reviewed in Daily Calls throughout assigned region(s) - and holds direct reports accountable to the same review/update - to facilitate site-specific questions with actionable commitments that contribute to customer satisfaction and streamlined operations. "} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={43}  />
			<TextInput className={"question-card"} name={"7052_0"} label={"Comments"} excel_reference={44}  />
			<div className={"question-card"} name={"7053_0"} excel_reference={45}  >
				<Paragraph text={"Raise the Flag "} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"7054_0"} label={"Employee - Creates RTFs, follows up on RTFs and final RCA/ICA. Assigned regions meet RTF minimum/maximum quantity goals, with RTFs filed and closed timely. Supports direct reports in creation of high quality IFs, RCAs, ICAs to ensure timely issue resolution and prevent recurrence."} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={46}  />
			<Select className={"question-card"} name={"7055_0"} label={"Supervisor - Creates RTFs, follows up on RTFs and final RCA/ICA. Assigned regions meet RTF minimum/maximum quantity goals, with RTFs filed and closed timely. Supports direct reports in creation of high quality IFs, RCAs, ICAs to ensure timely issue resolution and prevent recurrence."} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={47}  />
			<TextInput className={"question-card"} name={"7056_0"} label={"Comments"} excel_reference={48}  />
		</Accordion>,
		<Accordion index={7057} header={<div className={"question-card"} name={"7057_0"} excel_reference={49} style={{fontSize: '24px'}}  >
				<Paragraph text={"LEADERSHIP"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"7059_0,7060_0,7061_0,7063_0,7064_0,7065_0,7067_0,7068_0,7069_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<div className={"question-card"} name={"7058_0"} excel_reference={50}  >
				<Paragraph text={"LEADERSHIP Development"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"7059_0"} label={"Employee - Leads the team to embracing & applying the LEADERSHIP Development Plan to each and every task, coaches and develops Supervisors. Use the comments space below to list the LEADERSHIP Development Trainings you conducted with your direct reports since we met last. "} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={51}  />
			<Select className={"question-card"} name={"7060_0"} label={"Supervisor - Leads the team to embracing &amp; applying the LEADERSHIP Development Plan to each and every task, coaches and develops Supervisors. Use the comments space below to list the LEADERSHIP Development Trainings you conducted with your direct reports since we met last. "} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={52}  />
			<TextInput className={"question-card"} name={"7061_0"} label={"Comments"} excel_reference={53}  />
			<div className={"question-card"} name={"7062_0"} excel_reference={54}  >
				<Paragraph text={"LEADERSHIP Assessments"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"7063_0"} label={"Employee - Conducts LEADERSHIP assessments and determines when we have the right people (yes - plug them in, no - develop or replace)"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={55}  />
			<Select className={"question-card"} name={"7064_0"} label={"Supervisor - Conducts LEADERSHIP assessments and determines when we have the right people (yes - plug them in, no - develop or replace)"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={56}  />
			<TextInput className={"question-card"} name={"7065_0"} label={"Comments"} excel_reference={57}  />
			<div className={"question-card"} name={"7066_0"} excel_reference={58}  >
				<Paragraph text={"LEADERSHIP"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"7067_0"} label={"Employee - Generates lasting positive change at a quick, yet sustainable pace. Use the comments space below to list the LEADERSHIP demonstrated since your last scorecard. Must truly be examples of lasting positive change."} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={59}  />
			<Select className={"question-card"} name={"7068_0"} label={"Supervisor - Generates lasting positive change at a quick, yet sustainable pace. Use the comments space below to list the LEADERSHIP demonstrated since your last scorecard. Must truly be examples of lasting positive change."} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={60}  />
			<TextInput className={"question-card"} name={"7069_0"} label={"Comments"} excel_reference={61}  />
		</Accordion>,
		<Accordion index={7070} header={<div className={"question-card"} name={"7070_0"} excel_reference={62} style={{fontSize: '24px'}}  >
				<Paragraph text={"Strategic Plan Execution"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"7072_0,7073_0,7074_0,7075_0,7076_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<div className={"question-card"} name={"7071_0"} excel_reference={63}  >
				<Paragraph text={"Meet Established Goals (by assigned region(s)/customer(s)/direct report(s)"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"7072_0"} label={"Employee - Assigned region(s) and direct reports meet all goals established in the annual Strategic Plan including growth, operating margin, plugging in, and customer satisfaction"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={64}  />
			<Select className={"question-card"} name={"7073_0"} label={"Supervisor - Assigned region(s) and direct reports meet all goals established in the annual Strategic Plan including growth, operating margin, plugging in, and customer satisfaction"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={65}  />
			<TextInput className={"question-card"} name={"7074_0"} label={"Comments"} excel_reference={66}  />
			<TextInput className={"question-card"} name={"7075_0"} label={"Average Employee Score"} excel_reference={67} excel={'(+Q8+Q12+Q16+Q20+Q25+Q29+Q33+Q38+Q42+Q46+Q51+Q55+Q59+Q64)/14'}  />
			<TextInput className={"question-card"} name={"7076_0"} label={"Average Supervisor Score"} excel_reference={68} excel={'(+Q9+Q13+Q17+Q21+Q26+Q30+Q34+Q39+Q43+Q47+Q52+Q56+Q60+Q65)/14'}  />
		</Accordion>,
    ];

    return elements;
}

export default get_form_elements;

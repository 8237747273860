import React, { Component } from 'react';
import { ajaxWrapper } from 'functions';


class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            has_error: false
        };
    }

    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({
            has_error: true
        });

        var global_state = window.cmState.getGlobalState();
        // You can also log the error to an error reporting service
        var data = {
            subject: error.message,
            callstack: error.stack,
            file_traceback: info.componentStack,
            global_context: JSON.stringify(global_state),
        };

        ajaxWrapper('POST', '/report_react_error/', data);
    }

    render() {
        if (this.state.has_error) {
            // You can render any custom fallback UI
            return <div>
                <div style={{textAlign:'center', boxShadow:'2px 2px 10px rgba(0,0,0,.1)', padding:'10px'}} >
                    <img style={{verticalAlign: 'top', height: '50px'}} src='/static/images/logo.png' />
                </div>

                <br/>

                <div style={{textAlign:'center'}} >
                    <h1>Something went wrong.  Our team has been notified.</h1>
                </div>
            </div>;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;

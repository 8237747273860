import React, { Component } from 'react';
import {Wrapper, Container, Header, Div, Paragraph, FormWithChildren, DateTimePicker, TextInput, Select, TextArea, UserSearchInput, SignatureInput, ImageInput, Accordion} from 'library';

function get_form_elements(){
    var elements = [
		<Accordion index={3743} header={<div className={"question-card"} name={"3743_0"} excel_reference={1} style={{fontSize: '24px'}}  >
				<Paragraph text={"Espanol Plugging-In Scorecard - Kleen-Tech"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"3644_0,3645_0,3646_0,3647_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<TextInput className={"question-card"} name={"3644_0"} label={"NOMBRE"} excel_reference={2}  />
			<TextInput className={"question-card"} name={"3645_0"} label={"REGION"} excel_reference={3}  />
			<DateTimePicker className={"question-card"} name={"3646_0"} label={"MES"} excel_reference={4}  />
			<UserSearchInput className={"question-card"} name={"3647_0"} label={"Type and the CLICK on your SUPERVISOR's e-mail address here"} excel_reference={5} required={true}  />
		</Accordion>,
		<Accordion index={3648} header={<div className={"question-card"} name={"3648_0"} excel_reference={6} style={{fontSize: '24px'}}  >
				<Paragraph text={"5 RESPONSABILIDADES MAS IMPORTANTES "} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"3649_0,3650_0,3651_0,3652_0,3653_0,3654_0,3655_0,3656_0,3657_0,3658_0,3659_0,3660_0,3661_0,3662_0,3663_0,3664_0,3665_0,3666_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"3649_0"} label={"EE - Maneje de Trabajo "} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={7}  />
			<Select className={"question-card"} name={"3650_0"} label={"Mgr - Maneje de Trabajo "} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={8}  />
			<TextInput className={"question-card"} name={"3651_0"} label={"Commentarios - Maneje de Trabajo "} excel_reference={9}  />
			<Select className={"question-card"} name={"3652_0"} label={"EE - Consistentes QCs/Listas de Trabajo"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={10}  />
			<Select className={"question-card"} name={"3653_0"} label={"Mgr - Consistentes QCs/Listas de Trabajo "} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={11}  />
			<TextInput className={"question-card"} name={"3654_0"} label={"Commentarios - Consistentes QCs/Listas de Trabajo "} excel_reference={12}  />
			<Select className={"question-card"} name={"3655_0"} label={"EE - Maneje de Qu\u00edmicos/Equipo de Trabajo"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={13}  />
			<Select className={"question-card"} name={"3656_0"} label={"Mgr - Maneje de Qu\u00edmicos/Equipo de Trabajo "} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={14}  />
			<TextInput className={"question-card"} name={"3657_0"} label={"Comentarios - Maneje de Qu\u00edmicos/Equipo de Trabajo "} excel_reference={15}  />
			<Select className={"question-card"} name={"3658_0"} label={"EE - Cumplir con Contacto a los Clientes "} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={16}  />
			<Select className={"question-card"} name={"3659_0"} label={"Mgr - Cumplir con Contacto a los Clientes "} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={17}  />
			<TextInput className={"question-card"} name={"3660_0"} label={"Commentarios - Cumplir con Contacto a los Clientes "} excel_reference={18}  />
			<Select className={"question-card"} name={"3661_0"} label={"EE - Reportando, Colaborando, respondiendo a los Departamentos HB "} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={19}  />
			<Select className={"question-card"} name={"3662_0"} label={"Mgr - Reportando, Colaborando, respondiendo a los Departamentos HB "} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={20}  />
			<TextInput className={"question-card"} name={"3663_0"} label={"Comentarios - Reportando, Colaborando, respondiendo a los Departamentos HB "} excel_reference={21}  />
			<TextInput className={"question-card"} name={"3664_0"} label={"PUNTUACION TOTAL - EE"} excel_reference={22} excel={'+Q7+Q10+Q13+Q16+Q19'}  />
			<TextInput className={"question-card"} name={"3665_0"} label={"PUNTUACION TOTAL - MGR"} excel_reference={23} excel={'+Q8+Q11+Q14+Q17+Q20'}  />
			<TextInput className={"question-card"} name={"3666_0"} label={"Diferencia"} excel_reference={24} excel={'+Q22-Q23'}  />
		</Accordion>,
		<Accordion index={3667} header={<div className={"question-card"} name={"3667_0"} excel_reference={25} style={{fontSize: '24px'}}  >
				<Paragraph text={"SI LA PUNTUACION ES MENOS QUE 40, NO CONTINUE ABAJO"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"3669_0,3670_0,3671_0,3672_0,3673_0,3674_0,3675_0,3676_0,3677_0,3678_0,3679_0,3681_0,3682_0,3739_0,3740_0,3683_0,3741_0,3691_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<div className={"question-card"} name={"3738_0"} excel_reference={26} style={{fontSize: '14px'}}  >
				<Paragraph text={"PLUGGING IN"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<div className={"question-card"} name={"3668_0"} excel_reference={27} style={{fontSize: '14px'}}  >
				<Paragraph text={"SI LA RESPONSABILIDAD NO APPLICA PUNTUACION ES= 10"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<div className={"question-card"} name={"3765_0"} excel_reference={28} style={{fontSize: '14px'}}  >
				<Paragraph text={"Manejo de Ordenes de Trabajo [Cero WOs Tarde en la WLM Agenda]"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"3669_0"} label={"EE- Manejo de Ordenes de Trabajo [Cero WOs Tarde en la WLM Agenda]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={29}  />
			<Select className={"question-card"} name={"3670_0"} label={"Mgr - Manejo de Ordenes de Trabajo [Cero WOs Tarde en la WLM Agenda]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={30}  />
			<TextInput className={"question-card"} name={"3671_0"} label={"Comentarios - Manejo de Ordenes de Trabajo [Cero WOs Tarde en la WLM Agenda]"} excel_reference={31}  />
			<div className={"question-card"} name={"3746_0"} excel_reference={32} style={{fontSize: '14px'}}  >
				<Paragraph text={"Consistente y Productiva Llamada Diaria con MC [Robusta y 100% Completa las Llamadas Diarias con MC]"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"3672_0"} label={"EE - Consistente y Productiva Llamada Diaria con MC [Robusta y 100% Completa las Llamadas Diarias con MC]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={33}  />
			<Select className={"question-card"} name={"3673_0"} label={"Mgr - Consistente y Productiva Llamada Diaria con MC [Robusta y 100% Completa las Llamadas Diarias con MC]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={34}  />
			<TextInput className={"question-card"} name={"3674_0"} label={"Comentarios - Consistente y Productiva Llamada Diaria con MC [Robusta y 100% Completa las Llamadas Diarias con MC]"} excel_reference={35}  />
			<div className={"question-card"} name={"3747_0"} excel_reference={36} style={{fontSize: '14px'}}  >
				<Paragraph text={"Cumpliendo RTF Normas (Reportando/Trabajando)[Reportando Nuevas &amp; Trabajando a Tiempo/Cerrar RTFs]"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"3675_0"} label={"EE - Cumpliendo RTF Normas (Reportando/Trabajando)[Reportando Nuevas &amp; Trabajando a Tiempo/Cerrar RTFs]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={37}  />
			<Select className={"question-card"} name={"3676_0"} label={"Mgr - Cumpliendo RTF Normas (Reportando/Trabajando)[Reportando Nuevas &amp; Trabajando a Tiempo/Cerrar RTFs]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={38}  />
			<TextInput className={"question-card"} name={"3677_0"} label={"Comentarios - Cumpliendo RTF Normas (Reportando/Trabajando)[Reportando Nuevas &amp; Trabajando a Tiempo/Cerrar RTFs]"} excel_reference={39}  />
			<div className={"question-card"} name={"3748_0"} excel_reference={40} style={{fontSize: '14px'}}  >
				<Paragraph text={"Cumplir con Quality Control[Cumplido todas las QCs programadas {yo y empleados}]"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"3678_0"} label={"EE - Cumplir con Quality Control[Cumplido todas las QCs programadas {yo y empleados}]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={41}  />
			<Select className={"question-card"} name={"3679_0"} label={"Mgr - Cumplir con Quality Control[Cumplido todas las QCs programadas {yo y empleados}]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={42}  />
			<TextInput className={"question-card"} name={"3681_0"} label={"Comentarios - Cumplir con Quality Control[Cumplido todas las QCs programadas {yo y empleados}]"} excel_reference={43}  />
			<div className={"question-card"} name={"3749_0"} excel_reference={44} style={{fontSize: '14px'}}  >
				<Paragraph text={"Lista de Empleados CompletaTodas las Cuentas tienen Lista"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"3682_0"} label={"EE - Lista de Empleados CompletaTodas las Cuentas tienen Lista"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={45}  />
			<Select className={"question-card"} name={"3739_0"} label={"Mgr - Lista de Empleados CompletaTodas las Cuentas tienen Lista"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={46}  />
			<TextInput className={"question-card"} name={"3740_0"} label={"Comentarios - Lista de Empleados CompletaTodas las Cuentas tienen Lista"} excel_reference={47}  />
			<div className={"question-card"} name={"3750_0"} excel_reference={48} style={{fontSize: '14px'}}  >
				<Paragraph text={"Participaci\u00f3n en KEIT[100% asistencia y robusta participaci\u00f3n]"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"3683_0"} label={"EE - Participaci\u00f3n en KEIT[100% asistencia y robusta participaci\u00f3n]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={49}  />
			<Select className={"question-card"} name={"3741_0"} label={"Mgr - Participaci\u00f3n en KEIT[100% asistencia y robusta participaci\u00f3n]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={50}  />
			<TextInput className={"question-card"} name={"3691_0"} label={"Comentarios - Crear Excelentes (RTF) ICAs[Enfocar en la prevenci\u00f3n de la recurrencia ]"} excel_reference={51}  />
		</Accordion>,
		<Accordion index={3684} header={<div className={"question-card"} name={"3684_0"} excel_reference={52} style={{fontSize: '24px'}}  >
				<Paragraph text={"LIDERAZGO"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"3686_0,3687_0,3688_0,3689_0,3690_0,3742_0,3692_0,3693_0,3694_0,3695_0,3696_0,3697_0,3698_0,3699_0,3700_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<div className={"question-card"} name={"3685_0"} excel_reference={53} style={{fontSize: '14px'}}  >
				<Paragraph text={"SI LA RESPONSABILIDAD NO APPLICA PUNTUACION ES= 10"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<div className={"question-card"} name={"3751_0"} excel_reference={54} style={{fontSize: '14px'}}  >
				<Paragraph text={"Participante Activo/Presentaci\u00f3n En WLM/DLM[Dominio de Material- \\\"posee\\\" cuentas asignadas]"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"3686_0"} label={"EE - Participante Activo/Presentaci\u00f3n En WLM/DLM[Dominio de Material- \\\"posee\\\" cuentas asignadas]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={55}  />
			<Select className={"question-card"} name={"3687_0"} label={"Mgr - Participante Activo/Presentaci\u00f3n En WLM/DLM[Dominio de Material- \\\"posee\\\" cuentas asignadas]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={56}  />
			<TextInput className={"question-card"} name={"3688_0"} label={"Comentarios - Participante Activo/Presentaci\u00f3n En WLM/DLM[Dominio de Material- \\\"posee\\\" cuentas asignadas]"} excel_reference={57}  />
			<div className={"question-card"} name={"3752_0"} excel_reference={58} style={{fontSize: '14px'}}  >
				<Paragraph text={"Crear Excelentes (RTF) ICAs[Enfocar en la prevenci\u00f3n de la recurrencia ]"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"3689_0"} label={"EE - Crear Excelentes (RTF) ICAs[Enfocar en la prevenci\u00f3n de la recurrencia ]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={59}  />
			<Select className={"question-card"} name={"3690_0"} label={"Mgr - Crear Excelentes (RTF) ICAs[Enfocar en la prevenci\u00f3n de la recurrencia ]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={60}  />
			<TextInput className={"question-card"} name={"3742_0"} label={"Comentarios - Participaci\u00f3n en KEIT[100% asistencia y robusta participaci\u00f3n]"} excel_reference={61}  />
			<div className={"question-card"} name={"3753_0"} excel_reference={62} style={{fontSize: '14px'}}  >
				<Paragraph text={"Supervisar y Entrenar Empleados[Demuestra EE Supervisi\u00f3n, direcci\u00f3n y entrenamiento]"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"3692_0"} label={"EE - Supervisar y Entrenar Empleados[Demuestra EE Supervisi\u00f3n, direcci\u00f3n y entrenamiento]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={63}  />
			<Select className={"question-card"} name={"3693_0"} label={"Mgr - Supervisar y Entrenar Empleados[Demuestra EE Supervisi\u00f3n, direcci\u00f3n y entrenamiento]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={64}  />
			<TextInput className={"question-card"} name={"3694_0"} label={"Comentarios - Supervisar y Entrenar Empleados[Demuestra EE Supervisi\u00f3n, direcci\u00f3n y entrenamiento]"} excel_reference={65}  />
			<div className={"question-card"} name={"3754_0"} excel_reference={66} style={{fontSize: '14px'}}  >
				<Paragraph text={"Administrar Trabajo Diario[Revisa Trabajo Diario y sabe quien esta trabajando y cuando y ajusta]"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"3695_0"} label={"EE - Administrar Trabajo Diario[Revisa Trabajo Diario y sabe quien esta trabajando y cuando y ajusta]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={67}  />
			<Select className={"question-card"} name={"3696_0"} label={"Mgr - Administrar Trabajo Diario[Revisa Trabajo Diario y sabe quien esta trabajando y cuando y ajusta]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={68}  />
			<TextInput className={"question-card"} name={"3697_0"} label={"Comentarios - Administrar Trabajo Diario[Revisa Trabajo Diario y sabe quien esta trabajando y cuando y ajusta]"} excel_reference={69}  />
			<div className={"question-card"} name={"3755_0"} excel_reference={70} style={{fontSize: '14px'}}  >
				<Paragraph text={"Administrar Materiales/Consumibles/Equipo[Inventario consistente para gestionar el uso de los suministros y saber de lo que esta siendo utilizado antes de pedir mas ]"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"3698_0"} label={"EE - Administrar Materiales/Consumibles/Equipo[Inventario consistente para gestionar el uso de los suministros y saber de lo que esta siendo utilizado antes de pedir mas ]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={71}  />
			<Select className={"question-card"} name={"3699_0"} label={"Mgr - Administrar Materiales/Consumibles/Equipo[Inventario consistente para gestionar el uso de los suministros y saber de lo que esta siendo utilizado antes de pedir mas ]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={72}  />
			<TextInput className={"question-card"} name={"3700_0"} label={"Comentarios - Administrar Materiales/Consumibles/Equipo[Inventario consistente para gestionar el uso de los suministros y saber de lo que esta siendo utilizado antes de pedir mas ]"} excel_reference={73}  />
		</Accordion>,
		<Accordion index={3701} header={<div className={"question-card"} name={"3701_0"} excel_reference={74} style={{fontSize: '24px'}}  >
				<Paragraph text={"MANEJO DE LOS CLIENTES"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"3703_0,3704_0,3705_0,3706_0,3707_0,3708_0,3709_0,3710_0,3711_0,3715_0,3716_0,3717_0,3718_0,3719_0,3721_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<div className={"question-card"} name={"3702_0"} excel_reference={75} style={{fontSize: '14px'}}  >
				<Paragraph text={"SI LA RESPONSABILIDAD NO APPLICA PUNTUACION ES= 10"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<div className={"question-card"} name={"3756_0"} excel_reference={76} style={{fontSize: '14px'}}  >
				<Paragraph text={"CONSTRUIR GRANDES RELACIONES CON LOS CLIENTES[Se lleva muy bien con los clientes]"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"3703_0"} label={"EE - CONSTRUIR GRANDES RELACIONES CON LOS CLIENTES[Se lleva muy bien con los clientes]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={77}  />
			<Select className={"question-card"} name={"3704_0"} label={"Mgr - CONSTRUIR GRANDES RELACIONES CON LOS CLIENTES[Se lleva muy bien con los clientes]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={78}  />
			<TextInput className={"question-card"} name={"3705_0"} label={"Comentarios - CONSTRUIR GRANDES RELACIONES CON LOS CLIENTES[Se lleva muy bien con los clientes]"} excel_reference={79}  />
			<div className={"question-card"} name={"3757_0"} excel_reference={80} style={{fontSize: '14px'}}  >
				<Paragraph text={"Seguimiento con Clientes Externos[Conocido por el cumplimiento de los compromisos]"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"3706_0"} label={"EE - Seguimiento con Clientes Externos[Conocido por el cumplimiento de los compromisos]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={81}  />
			<Select className={"question-card"} name={"3707_0"} label={"Mgr - Seguimiento con Clientes Externos[Conocido por el cumplimiento de los compromisos]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={82}  />
			<TextInput className={"question-card"} name={"3708_0"} label={"Comentarios - Seguimiento con Clientes Externos[Conocido por el cumplimiento de los compromisos]"} excel_reference={83}  />
			<div className={"question-card"} name={"3758_0"} excel_reference={84} style={{fontSize: '14px'}}  >
				<Paragraph text={"Proporciona Atenci\u00f3n a los problemas de los clientes[Cliente te describe como \\\"gran capacidad\\\"]"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"3709_0"} label={"EE - Proporciona Atenci\u00f3n a los problemas de los clientes[Cliente te describe como \\\"gran capacidad\\\"]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={85}  />
			<Select className={"question-card"} name={"3710_0"} label={"Mgr - Proporciona Atenci\u00f3n a los problemas de los clientes[Cliente te describe como \\\"gran capacidad\\\"]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={86}  />
			<TextInput className={"question-card"} name={"3711_0"} label={"Comentarios - Proporciona Atenci\u00f3n a los problemas de los clientes[Cliente te describe como \\\"gran capacidad\\\"]"} excel_reference={87}  />
			<div className={"question-card"} name={"3766_0"} excel_reference={88} style={{fontSize: '14px'}}  >
				<Paragraph text={"Comentarios del cliente supera las Expectativas[Recibe puntuaci\u00f3n del cliente de 8 o&gt; mensual]"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"3715_0"} label={"EE - Comentarios del cliente supera las Expectativas[Recibe puntuaci\u00f3n del cliente de 8 o&gt; mensual]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={89}  />
			<Select className={"question-card"} name={"3716_0"} label={"Mgr - Comentarios del cliente supera las Expectativas[Recibe puntuaci\u00f3n del cliente de 8 o&gt; mensual]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={90}  />
			<TextInput className={"question-card"} name={"3717_0"} label={"Comentarios - Comentarios del cliente supera las Expectativas[Recibe puntuaci\u00f3n del cliente de 8 o&gt; mensual]"} excel_reference={91}  />
			<div className={"question-card"} name={"3760_0"} excel_reference={92} style={{fontSize: '14px'}}  >
				<Paragraph text={"Seguimiento con Clientes Internos[Proporciona a Ops y HB Liderazgo no vice-versa]"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"3718_0"} label={"EE - Seguimiento con Clientes Internos[Proporciona a Ops y HB Liderazgo no vice-versa]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={93}  />
			<Select className={"question-card"} name={"3719_0"} label={"Mgr - Seguimiento con Clientes Internos[Proporciona a Ops y HB Liderazgo no vice-versa]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={94}  />
			<TextInput className={"question-card"} name={"3721_0"} label={"Comentarios - Seguimiento con Clientes Internos[Proporciona a Ops y HB Liderazgo no vice-versa]"} excel_reference={95}  />
		</Accordion>,
		<Accordion index={3722} header={<div className={"question-card"} name={"3722_0"} excel_reference={96} style={{fontSize: '24px'}}  >
				<Paragraph text={"COLABORACION"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"3724_0,3725_0,3726_0,3727_0,3729_0,3730_0,3731_0,3732_0,3733_0,3734_0,3735_0,3736_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<div className={"question-card"} name={"3723_0"} excel_reference={97} style={{fontSize: '14px'}}  >
				<Paragraph text={"SI LA RESPONSABILIDAD NO APPLICA PUNTUACION ES= 10"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<div className={"question-card"} name={"3761_0"} excel_reference={98} style={{fontSize: '14px'}}  >
				<Paragraph text={"Sumisi\u00f3n de Nomina a Tiempo[No reportes de tiempo abierto]"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"3724_0"} label={"EE - Sumisi\u00f3n de Nomina a Tiempo[No reportes de tiempo abierto]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={99}  />
			<Select className={"question-card"} name={"3725_0"} label={"Mgr - Sumisi\u00f3n de Nomina a Tiempo[No reportes de tiempo abierto]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={100}  />
			<TextInput className={"question-card"} name={"3726_0"} label={"Comentarios - Sumisi\u00f3n de Nomina a Tiempo[No reportes de tiempo abierto]"} excel_reference={101}  />
			<div className={"question-card"} name={"3762_0"} excel_reference={102} style={{fontSize: '14px'}}  >
				<Paragraph text={"Colabora con HR (incluye entrenamientos)[Eficazmente se comunica con regularidad con HR]"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"3727_0"} label={"EE - Colabora con HR (incluye entrenamientos)[Eficazmente se comunica con regularidad con HR]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={103}  />
			<Select className={"question-card"} name={"3729_0"} label={"Mgr - Colabora con HR (incluye entrenamientos)[Eficazmente se comunica con regularidad con HR]"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={104}  />
			<TextInput className={"question-card"} name={"3730_0"} label={"Comentarios - Colabora con HR (incluye entrenamientos)[Eficazmente se comunica con regularidad con HR]"} excel_reference={105}  />
			<div className={"question-card"} name={"3763_0"} excel_reference={106} style={{fontSize: '14px'}}  >
				<Paragraph text={"Colabora con S&P[Provee 1 buena referencia/semana y asiste Walk-Thrus)"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"3731_0"} label={"EE - Colabora con S&P[Provee 1 buena referencia/semana y asiste Walk-Thrus)"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={107}  />
			<Select className={"question-card"} name={"3732_0"} label={"Mgr - Colabora con S&P[Provee 1 buena referencia/semana y asiste Walk-Thrus)"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={108}  />
			<TextInput className={"question-card"} name={"3733_0"} label={"Comentarios - Colabora con S&P[Provee 1 buena referencia/semana y asiste Walk-Thrus)"} excel_reference={109}  />
			<div className={"question-card"} name={"3764_0"} excel_reference={110} style={{fontSize: '14px'}}  >
				<Paragraph text={"Vender y Enviar TAGs[Puntual/Precisa TAG registro/notificaci\u00f3n @ +20%] Safeway - On Demand ordenes de trabajo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"3734_0"} label={"EE - Vender y Enviar TAGs[Puntual/Precisa TAG registro/notificaci\u00f3n @ +20%] Safeway - On Demand ordenes de trabajo"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={111}  />
			<Select className={"question-card"} name={"3735_0"} label={"Mgr - Vender y Enviar TAGs[Puntual/Precisa TAG registro/notificaci\u00f3n @ +20%] Safeway - On Demand ordenes de trabajo"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={112}  />
			<TextInput className={"question-card"} name={"3736_0"} label={"Comentarios - Vender y Enviar TAGs[Puntual/Precisa TAG registro/notificaci\u00f3n @ +20%] Safeway - On Demand ordenes de trabajo"} excel_reference={113}  />
		</Accordion>,
		<Accordion index={3737} header={<div className={"question-card"} name={"3737_0"} excel_reference={114} style={{fontSize: '24px'}}  >
				<Paragraph text={"PROMEDIO TOTAL DE PUNTUACION"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"3744_0,3745_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<TextInput className={"question-card"} name={"3744_0"} label={"EE - PROMEDIO TOTAL DE PUNTUACION"} excel_reference={115} excel={'(+Q29+Q33+Q37+Q41+Q45+Q49+Q55+Q59+Q63+Q67+Q71+Q77+Q81+Q85+Q89+Q93+Q99+Q103+Q107+Q111)/20'}  />
			<TextInput className={"question-card"} name={"3745_0"} label={"Mgr - PROMEDIO TOTAL DE PUNTUACION"} excel_reference={116} excel={'(+Q30+Q34+Q38+Q42+Q46+Q50+Q56+Q60+Q64+Q68+Q72+Q78+Q82+Q86+Q90+Q94+Q100+Q104+Q108+Q112)/20'}  />
		</Accordion>,
    ];

    return elements;
}

export default get_form_elements;

import React, { Component } from 'react';
import {
    Wrapper,
    Container,
    Header,
    Div,
    Paragraph,
    FormWithChildren,
    DateTimePicker,
    TextInput,
    Select,
    TextArea,
    UserSearchInput,
    SignatureInput,
    ImageInput,
    Accordion,
} from 'library';

function get_form_elements() {
    var elements = [
        <Accordion
            index={10989}
            header={
                <div
                    className={'question-card'}
                    name={'10989_0'}
                    excel_reference={1}
                    style={{ fontSize: '24px' }}
                >
                    <Paragraph
                        text={'QUALITY CONTROL INSPECTION'}
                        style={{
                            margin: '0px',
                            fontSize: 'inherit',
                            marginRight: '200px',
                        }}
                    />
                </div>
            }
            names={'10990_0,10993_0,10994_0,10995_0'}
            sub_total={''}
            className={'col-12'}
            style={{ padding: '0px' }}
        >
            <Select
                className={'question-card'}
                name={'10990_0'}
                label={
                    'Select Position Below if QC Form is Completed by a Supervisor or Manager'
                }
                options={[
                    { text: 'Supervisor', value: 'Supervisor' },
                    { text: 'Area Supervisor', value: 'Area Supervisor' },
                    {
                        text: 'Dir/Mgr of Operations',
                        value: 'Dir/Mgr of Operations',
                    },
                    { text: 'Vice President', value: 'Vice President' },
                ]}
                radio={true}
                excel_reference={2}
            />
            <TextInput
                className={'question-card'}
                name={'10993_0'}
                label={'Area:'}
                excel_reference={5}
                required={true}
            />
            <TextInput
                className={'question-card'}
                name={'10994_0'}
                label={'Completed by:'}
                excel_reference={6}
                required={true}
            />
            <TextInput
                className={'question-card'}
                name={'10995_0'}
                label={'Supervisor'}
                excel_reference={7}
                required={true}
            />
        </Accordion>,
        <Accordion
            index={10996}
            header={
                <div
                    className={'question-card'}
                    name={'10996_0'}
                    excel_reference={8}
                    anchor={true}
                    anchor_text={'MAIN ENTRANCE / LOBBY / ELEVAT'}
                    style={{ fontSize: '24px' }}
                >
                    <Paragraph
                        text={'MAIN ENTRANCE / LOBBY / ELEVATORS'}
                        style={{
                            margin: '0px',
                            fontSize: 'inherit',
                            marginRight: '200px',
                        }}
                    />
                </div>
            }
            names={
                '10997_0,10998_0,10999_0,11000_0,11001_0,11002_0,11003_0,11004_0,11005_0,11006_0,11007_0'
            }
            sub_total={'11011_0'}
            className={'col-12'}
            style={{ padding: '0px' }}
        >
            <Select
                className={'question-card'}
                name={'10997_0'}
                label={'Door Glass / Side Glass - Main entrance'}
                options={[
                    { text: 'Pass', value: 1 },
                    { text: 'Fail', value: 0 },
                ]}
                radio={true}
                excel_reference={9}
            />
            <Select
                className={'question-card'}
                name={'10998_0'}
                label={'Walk Off Mats / Corners & Edges'}
                options={[
                    { text: 'Pass', value: 1 },
                    { text: 'Fail', value: 0 },
                ]}
                radio={true}
                excel_reference={10}
            />
            <Select
                className={'question-card'}
                name={'10999_0'}
                label={'Carpets Vacuumed  / Spot Clean Stains'}
                options={[
                    { text: 'Pass', value: 1 },
                    { text: 'Fail', value: 0 },
                ]}
                radio={true}
                excel_reference={11}
            />
            <Select
                className={'question-card'}
                name={'11000_0'}
                label={'Floors - Sweep, Mop, Buff & Vacuum'}
                options={[
                    { text: 'Pass', value: 1 },
                    { text: 'Fail', value: 0 },
                ]}
                radio={true}
                excel_reference={12}
            />
            <Select
                className={'question-card'}
                name={'11001_0'}
                label={
                    'Main Elevator Cabs - Interior & Exterior Directional Plates, Tracks'
                }
                options={[
                    { text: 'Pass', value: 1 },
                    { text: 'Fail', value: 0 },
                ]}
                radio={true}
                excel_reference={13}
            />
            <Select
                className={'question-card'}
                name={'11002_0'}
                label={'Escalators & Landings'}
                options={[
                    { text: 'Pass', value: 1 },
                    { text: 'Fail', value: 0 },
                ]}
                radio={true}
                excel_reference={14}
            />
            <Select
                className={'question-card'}
                name={'11003_0'}
                label={'Ice Machine'}
                options={[
                    { text: 'Pass', value: 1 },
                    { text: 'Fail', value: 0 },
                ]}
                radio={true}
                excel_reference={15}
            />
            <Select
                className={'question-card'}
                name={'11004_0'}
                label={'High Low Dusting / A/C Vents'}
                options={[
                    { text: 'Pass', value: 1 },
                    { text: 'Fail', value: 0 },
                ]}
                radio={true}
                excel_reference={16}
            />
            <Select
                className={'question-card'}
                name={'11005_0'}
                label={'Trash Containers / Ash Urns'}
                options={[
                    { text: 'Pass', value: 1 },
                    { text: 'Fail', value: 0 },
                ]}
                radio={true}
                excel_reference={17}
            />
            <Select
                className={'question-card'}
                name={'11006_0'}
                label={'Lobby Furniture & Fixtures'}
                options={[
                    { text: 'Pass', value: 1 },
                    { text: 'Fail', value: 0 },
                ]}
                radio={true}
                excel_reference={18}
            />
            <Select
                className={'question-card'}
                name={'11007_0'}
                label={'Building Exterior (police area)'}
                options={[
                    { text: 'Pass', value: 1 },
                    { text: 'Fail', value: 0 },
                ]}
                radio={true}
                excel_reference={19}
            />
            <TextInput
                className={'question-card'}
                name={'11008_0'}
                label={'COMMENTS '}
                excel_reference={20}
                fail_comment={true}
            />
            <div
                className={'question-card'}
                name={'11009_0'}
                excel_reference={21}
            >
                <Paragraph
                    text={'Take a photo'}
                    style={{ margin: '0px', fontSize: 'inherit' }}
                />
            </div>
            <ImageInput
                className={'question-card'}
                name={'11010_0'}
                excel_reference={22}
            />
            <TextInput
                className={'question-card'}
                name={'11011_0'}
                label={'Total Rating'}
                excel_reference={23}
                excel={'+Q9+Q10+Q11+Q12+Q13+Q14+Q15+Q16+Q17+Q18+Q19'}
            />
        </Accordion>,
        <Accordion
            index={11012}
            header={
                <div
                    className={'question-card'}
                    name={'11012_0'}
                    excel_reference={24}
                    anchor={true}
                    anchor_text={'COMMON AREAS'}
                    style={{ fontSize: '24px' }}
                >
                    <Paragraph
                        text={'COMMON AREAS'}
                        style={{
                            margin: '0px',
                            fontSize: 'inherit',
                            marginRight: '200px',
                        }}
                    />
                </div>
            }
            names={
                '11013_0,11014_0,11015_0,11016_0,11017_0,11018_0,11019_0,11020_0,11021_0'
            }
            sub_total={'11025_0'}
            className={'col-12'}
            style={{ padding: '0px' }}
        >
            <Select
                className={'question-card'}
                name={'11013_0'}
                label={'Drinking Fountains         '}
                options={[
                    { text: 'Pass', value: 1 },
                    { text: 'Fail', value: 0 },
                ]}
                radio={true}
                excel_reference={25}
            />
            <Select
                className={'question-card'}
                name={'11014_0'}
                label={'Stairway'}
                options={[
                    { text: 'Pass', value: 1 },
                    { text: 'Fail', value: 0 },
                ]}
                radio={true}
                excel_reference={26}
            />
            <Select
                className={'question-card'}
                name={'11015_0'}
                label={'Elevators, Cabs, Tracks, Panels'}
                options={[
                    { text: 'Pass', value: 1 },
                    { text: 'Fail', value: 0 },
                ]}
                radio={true}
                excel_reference={27}
            />
            <Select
                className={'question-card'}
                name={'11016_0'}
                label={'Telephones'}
                options={[
                    { text: 'Pass', value: 1 },
                    { text: 'Fail', value: 0 },
                ]}
                radio={true}
                excel_reference={28}
            />
            <Select
                className={'question-card'}
                name={'11017_0'}
                label={'Interior Glass'}
                options={[
                    { text: 'Pass', value: 1 },
                    { text: 'Fail', value: 0 },
                ]}
                radio={true}
                excel_reference={29}
            />
            <Select
                className={'question-card'}
                name={'11018_0'}
                label={'High Low Dusting / A/C Vents'}
                options={[
                    { text: 'Pass', value: 1 },
                    { text: 'Fail', value: 0 },
                ]}
                radio={true}
                excel_reference={30}
            />
            <Select
                className={'question-card'}
                name={'11019_0'}
                label={'Floors - Sweep, Mop, Buff & Vacuum'}
                options={[
                    { text: 'Pass', value: 1 },
                    { text: 'Fail', value: 0 },
                ]}
                radio={true}
                excel_reference={31}
            />
            <Select
                className={'question-card'}
                name={'11020_0'}
                label={'Carpets Vacuumed  / Spot Clean Stains'}
                options={[
                    { text: 'Pass', value: 1 },
                    { text: 'Fail', value: 0 },
                ]}
                radio={true}
                excel_reference={32}
            />
            <Select
                className={'question-card'}
                name={'11021_0'}
                label={'Wall Spotting / Corners & Edges'}
                options={[
                    { text: 'Pass', value: 1 },
                    { text: 'Fail', value: 0 },
                ]}
                radio={true}
                excel_reference={33}
            />
            <TextInput
                className={'question-card'}
                name={'11022_0'}
                label={'COMMENTS'}
                excel_reference={34}
                fail_comment={true}
            />
            <div
                className={'question-card'}
                name={'11023_0'}
                excel_reference={35}
            >
                <Paragraph
                    text={'Take a photo'}
                    style={{ margin: '0px', fontSize: 'inherit' }}
                />
            </div>
            <ImageInput
                className={'question-card'}
                name={'11024_0'}
                excel_reference={36}
            />
            <TextInput
                className={'question-card'}
                name={'11025_0'}
                label={'Total Rating'}
                excel_reference={37}
                excel={'Q25+Q26+Q27+Q28+Q29+Q30+Q31+Q32+Q33'}
            />
        </Accordion>,
        <Accordion
            index={11026}
            header={
                <div
                    className={'question-card'}
                    name={'11026_0'}
                    excel_reference={38}
                    anchor={true}
                    anchor_text={'OFFICES / CONFERENCE ROOMS'}
                    style={{ fontSize: '24px' }}
                >
                    <Paragraph
                        text={'OFFICES / CONFERENCE ROOMS'}
                        style={{
                            margin: '0px',
                            fontSize: 'inherit',
                            marginRight: '200px',
                        }}
                    />
                </div>
            }
            names={
                '11027_0,11028_0,11029_0,11030_0,11031_0,11032_0,11033_0,11034_0,11035_0,11036_0,11037_0'
            }
            sub_total={'11041_0'}
            className={'col-12'}
            style={{ padding: '0px' }}
        >
            <Select
                className={'question-card'}
                name={'11027_0'}
                label={'Trash Removed & Liner Replaced'}
                options={[
                    { text: 'Pass', value: 1 },
                    { text: 'Fail', value: 0 },
                ]}
                radio={true}
                excel_reference={39}
            />
            <Select
                className={'question-card'}
                name={'11028_0'}
                label={'Carpets Vacuumed  / Spot Clean Stains'}
                options={[
                    { text: 'Pass', value: 1 },
                    { text: 'Fail', value: 0 },
                ]}
                radio={true}
                excel_reference={40}
            />
            <Select
                className={'question-card'}
                name={'11029_0'}
                label={'Floors - Sweep, Mop, Buff & Vacuum'}
                options={[
                    { text: 'Pass', value: 1 },
                    { text: 'Fail', value: 0 },
                ]}
                radio={true}
                excel_reference={41}
            />
            <Select
                className={'question-card'}
                name={'11030_0'}
                label={'Vacuum / Sweep Corners, Edges, & Under Furniture'}
                options={[
                    { text: 'Pass', value: 1 },
                    { text: 'Fail', value: 0 },
                ]}
                radio={true}
                excel_reference={42}
            />
            <Select
                className={'question-card'}
                name={'11031_0'}
                label={'Interior Glass'}
                options={[
                    { text: 'Pass', value: 1 },
                    { text: 'Fail', value: 0 },
                ]}
                radio={true}
                excel_reference={43}
            />
            <Select
                className={'question-card'}
                name={'11032_0'}
                label={'Telephones'}
                options={[
                    { text: 'Pass', value: 1 },
                    { text: 'Fail', value: 0 },
                ]}
                radio={true}
                excel_reference={44}
            />
            <Select
                className={'question-card'}
                name={'11033_0'}
                label={'High Low Dusting'}
                options={[
                    { text: 'Pass', value: 1 },
                    { text: 'Fail', value: 0 },
                ]}
                radio={true}
                excel_reference={45}
            />
            <Select
                className={'question-card'}
                name={'11034_0'}
                label={'Blinds / Window Sills Dusted'}
                options={[
                    { text: 'Pass', value: 1 },
                    { text: 'Fail', value: 0 },
                ]}
                radio={true}
                excel_reference={46}
            />
            <Select
                className={'question-card'}
                name={'11035_0'}
                label={'Furniture, File Cabinets, Counter Tops, Etc.'}
                options={[
                    { text: 'Pass', value: 1 },
                    { text: 'Fail', value: 0 },
                ]}
                radio={true}
                excel_reference={47}
            />
            <Select
                className={'question-card'}
                name={'11036_0'}
                label={'Wall Spotting (Finger Prints, Smudges, Etc.)'}
                options={[
                    { text: 'Pass', value: 1 },
                    { text: 'Fail', value: 0 },
                ]}
                radio={true}
                excel_reference={48}
            />
            <Select
                className={'question-card'}
                name={'11037_0'}
                label={'A/C Vents & Return Air Vents'}
                options={[
                    { text: 'Pass', value: 1 },
                    { text: 'Fail', value: 0 },
                ]}
                radio={true}
                excel_reference={49}
            />
            <TextInput
                className={'question-card'}
                name={'11038_0'}
                label={'COMMENTS'}
                excel_reference={50}
                fail_comment={true}
            />
            <div
                className={'question-card'}
                name={'11039_0'}
                excel_reference={51}
            >
                <Paragraph
                    text={'Take a photo'}
                    style={{ margin: '0px', fontSize: 'inherit' }}
                />
            </div>
            <ImageInput
                className={'question-card'}
                name={'11040_0'}
                excel_reference={52}
            />
            <TextInput
                className={'question-card'}
                name={'11041_0'}
                label={'Total Rating'}
                excel_reference={53}
                excel={'Q39+Q40+Q41+Q42+Q43+Q44+Q45+Q46+Q47+Q48+Q49'}
            />
        </Accordion>,
        <Accordion
            index={11042}
            header={
                <div
                    className={'question-card'}
                    name={'11042_0'}
                    excel_reference={54}
                    anchor={true}
                    anchor_text={'REST ROOMS / LOCKER ROOMS'}
                    style={{ fontSize: '24px' }}
                >
                    <Paragraph
                        text={'REST ROOMS / LOCKER ROOMS'}
                        style={{
                            margin: '0px',
                            fontSize: 'inherit',
                            marginRight: '200px',
                        }}
                    />
                </div>
            }
            names={
                '11043_0,11044_0,11045_0,11046_0,11047_0,11048_0,11049_0,11050_0,11051_0,11052_0'
            }
            sub_total={'11056_0'}
            className={'col-12'}
            style={{ padding: '0px' }}
        >
            <Select
                className={'question-card'}
                name={'11043_0'}
                label={'Trash Removed & Liner Replaced'}
                options={[
                    { text: 'Pass', value: 1 },
                    { text: 'Fail', value: 0 },
                ]}
                radio={true}
                excel_reference={55}
            />
            <Select
                className={'question-card'}
                name={'11044_0'}
                label={'Clean & Polish Stainless Steel Fixtures'}
                options={[
                    { text: 'Pass', value: 1 },
                    { text: 'Fail', value: 0 },
                ]}
                radio={true}
                excel_reference={56}
            />
            <Select
                className={'question-card'}
                name={'11045_0'}
                label={'Replenish Tissue, Towels, & Soap Dispensers'}
                options={[
                    { text: 'Pass', value: 1 },
                    { text: 'Fail', value: 0 },
                ]}
                radio={true}
                excel_reference={57}
            />
            <Select
                className={'question-card'}
                name={'11046_0'}
                label={'Mirrors & Frames'}
                options={[
                    { text: 'Pass', value: 1 },
                    { text: 'Fail', value: 0 },
                ]}
                radio={true}
                excel_reference={58}
            />
            <Select
                className={'question-card'}
                name={'11047_0'}
                label={'High & Low Dusting'}
                options={[
                    { text: 'Pass', value: 1 },
                    { text: 'Fail', value: 0 },
                ]}
                radio={true}
                excel_reference={59}
            />
            <Select
                className={'question-card'}
                name={'11048_0'}
                label={'Sinks, Faucets, & Counter Tops'}
                options={[
                    { text: 'Pass', value: 1 },
                    { text: 'Fail', value: 0 },
                ]}
                radio={true}
                excel_reference={60}
            />
            <Select
                className={'question-card'}
                name={'11049_0'}
                label={'Floors Swept, Mopped'}
                options={[
                    { text: 'Pass', value: 1 },
                    { text: 'Fail', value: 0 },
                ]}
                radio={true}
                excel_reference={61}
            />
            <Select
                className={'question-card'}
                name={'11050_0'}
                label={
                    'Toilets / Urinals - Bowls, Seats, Rim, Plumbing Fixtures'
                }
                options={[
                    { text: 'Pass', value: 1 },
                    { text: 'Fail', value: 0 },
                ]}
                radio={true}
                excel_reference={62}
            />
            <Select
                className={'question-card'}
                name={'11051_0'}
                label={'Partition Walls, Doors, Etc.'}
                options={[
                    { text: 'Pass', value: 1 },
                    { text: 'Fail', value: 0 },
                ]}
                radio={true}
                excel_reference={63}
            />
            <Select
                className={'question-card'}
                name={'11052_0'}
                label={'A/C Vents & Return Air Vents'}
                options={[
                    { text: 'Pass', value: 1 },
                    { text: 'Fail', value: 0 },
                ]}
                radio={true}
                excel_reference={64}
            />
            <TextInput
                className={'question-card'}
                name={'11053_0'}
                label={'COMMENTS'}
                excel_reference={65}
                fail_comment={true}
            />
            <div
                className={'question-card'}
                name={'11054_0'}
                excel_reference={66}
            >
                <Paragraph
                    text={'Take a photo'}
                    style={{ margin: '0px', fontSize: 'inherit' }}
                />
            </div>
            <ImageInput
                className={'question-card'}
                name={'11055_0'}
                excel_reference={67}
            />
            <TextInput
                className={'question-card'}
                name={'11056_0'}
                label={'Total Rating '}
                excel_reference={68}
                excel={'Q55+Q56+Q57+Q58+Q59+Q60+Q61+Q62+Q63+Q64'}
            />
        </Accordion>,
        <Accordion
            index={11057}
            header={
                <div
                    className={'question-card'}
                    name={'11057_0'}
                    excel_reference={69}
                    anchor={true}
                    anchor_text={' BREAK ROOMS / CAFETERIA'}
                    style={{ fontSize: '24px' }}
                >
                    <Paragraph
                        text={' BREAK ROOMS / CAFETERIA'}
                        style={{
                            margin: '0px',
                            fontSize: 'inherit',
                            marginRight: '200px',
                        }}
                    />
                </div>
            }
            names={'11058_0,11059_0,11060_0,11061_0,11062_0,11063_0,11064_0'}
            sub_total={'11068_0'}
            className={'col-12'}
            style={{ padding: '0px' }}
        >
            <Select
                className={'question-card'}
                name={'11058_0'}
                label={'Floors Swept, Mopped, Vacuum & Buff'}
                options={[
                    { text: 'Pass', value: 1 },
                    { text: 'Fail', value: 0 },
                ]}
                radio={true}
                excel_reference={70}
            />
            <Select
                className={'question-card'}
                name={'11059_0'}
                label={'High & Low Dusting'}
                options={[
                    { text: 'Pass', value: 1 },
                    { text: 'Fail', value: 0 },
                ]}
                radio={true}
                excel_reference={71}
            />
            <Select
                className={'question-card'}
                name={'11060_0'}
                label={'Tables / Chairs'}
                options={[
                    { text: 'Pass', value: 1 },
                    { text: 'Fail', value: 0 },
                ]}
                radio={true}
                excel_reference={72}
            />
            <Select
                className={'question-card'}
                name={'11061_0'}
                label={'A/C Vents & Return Air Vents'}
                options={[
                    { text: 'Pass', value: 1 },
                    { text: 'Fail', value: 0 },
                ]}
                radio={true}
                excel_reference={73}
            />
            <Select
                className={'question-card'}
                name={'11062_0'}
                label={'Trash  removed & liners replaced'}
                options={[
                    { text: 'Pass', value: 1 },
                    { text: 'Fail', value: 0 },
                ]}
                radio={true}
                excel_reference={74}
            />
            <Select
                className={'question-card'}
                name={'11063_0'}
                label={'Counter Tops, Sinks, & Back Splash Areas'}
                options={[
                    { text: 'Pass', value: 1 },
                    { text: 'Fail', value: 0 },
                ]}
                radio={true}
                excel_reference={75}
            />
            <Select
                className={'question-card'}
                name={'11064_0'}
                label={'Wall Spotting (Finger Prints, Smudges)'}
                options={[
                    { text: 'Pass', value: 1 },
                    { text: 'Fail', value: 0 },
                ]}
                radio={true}
                excel_reference={76}
            />
            <TextInput
                className={'question-card'}
                name={'11065_0'}
                label={'COMMENTS'}
                excel_reference={77}
                fail_comment={true}
            />
            <div
                className={'question-card'}
                name={'11066_0'}
                excel_reference={78}
            >
                <Paragraph
                    text={'Take a Photo'}
                    style={{ margin: '0px', fontSize: 'inherit' }}
                />
            </div>
            <ImageInput
                className={'question-card'}
                name={'11067_0'}
                excel_reference={79}
            />
            <TextInput
                className={'question-card'}
                name={'11068_0'}
                label={'Total Rating'}
                excel_reference={80}
                excel={'Q70+Q71+Q72+Q73+Q74+Q75+Q76'}
            />
        </Accordion>,
        <Accordion
            index={11069}
            header={
                <div
                    className={'question-card'}
                    name={'11069_0'}
                    excel_reference={81}
                    anchor={true}
                    anchor_text={'JANITOR CLOSET / STORAGE AREA'}
                    style={{ fontSize: '24px' }}
                >
                    <Paragraph
                        text={'JANITOR CLOSET / STORAGE AREA'}
                        style={{
                            margin: '0px',
                            fontSize: 'inherit',
                            marginRight: '200px',
                        }}
                    />
                </div>
            }
            names={'11070_0,11071_0,11072_0,11073_0,11530_0,11531_0'}
            sub_total={'11077_0'}
            className={'col-12'}
            style={{ padding: '0px' }}
        >
            <Select
                className={'question-card'}
                name={'11070_0'}
                label={'Mop Sink & Floors'}
                options={[
                    { text: 'Pass', value: 1 },
                    { text: 'Fail', value: 0 },
                ]}
                radio={true}
                excel_reference={82}
            />
            <Select
                className={'question-card'}
                name={'11071_0'}
                label={'Supplies/Equipment (Stored neat & orderly)'}
                options={[
                    { text: 'Pass', value: 1 },
                    { text: 'Fail', value: 0 },
                ]}
                radio={true}
                excel_reference={83}
            />
            <Select
                className={'question-card'}
                name={'11072_0'}
                label={'SDS binders in closet and chemicals labeled'}
                options={[
                    { text: 'Pass', value: 1 },
                    { text: 'Fail', value: 0 },
                ]}
                radio={true}
                excel_reference={84}
            />
            <Select
                className={'question-card'}
                name={'11073_0'}
                label={'All chemicals in the closet are approved'}
                options={[
                    { text: 'Pass', value: 1 },
                    { text: 'Fail', value: 0 },
                ]}
                radio={true}
                excel_reference={85}
            />
            <Select
                className={'question-card'}
                name={'11530_0'}
                label={'Have all checklists been completed? '}
                options={[
                    { text: 'Pass', value: 1 },
                    { text: 'Fail', value: 0 },
                ]}
                radio={true}
                excel_reference={86}
            />
            <Select
                className={'question-card'}
                name={'11531_0'}
                label={
                    'Have all employees completed the sign in/out sheet for their shift?  '
                }
                options={[
                    { text: 'Pass', value: 1 },
                    { text: 'Fail', value: 0 },
                ]}
                radio={true}
                excel_reference={87}
            />
            <TextInput
                className={'question-card'}
                name={'11074_0'}
                label={'COMMENTS'}
                excel_reference={88}
                fail_comment={true}
            />
            <div
                className={'question-card'}
                name={'11075_0'}
                excel_reference={89}
            >
                <Paragraph
                    text={'Take a photo'}
                    style={{ margin: '0px', fontSize: 'inherit' }}
                />
            </div>
            <ImageInput
                className={'question-card'}
                name={'11076_0'}
                excel_reference={90}
            />
            <TextInput
                className={'question-card'}
                name={'11077_0'}
                label={'Rating Total'}
                excel_reference={91}
                excel={'Q82+Q83+Q84+Q85+Q86+Q87'}
            />
        </Accordion>,
        <Accordion
            index={11080}
            header={
                <div
                    className={'question-card'}
                    name={'11080_0'}
                    excel_reference={94}
                    anchor={true}
                    anchor_text={'CAN WE PROVIDE ADDITIONAL SERV'}
                    style={{ fontSize: '24px' }}
                >
                    <Paragraph
                        text={
                            'CAN WE PROVIDE ADDITIONAL SERVICES (TAGS)?  What extra services can we suggest to make the buildings look better?'
                        }
                        style={{
                            margin: '0px',
                            fontSize: 'inherit',
                            marginRight: '200px',
                        }}
                    />
                </div>
            }
            names={'11081_0,11082_0,11083_0,11084_0,11085_0,11086_0'}
            sub_total={''}
            className={'col-12'}
            style={{ padding: '0px' }}
        >
            <Select
                className={'question-card'}
                name={'11081_0'}
                label={'Do any areas need pressure washing? '}
                options={[
                    { text: 'Yes', value: 'Yes' },
                    { text: 'No', value: 'No' },
                ]}
                radio={true}
                excel_reference={95}
            />
            <Select
                className={'question-card'}
                name={'11082_0'}
                label={'Do we need to shampoo carpet before periodics are due?'}
                options={[
                    { text: 'Yes', value: 'Yes' },
                    { text: 'No', value: 'No' },
                ]}
                radio={true}
                excel_reference={96}
            />
            <Select
                className={'question-card'}
                name={'11083_0'}
                label={
                    'Do we need to strip & wax floor before periodics are due?'
                }
                options={[
                    { text: 'Yes', value: 'Yes' },
                    { text: 'No', value: 'No' },
                ]}
                radio={true}
                excel_reference={97}
            />
            <Select
                className={'question-card'}
                name={'11084_0'}
                label={'Do we need to provide any exterior window cleaning?'}
                options={[
                    { text: 'Yes', value: 'Yes' },
                    { text: 'No', value: 'No' },
                ]}
                radio={true}
                excel_reference={98}
            />
            <Select
                className={'question-card'}
                name={'11085_0'}
                label={'Can we provide a maintenance technician?'}
                options={[
                    { text: 'Yes', value: 'Yes' },
                    { text: 'No', value: 'No' },
                ]}
                radio={true}
                excel_reference={99}
            />
            <TextInput
                className={'question-card'}
                name={'11086_0'}
                label={
                    'If the answer to a question above is yes please comment below'
                }
                excel_reference={100}
            />
        </Accordion>,
        <TextInput
            className={'question-card'}
            name={'11078_0'}
            label={'Grand Total'}
            excel_reference={92}
            anchor={true}
            anchor_text={'Grand Total'}
            excel={'Q23+Q37+Q53+Q68+Q80+Q91'}
        />,
        <TextInput
            className={'question-card'}
            name={'11079_0'}
            label={'Percentage'}
            excel_reference={93}
            excel={'(Q92/54)*100'}
        />,
    ];

    return elements;
}

export default get_form_elements;

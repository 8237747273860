import React, { Component } from 'react';
import {resolveVariables} from 'functions';
import {TextInput, CSSInput} from 'library';

//Example
//var questionName = 'Question: ' + this.state.question.name;
//var questionText = {'text': this.state.question.text}
//var responseName = 'Response ' + this.state.answer.id;
//var responseText = {'text':this.state.answer.response}
//<Accordion names={[questionName, responseName]} open={[true,true]} ComponentList={[Paragraph, Paragraph]} ComponentProps={[questionText, responseText]} multiple={true} />

import {isMobile} from "react-device-detect";


class Accordion extends React.Component {
    static component_name = 'Accordion';
    constructor(props) {
        super(props);
        this.config = {
            form_components: [
                <TextInput label={'Name'} name={'name'} />,
                <TextInput label={'Index'} name={'index'} />,
                <CSSInput label={'css'} name={'style'} default={{}} />,
            ],
            can_have_children: true,
        };

        this.state = {
            open: this.props.open,
            starting_open: this.props.open,
            been_clicked: false
        };

        this.toggle_open = this.toggle_open.bind(this);
    }

    componentDidMount(){
        var open = this.props.open;

        //User feedback requires forms to start open
        if (open !== false) {
            open = true;
        }

        /*
        if (!(isMobile)) {
            open = true;
        }
        */

        this.setState({starting_open: open, open: open});
    }

    toggle_open(){
        if (this.state.open){
            this.setState({open: false, been_clicked: true});
        }
        else {
            this.setState({open: true, been_clicked: true});
        }
    }

    render() {
        var global_state = window.cmState.getGlobalState();
        var name = this.props.name;
        var index = this.props.index;
        if (this.props.header){
            name = this.props.header;
        }

        var dataParent = '#accordion-' + index;
        if (this.props.multiple == true) {
            dataParent = '#accordion-' + index;
        }

        var collapsed = 'collapsed collapse';
        var chevron_style = {
            position: 'absolute',
            top: '14px',
            right: '14px',
            color: '#666'
        };
        var chevron = <div style={chevron_style} ><i class="fas fa-2x fa-chevron-right"></i></div>;
        if (this.state.starting_open == true && !(this.state.been_clicked)) {
            collapsed += ' show';
        }
        if (this.state.open){
            chevron = <div style={chevron_style} ><i class="fas fa-2x fa-chevron-down"></i></div>;
            collapsed += ' show';
        }

        var header_style = {
            border: 'none',
            padding: '0px',
            display: 'block',
            width: '100%',
            textAlign: 'left',
            cursor: 'pointer',
            position: 'relative'
        };

        var bottom_toggle = <button style={{float:'right', margin:'15px'}} onClick={this.toggle_open} className="btn btn-default" data-toggle="collapse" data-target={"#collapse" + index} aria-expanded="true" aria-controls={"collapse" + index}>
            <i class="fas fa-chevron-up"></i>
            <span style={{paddingLeft:'5px'}} >{'Collapse Section'}</span>
        </button>;

        var sub_total = null;
        if (this.props.sub_total && global_state['form_answers']){
            var total_style = {
                height: '0px',
                float: 'right',
                position: 'absolute',
                top: '21px',
                right: '60px',
            };
            var subtotal_value = global_state['form_answers'][this.props.sub_total] ? global_state['form_answers'][this.props.sub_total] : '0';
            sub_total = <div style={total_style} >{'Subtotal: ' + subtotal_value}</div>;
        }

        var content = <div>
            <div id={"heading" + index}>
                <h4 style={{textAlign:'left', margin:'0px'}}>
                    <button onClick={this.toggle_open} style={header_style} type="button"
                        data-toggle="collapse" data-target={"#collapse" + index} aria-expanded="true" aria-controls={"collapse" + index}>
                        {sub_total}
                        {chevron}
                        {name}
                    </button>
                </h4>
            </div>
            <div className="">
                <div id={"collapse" + index} className={collapsed} aria-labelledby={"heading" + index} data-parent={dataParent}>
                    <div>
                        {this.props.children}

                        {bottom_toggle}
                        <div style={{clear:'both'}}></div>
                    </div>
                </div>
            </div>
        </div>;

        return (
            <div key={index} className={"accordion " + this.props.className} style={this.props.style} id="accordion">
                {content}
            </div>
        );
    }
}

export default Accordion;

import React, { Component } from 'react';
import {Wrapper, Container, Header, Div, Paragraph, FormWithChildren, DateTimePicker, TextInput, Select, TextArea, UserSearchInput, SignatureInput, ImageInput, Accordion} from 'library';

function get_form_elements(){
    var elements = [
		<Accordion index={9037} header={<div className={"question-card"} name={"9037_0"} excel_reference={1} style={{fontSize: '24px'}}  >
				<Paragraph text={"Quality Control Inspection"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"9038_0,9041_0,9042_0,9043_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"9038_0"} label={"Select Position Below If QC Form is Completed by a Supervisor or Manager"} options={[{"text": "Supervisor", "value": "Supervisor"}, {"text": "Area Supervisor", "value": "Area Supervisor"}, {"text": "Dir/Mgr of Operations", "value": "Dir/Mgr of Operations"}, {"text": "Vice President", "value": "Vice President"}]} radio={true} excel_reference={2}  />
			<TextInput className={"question-card"} name={"9041_0"} label={"Area"} excel_reference={5} required={true}  />
			<TextInput className={"question-card"} name={"9042_0"} label={"Completed by:"} excel_reference={6} required={true}  />
			<TextInput className={"question-card"} name={"9043_0"} label={"Supervisor"} excel_reference={7} required={true}  />
			<div className={"question-card"} name={"9044_0"} excel_reference={8}  >
				<Paragraph text={"5 \u2013 Perfect (exceeds contract requirements/no issues)  "} style={{margin:"0px", fontSize:"inherit"}} />
				<Paragraph text={"4 \u2013 Good (meets contract requirements)  "} style={{margin:"0px", fontSize:"inherit"}} />
				<Paragraph text={"3 \u2013 Fair (a couple {1 or 2} issues, Work Order required)  "} style={{margin:"0px", fontSize:"inherit"}} />
				<Paragraph text={"2 \u2013 Poor (multiple {3 to 5} issues, Work Order required)  "} style={{margin:"0px", fontSize:"inherit"}} />
				<Paragraph text={"1 \u2013 Bad (numerous {more than 5} issues, Work Order required)  "} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
		</Accordion>,
		<Accordion index={9045} header={<div className={"question-card"} name={"9045_0"} excel_reference={9} anchor={true} anchor_text={"MAIN ENTRANCE / LOBBY / ELEVAT"} style={{fontSize: '24px'}}  >
				<Paragraph text={"MAIN ENTRANCE / LOBBY / ELEVATORS"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"9046_0,9047_0,9048_0,9049_0,9050_0,9051_0,9052_0,9053_0,9054_0,9055_0,9056_0"} sub_total={"9061_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"9046_0"} label={"Door Glass / Side Glass - Main entrance"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={10}  />
			<Select className={"question-card"} name={"9047_0"} label={"Walk Off Mats / Corners & Edges"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={11}  />
			<Select className={"question-card"} name={"9048_0"} label={"Floors - Sweep, Mop, Buff & Vacuum"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={12}  />
			<Select className={"question-card"} name={"9049_0"} label={"Carpets Vacuumed  / Spot Clean Stains"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={13}  />
			<Select className={"question-card"} name={"9050_0"} label={"Main Elevator Cabs - Interior & Exterior Directional Plates, Tracks"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={14}  />
			<Select className={"question-card"} name={"9051_0"} label={"Escalators & Landings"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={15}  />
			<Select className={"question-card"} name={"9052_0"} label={"Ice Machine"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={16}  />
			<Select className={"question-card"} name={"9053_0"} label={"High Low Dusting / A/C Vents"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={17}  />
			<Select className={"question-card"} name={"9054_0"} label={"Trash Containers / Ash Urns"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={18}  />
			<Select className={"question-card"} name={"9055_0"} label={"Lobby Furniture & Fixtures"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={19}  />
			<Select className={"question-card"} name={"9056_0"} label={"Building Exterior (police area)"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={20}  />
			<TextInput className={"question-card"} name={"9057_0"} label={"COMMENTS"} excel_reference={21} fail_comment={true}  />
			<div className={"question-card"} name={"9058_0"} excel_reference={22}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"9059_0"} excel_reference={23}  />
			<Select className={"question-card"} name={"9060_0"} label={"Create Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={24}  />
			<TextInput className={"question-card"} name={"9061_0"} label={"Total Rating - Maximum Score - 55"} excel_reference={25} excel={'+Q10+Q11+Q12+Q13+Q14+Q15+Q16+Q17+Q18+Q19+Q20'}  />
		</Accordion>,
		<Accordion index={9062} header={<div className={"question-card"} name={"9062_0"} excel_reference={26} anchor={true} anchor_text={"COMMON AREAS"} style={{fontSize: '24px'}}  >
				<Paragraph text={"COMMON AREAS"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"9063_0,9064_0,9065_0,9066_0,9067_0,9068_0,9069_0,9070_0,9071_0"} sub_total={"9076_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"9063_0"} label={"Drinking Fountains         "} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={27}  />
			<Select className={"question-card"} name={"9064_0"} label={"Stairway"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={28}  />
			<Select className={"question-card"} name={"9065_0"} label={"Elevators, Cabs, Tracks, Panels"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={29}  />
			<Select className={"question-card"} name={"9066_0"} label={"Telephones"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={30}  />
			<Select className={"question-card"} name={"9067_0"} label={"Interior Glass"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={31}  />
			<Select className={"question-card"} name={"9068_0"} label={"High Low Dusting / A/C Vents"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={32}  />
			<Select className={"question-card"} name={"9069_0"} label={"Floors - Sweep, Mop, Buff & Vacuum"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={33}  />
			<Select className={"question-card"} name={"9070_0"} label={"Carpets Vacuumed  / Spot Clean Stains"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={34}  />
			<Select className={"question-card"} name={"9071_0"} label={"Wall Spotting / Corners & Edges"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={35}  />
			<TextInput className={"question-card"} name={"9072_0"} label={"COMMENTS"} excel_reference={36} fail_comment={true}  />
			<div className={"question-card"} name={"9073_0"} excel_reference={37}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"9074_0"} excel_reference={38}  />
			<Select className={"question-card"} name={"9075_0"} label={"Create Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={39}  />
			<TextInput className={"question-card"} name={"9076_0"} label={"Total Rating - Maximum Score - 45"} excel_reference={40} excel={'+Q27+Q28+Q29+Q30+Q31+Q32+Q33+Q34+Q35'}  />
		</Accordion>,
		<Accordion index={9077} header={<div className={"question-card"} name={"9077_0"} excel_reference={41} anchor={true} anchor_text={"OFFICES / CONFERENCE ROOMS"} style={{fontSize: '24px'}}  >
				<Paragraph text={"OFFICES / CONFERENCE ROOMS"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"9078_0,9079_0,9080_0,9081_0,9082_0,9083_0,9084_0,9085_0,9086_0,9087_0,9088_0"} sub_total={"9093_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"9078_0"} label={"Trash Removed & Liner Replaced"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={42}  />
			<Select className={"question-card"} name={"9079_0"} label={"Carpets Vacuumed  / Spot Clean Stains"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={43}  />
			<Select className={"question-card"} name={"9080_0"} label={"Floors - Sweep, Mop, Buff & Vacuum"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={44}  />
			<Select className={"question-card"} name={"9081_0"} label={"Vacuum / Sweep Corners, Edges, & Under Furniture"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={45}  />
			<Select className={"question-card"} name={"9082_0"} label={"Interior Glass"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={46}  />
			<Select className={"question-card"} name={"9083_0"} label={"Telephones"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={47}  />
			<Select className={"question-card"} name={"9084_0"} label={"High Low Dusting"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={48}  />
			<Select className={"question-card"} name={"9085_0"} label={"Blinds / Window Sills Dusted"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={49}  />
			<Select className={"question-card"} name={"9086_0"} label={"Furniture, File Cabinets, Counter Tops, Etc."} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={50}  />
			<Select className={"question-card"} name={"9087_0"} label={"Wall Spotting (Finger Prints, Smudges, Etc.)"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={51}  />
			<Select className={"question-card"} name={"9088_0"} label={"A/C Vents & Return Air Vents"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={52}  />
			<TextInput className={"question-card"} name={"9089_0"} label={"COMMENTS"} excel_reference={53} fail_comment={true}  />
			<div className={"question-card"} name={"9090_0"} excel_reference={54}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"9091_0"} excel_reference={55}  />
			<Select className={"question-card"} name={"9092_0"} label={"Create Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={56}  />
			<TextInput className={"question-card"} name={"9093_0"} label={"Total Rating - Maximum Score - 55"} excel_reference={57} excel={'+Q42+Q43+Q44+Q45+Q46+Q47+Q48+Q49+Q50+Q51+Q52'}  />
		</Accordion>,
		<Accordion index={9094} header={<div className={"question-card"} name={"9094_0"} excel_reference={58} anchor={true} anchor_text={"REST ROOMS / LOCKER ROOMS"} style={{fontSize: '24px'}}  >
				<Paragraph text={"REST ROOMS / LOCKER ROOMS"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"9095_0,9096_0,9097_0,9098_0,9099_0,9100_0,9101_0,9102_0,9103_0,9104_0,9105_0"} sub_total={"9110_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"9095_0"} label={"Trash Removed & Liner Replaced"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={59}  />
			<Select className={"question-card"} name={"9096_0"} label={"Clean & Polish Stainless Steel Fixtures"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={60}  />
			<Select className={"question-card"} name={"9097_0"} label={"Replenish Tissue, Towels, & Soap Dispensers"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={61}  />
			<Select className={"question-card"} name={"9098_0"} label={"Mirrors & Frames"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={62}  />
			<Select className={"question-card"} name={"9099_0"} label={"High & Low Dusting"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={63}  />
			<Select className={"question-card"} name={"9100_0"} label={"Sinks, Faucets, & Counter Tops"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={64}  />
			<Select className={"question-card"} name={"9101_0"} label={"Floors Swept, Mopped"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={65}  />
			<Select className={"question-card"} name={"9102_0"} label={"Toilets / Urinals - Bowls, Seats, Rim, Plumbing Fixtures"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={66}  />
			<Select className={"question-card"} name={"9103_0"} label={"Partition Walls, Doors, Etc."} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={67}  />
			<Select className={"question-card"} name={"9104_0"} label={"A/C Vents & Return Air Vents"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={68}  />
			<Select className={"question-card"} name={"9105_0"} label={"Do the shower curtains need to be replaced? "} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={69}  />
			<TextInput className={"question-card"} name={"9106_0"} label={"COMMENTS"} excel_reference={70} fail_comment={true}  />
			<div className={"question-card"} name={"9107_0"} excel_reference={71}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"9108_0"} excel_reference={72}  />
			<Select className={"question-card"} name={"9109_0"} label={"Create Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={73}  />
			<TextInput className={"question-card"} name={"9110_0"} label={"Total Rating - Maximum Score - 50"} excel_reference={74} excel={'+Q59+Q60+Q61+Q62+Q63+Q64+Q65+Q66+Q67+Q68'}  />
		</Accordion>,
		<Accordion index={9111} header={<div className={"question-card"} name={"9111_0"} excel_reference={75} anchor={true} anchor_text={" BREAK ROOMS / CAFETERIA"} style={{fontSize: '24px'}}  >
				<Paragraph text={" BREAK ROOMS / CAFETERIA"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"9112_0,9113_0,9114_0,9115_0,9116_0,9117_0,9118_0"} sub_total={"9123_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"9112_0"} label={"Floors Swept, Mopped, Vacuum & Buff"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={76}  />
			<Select className={"question-card"} name={"9113_0"} label={"High & Low Dusting"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={77}  />
			<Select className={"question-card"} name={"9114_0"} label={"Tables / Chairs"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={78}  />
			<Select className={"question-card"} name={"9115_0"} label={"A/C Vents & Return Air Vents"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={79}  />
			<Select className={"question-card"} name={"9116_0"} label={"Trash  removed & liners replaced"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={80}  />
			<Select className={"question-card"} name={"9117_0"} label={"Counter Tops, Sinks, & Back Splash Areas"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={81}  />
			<Select className={"question-card"} name={"9118_0"} label={"Wall Spotting (Finger Prints, Smudges)"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={82}  />
			<TextInput className={"question-card"} name={"9119_0"} label={"COMMENTS"} excel_reference={83} fail_comment={true}  />
			<ImageInput className={"question-card"} name={"9121_0"} excel_reference={85}  />
			<Select className={"question-card"} name={"9122_0"} label={"Create Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={86}  />
			<TextInput className={"question-card"} name={"9123_0"} label={"Total Rating - Maximum Score - 35"} excel_reference={87} excel={'+Q76+Q77+Q78+Q79+Q80+Q81+Q82'}  />
		</Accordion>,
		<Accordion index={9124} header={<div className={"question-card"} name={"9124_0"} excel_reference={88} anchor={true} anchor_text={"JANITOR CLOSET / STORAGE AREA"} style={{fontSize: '24px'}}  >
				<Paragraph text={"JANITOR CLOSET / STORAGE AREA"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"9125_0,9126_0"} sub_total={"9131_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"9125_0"} label={"Mop Sink & Floors"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={89}  />
			<Select className={"question-card"} name={"9126_0"} label={"Supplies/Equipment (Stored neat & orderly)"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={90}  />
			<TextInput className={"question-card"} name={"9127_0"} label={"COMMENTS"} excel_reference={91} fail_comment={true}  />
			<div className={"question-card"} name={"9128_0"} excel_reference={92}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"9129_0"} excel_reference={93}  />
			<Select className={"question-card"} name={"9130_0"} label={"Create Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={94}  />
			<TextInput className={"question-card"} name={"9131_0"} label={"Total Rating - Maximum Score - 10"} excel_reference={95} excel={'+Q89+Q90'}  />
			<Select className={"question-card"} name={"9133_0"} label={"DID YOU REQUEST ANY WORK ORDERS?"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={97} required={true}  />
		</Accordion>,
		<Accordion index={9135} header={<div className={"question-card"} name={"9135_0"} excel_reference={99} anchor={true} anchor_text={"CAN WE PROVIDE ADDITIONAL SERV"} style={{fontSize: '24px'}}  >
				<Paragraph text={"CAN WE PROVIDE ADDITIONAL SERVICES (TAGS)?  What extra services can we suggest to make the buildings look better?"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"9136_0,9137_0,9138_0,9139_0,9140_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"9136_0"} label={"Do any areas need pressure washing? "} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={100}  />
			<Select className={"question-card"} name={"9137_0"} label={"Do we need to shampoo carpet before periodics are due?"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={101}  />
			<Select className={"question-card"} name={"9138_0"} label={"Do we need to strip & wax floor before periodics are due?"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={102}  />
			<Select className={"question-card"} name={"9139_0"} label={"Do we need to provide any exterior window cleaning?"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={103}  />
			<Select className={"question-card"} name={"9140_0"} label={"Can we provide a maintenance technician?"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={104}  />
		</Accordion>,
		<TextInput className={"question-card"} name={"9132_0"} label={"Total Score"} excel_reference={96} anchor={true} anchor_text={"Total Score"} excel={'+Q25+Q40+Q57+Q74+Q87+Q95'}  />,
		<TextInput className={"question-card"} name={"9134_0"} label={"Percentage "} excel_reference={98} excel={'+Q96/250*100'}  />,
    ];

    return elements;
}

export default get_form_elements;

import React, { Component } from 'react';
import { ajaxWrapper } from 'functions';

import { Header, Container, FormWithChildren, TextInput } from 'library';
import Navbar from 'projectLibrary/navbar.js';
import Footer from 'projectLibrary/footer.js';

export default class ManageUserDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            users: [],
        };
    }

    componentDidMount() {
        ajaxWrapper('GET', '/users/get_users_on_process/', {}, (value) =>
            this.setState({ users: value }),
        );
    }

    render() {
        let user_forms = [];
        for (let item of this.state.users) {
            let user = item['user'];
            user_forms.push(
                <div className="question-card">
                    <FormWithChildren
                        key={user['id']}
                        defaults={user}
                        submitUrl={`/api/user/user/${user['id']}/`}
                        submit_text="Save Details"
                        submitButtonType="default"
                        submitClassName="float-right"
                    >
                        <div className="row">
                            <div className="col-6">
                                <b>{user['email']}</b>
                            </div>
                            <div className="col-6">
                                <TextInput name="name" label="Name" />
                                <TextInput
                                    name="supervisor"
                                    label="Supervisor Name"
                                />
                            </div>
                        </div>
                    </FormWithChildren>
                </div>,
            );
        }

        return (
            <div>
                <Navbar />

                <Container style={{ background: 'white' }} min_height={true}>
                    <br />
                    <a
                        href="/profile"
                        style={{ display: 'inline-block', padding: '5px' }}
                    >
                        <span
                            class="fa fa-arrow-left"
                            style={{ marginRight: '10px' }}
                        ></span>{' '}
                        Return to Profile
                    </a>

                    <Header size={3} text={'Edit User Detials'} />
                    <div className="formlist">{user_forms}</div>

                    <br />
                    <br />
                    <br />
                    <br />
                </Container>

                <Footer />
            </div>
        );
    }
}

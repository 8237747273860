import React, { Component } from 'react';
import {Wrapper, Container, Header, Div, Paragraph, FormWithChildren, DateTimePicker, TextInput, Select, TextArea, UserSearchInput, SignatureInput, ImageInput, Accordion} from 'library';

function get_form_elements(){
    var elements = [
		<Accordion index={7265} header={<div className={"question-card"} name={"7265_0"} excel_reference={1} style={{fontSize: '24px'}}  >
				<Paragraph text={"QUALITY CONTROL INSPECTION"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"7266_0,7269_0,7270_0,7271_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"7266_0"} label={"Select Position Below if QC Form is Completed by a Supervisor or Manager"} options={[{"text": "Supervisor", "value": "Supervisor"}, {"text": "Area Supervisor", "value": "Area Supervisor"}, {"text": "Dir/Mgr of Operations", "value": "Dir/Mgr of Operations"}, {"text": "Vice President", "value": "Vice President"}]} radio={true} excel_reference={2}  />
			<TextInput className={"question-card"} name={"7269_0"} label={"Area:"} excel_reference={5} required={true}  />
			<TextInput className={"question-card"} name={"7270_0"} label={"Completed by:"} excel_reference={6} required={true}  />
			<TextInput className={"question-card"} name={"7271_0"} label={"Supervisor"} excel_reference={7} required={true}  />
			<div className={"question-card"} name={"7272_0"} excel_reference={8}  >
				<Paragraph text={"5 \u2013 Perfect (exceeds contract requirements/no issues)   "} style={{margin:"0px", fontSize:"inherit"}} />
				<Paragraph text={"4 \u2013 Good (meets contract requirements)   "} style={{margin:"0px", fontSize:"inherit"}} />
				<Paragraph text={"3 \u2013 Fair (a couple {1 or 2} issues, Work Order required)  "} style={{margin:"0px", fontSize:"inherit"}} />
				<Paragraph text={"2 \u2013 Poor (multiple {3 to 5} issues, Work Order required)   "} style={{margin:"0px", fontSize:"inherit"}} />
				<Paragraph text={"1 \u2013 Bad (numerous {more than 5} issues, Work Order required)   "} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
		</Accordion>,
		<Accordion index={7273} header={<div className={"question-card"} name={"7273_0"} excel_reference={9} anchor={true} anchor_text={"MAIN ENTRANCE/LOBBY"} style={{fontSize: '24px'}}  >
				<Paragraph text={"MAIN ENTRANCE/LOBBY"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"7274_0,7275_0,7276_0,7277_0"} sub_total={"7282_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"7274_0"} label={"Door Glass / Side Glass - Main entrance"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={10}  />
			<Select className={"question-card"} name={"7275_0"} label={"Floors - Corners &amp; Edges / Walk-Off Mats"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={11}  />
			<Select className={"question-card"} name={"7276_0"} label={"Trash Containers / Ash Urns"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={12}  />
			<Select className={"question-card"} name={"7277_0"} label={"Lobby Furniture &amp; Fixtures"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={13}  />
			<TextInput className={"question-card"} name={"7278_0"} label={"COMMENTS"} excel_reference={14} fail_comment={true}  />
			<div className={"question-card"} name={"7279_0"} excel_reference={15}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"7280_0"} excel_reference={16}  />
			<Select className={"question-card"} name={"7281_0"} label={"Create Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={17}  />
			<TextInput className={"question-card"} name={"7282_0"} label={"Total Rating - Maximum Score 20"} excel_reference={18} excel={'Q10+Q11+Q12+Q13'}  />
		</Accordion>,
		<Accordion index={7283} header={<div className={"question-card"} name={"7283_0"} excel_reference={19} anchor={true} anchor_text={"COMMON AREAS"} style={{fontSize: '24px'}}  >
				<Paragraph text={"COMMON AREAS"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"7284_0,7285_0,7286_0,7287_0,7288_0,7289_0,7290_0,7291_0,7292_0,7293_0"} sub_total={"7298_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"7284_0"} label={"Stairway/Ramp; Landings"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={20}  />
			<Select className={"question-card"} name={"7285_0"} label={"Interior/Exterior Glass"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={21}  />
			<Select className={"question-card"} name={"7286_0"} label={"High Dusting"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={22}  />
			<Select className={"question-card"} name={"7287_0"} label={"Low Dusting"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={23}  />
			<Select className={"question-card"} name={"7288_0"} label={"A/C Vents &amp; Return Air Vents"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={24}  />
			<Select className={"question-card"} name={"7289_0"} label={"Carpets Vacuumed / Spot Clean Stains"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={25}  />
			<Select className={"question-card"} name={"7290_0"} label={"Elevators/Tracks/Walls"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={26}  />
			<Select className={"question-card"} name={"7291_0"} label={"Hard Surface Floors (Swept, Mopped, Buffed)"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={27}  />
			<Select className={"question-card"} name={"7292_0"} label={"Corners, Edges, Baseboards"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={28}  />
			<Select className={"question-card"} name={"7293_0"} label={"Wall Spotting"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={29}  />
			<TextInput className={"question-card"} name={"7294_0"} label={"COMMENTS"} excel_reference={30} fail_comment={true}  />
			<div className={"question-card"} name={"7295_0"} excel_reference={31}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"7296_0"} excel_reference={32}  />
			<Select className={"question-card"} name={"7297_0"} label={"Create Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={33}  />
			<TextInput className={"question-card"} name={"7298_0"} label={"Total Rating - Maximum Score 50"} excel_reference={34} excel={'+Q20+Q21+Q22+Q23+Q24+Q25+Q26+Q27+Q28+Q29'}  />
		</Accordion>,
		<Accordion index={7299} header={<div className={"question-card"} name={"7299_0"} excel_reference={35} anchor={true} anchor_text={"OFFICES/ADMIN. AREAS"} style={{fontSize: '24px'}}  >
				<Paragraph text={"OFFICES/ADMIN. AREAS"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"7300_0,7301_0,7302_0,7303_0,7304_0,7305_0,7306_0,7307_0"} sub_total={"7312_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"7300_0"} label={"Trash Removed &amp; Liner Replaced"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={36}  />
			<Select className={"question-card"} name={"7301_0"} label={"Carpets Vacuumed &amp; Stains Spot Cleaned"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={37}  />
			<Select className={"question-card"} name={"7302_0"} label={"Vacuum Corners, Edges, &amp; Under Furniture"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={38}  />
			<Select className={"question-card"} name={"7303_0"} label={"Interior Glass"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={39}  />
			<Select className={"question-card"} name={"7304_0"} label={"High Dusting"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={40}  />
			<Select className={"question-card"} name={"7305_0"} label={"Low Dusting"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={41}  />
			<Select className={"question-card"} name={"7306_0"} label={"Blinds / Window Sills Dusted"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={42}  />
			<Select className={"question-card"} name={"7307_0"} label={"Hard Surface Floors (Swept, Mopped, Buffed)"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={43}  />
			<TextInput className={"question-card"} name={"7308_0"} label={"COMMENTS"} excel_reference={44} fail_comment={true}  />
			<div className={"question-card"} name={"7309_0"} excel_reference={45}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"7310_0"} excel_reference={46}  />
			<Select className={"question-card"} name={"7311_0"} label={"Create Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={47}  />
			<TextInput className={"question-card"} name={"7312_0"} label={"Total Rating - Maximum Score 40"} excel_reference={48} excel={'+Q36+Q37+Q38+Q39+Q40+Q41+Q42+Q43'}  />
		</Accordion>,
		<Accordion index={7313} header={<div className={"question-card"} name={"7313_0"} excel_reference={49} anchor={true} anchor_text={"RESTROOMS"} style={{fontSize: '24px'}}  >
				<Paragraph text={"RESTROOMS"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"7314_0,7315_0,7316_0,7317_0,7318_0,7319_0,7320_0,7321_0,7322_0,7323_0,7324_0,7325_0,7326_0"} sub_total={"7345_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"7314_0"} label={"Trash Removed &amp; Liner Replaced"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={50}  />
			<Select className={"question-card"} name={"7315_0"} label={"Clean & Polish Stainless Steel Fixtures"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={51}  />
			<Select className={"question-card"} name={"7316_0"} label={"Replenish Tissue, Towels, &amp; Soap Dispensers"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={52}  />
			<Select className={"question-card"} name={"7317_0"} label={"Mirrors &amp; Frames"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={53}  />
			<Select className={"question-card"} name={"7318_0"} label={"Showers"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={54}  />
			<Select className={"question-card"} name={"7319_0"} label={"High Dusting"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={55}  />
			<Select className={"question-card"} name={"7320_0"} label={"Low Dusting"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={56}  />
			<Select className={"question-card"} name={"7321_0"} label={"Sinks, Faucets, &amp; Counter Tops"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={57}  />
			<Select className={"question-card"} name={"7322_0"} label={"Floors Swept, Mopped, &amp; Scrubbed"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={58}  />
			<Select className={"question-card"} name={"7323_0"} label={"Toilets - Bowls, Seats, Rim, Plumbing Fixtures"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={59}  />
			<Select className={"question-card"} name={"7324_0"} label={"Urinals - Bowl, Rim, Plumbing Fixtures"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={60}  />
			<Select className={"question-card"} name={"7325_0"} label={"Partition Walls, Doors, Etc."} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={61}  />
			<Select className={"question-card"} name={"7326_0"} label={"A/C Vents &amp; Return Air Vents"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={62}  />
			<TextInput className={"question-card"} name={"7327_0"} label={"COMMENTS"} excel_reference={63} fail_comment={true}  />
			<div className={"question-card"} name={"7328_0"} excel_reference={64}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"7329_0"} excel_reference={65}  />
			<Select className={"question-card"} name={"7330_0"} label={"Create Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={66}  />
			<TextInput className={"question-card"} name={"7331_0"} label={"Total Rating - Maximum Score 65"} excel_reference={67} excel={'+Q50+Q51+Q52+Q53+Q54+Q55+Q56+Q57+Q58+Q59+Q60+Q61+Q62'}  />
			<Select className={"question-card"} name={"7333_0"} label={"Floors Swept, Mopped, Vacuum &amp; Buffed"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={69}  />
			<Select className={"question-card"} name={"7334_0"} label={"High Dusting"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={70}  />
			<Select className={"question-card"} name={"7335_0"} label={"Low Dusting"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={71}  />
			<Select className={"question-card"} name={"7336_0"} label={"Tables / Chairs"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={72}  />
			<Select className={"question-card"} name={"7337_0"} label={"A/C Vents &amp; Return Air Vents"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={73}  />
			<Select className={"question-card"} name={"7338_0"} label={"Trash Removed &amp; Liner Replaced"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={74}  />
			<Select className={"question-card"} name={"7339_0"} label={"Counter Tops, Sinks, &amp; Back Splash Areas"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={75}  />
			<Select className={"question-card"} name={"7340_0"} label={"Wall Spotting (Finger Prints, Smudges)"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={76}  />
			<TextInput className={"question-card"} name={"7341_0"} label={"COMMENTS"} excel_reference={77} fail_comment={true}  />
			<div className={"question-card"} name={"7342_0"} excel_reference={78}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"7343_0"} excel_reference={79}  />
			<Select className={"question-card"} name={"7344_0"} label={"Create Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={80}  />
			<TextInput className={"question-card"} name={"7345_0"} label={"Total Rating - Maximum Score 40"} excel_reference={81} excel={'+Q69+Q70+Q71+Q72+Q73+Q74+Q75+Q76'}  />
		</Accordion>,
		<Accordion index={7346} header={<div className={"question-card"} name={"7346_0"} excel_reference={82} anchor={true} anchor_text={"CHECKLISTS"} style={{fontSize: '24px'}}  >
				<Paragraph text={"CHECKLISTS"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"7347_0"} sub_total={"7352_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"7347_0"} label={"Are the Checklists on Site and being Utilized?"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={83}  />
			<TextInput className={"question-card"} name={"7348_0"} label={"COMMENTS"} excel_reference={84} fail_comment={true}  />
			<div className={"question-card"} name={"7349_0"} excel_reference={85}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"7350_0"} excel_reference={86}  />
			<Select className={"question-card"} name={"7351_0"} label={"Create Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={87}  />
			<TextInput className={"question-card"} name={"7352_0"} label={"Total Rating - Maximum Score 5"} excel_reference={88} excel={'+Q83'}  />
		</Accordion>,
		<Accordion index={7353} header={<div className={"question-card"} name={"7353_0"} excel_reference={89} anchor={true} anchor_text={"JANITOR CLOSET/STORAGE AREA"} style={{fontSize: '24px'}}  >
				<Paragraph text={"JANITOR CLOSET/STORAGE AREA"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"7354_0,7355_0,7356_0"} sub_total={"7361_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"7354_0"} label={"Mop Sink &amp; Floors"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={90}  />
			<Select className={"question-card"} name={"7355_0"} label={"Supplies/Equipment (Stored neat &amp; orderly)"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={91}  />
			<Select className={"question-card"} name={"7356_0"} label={"MSDS Binder up to date"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={92}  />
			<TextInput className={"question-card"} name={"7357_0"} label={"COMMENTS"} excel_reference={93} fail_comment={true}  />
			<div className={"question-card"} name={"7358_0"} excel_reference={94}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"7359_0"} excel_reference={95}  />
			<Select className={"question-card"} name={"7360_0"} label={"Create Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={96}  />
			<TextInput className={"question-card"} name={"7361_0"} label={"Total Rating - Maximum Score - 15"} excel_reference={97} excel={'+Q90+Q91+Q92'}  />
			<Select className={"question-card"} name={"8917_0"} label={"DID YOU REQUEST ANY WORK ORDERS? "} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={99} required={true}  />
		</Accordion>,
		<Accordion index={7364} header={<div className={"question-card"} name={"7364_0"} excel_reference={101} anchor={true} anchor_text={"ADDITIONAL SERVICES"} style={{fontSize: '24px'}}  >
				<Paragraph text={"ADDITIONAL SERVICES"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"7366_0,7367_0,7368_0,7369_0,7370_0,7371_0,7372_0,7373_0,7374_0,7375_0,7376_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<div className={"question-card"} name={"7365_0"} excel_reference={102}  >
				<Paragraph text={"Can we provide additional services (TAGs)?  Can we provide additional services (TAGs)?  "} style={{margin:"0px", fontSize:"inherit"}} />
				<Paragraph text={"What extra services can we suggest to make the buildings look better?  "} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<TextInput className={"question-card"} name={"7366_0"} label={"If you had extra time, what cleaning tasks or projects would you do?  "} excel_reference={103}  />
			<Select className={"question-card"} name={"7367_0"} label={"Do any areas need pressure washing?  "} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={104}  />
			<TextInput className={"question-card"} name={"7368_0"} label={"If the answer to a question is yes please comment below"} excel_reference={105}  />
			<Select className={"question-card"} name={"7369_0"} label={"Do we need to shampoo carpet before periodics are due?  "} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={106}  />
			<TextInput className={"question-card"} name={"7370_0"} label={"If the answer to a question is yes please comment below"} excel_reference={107}  />
			<Select className={"question-card"} name={"7371_0"} label={"Do we need to strip &amp; wax the floors before periodics are due?  "} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={108}  />
			<TextInput className={"question-card"} name={"7372_0"} label={"If the answer to a question is yes please comment below"} excel_reference={109}  />
			<Select className={"question-card"} name={"7373_0"} label={"Do we need to provide any exterior window cleaning?  "} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={110}  />
			<TextInput className={"question-card"} name={"7374_0"} label={"If the answer to a question is yes please comment below"} excel_reference={111}  />
			<Select className={"question-card"} name={"7375_0"} label={"Can we provide a maintenance technician?  "} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={112}  />
			<TextInput className={"question-card"} name={"7376_0"} label={"If the answer to a question is yes please comment below"} excel_reference={113}  />
		</Accordion>,
		<TextInput className={"question-card"} name={"7362_0"} label={"RATING TOTAL"} excel_reference={98} anchor={true} anchor_text={"RATING TOTAL"} excel={'+Q18+Q34+Q48+Q67+Q81+Q88+Q97'}  />,
		<TextInput className={"question-card"} name={"7363_0"} label={"Percentage"} excel_reference={100} excel={'+Q98/235*100'}  />,
    ];

    return elements;
}

export default get_form_elements;

import React, { Component } from 'react';
import {Wrapper, Container, Header, Div, Paragraph, FormWithChildren, DateTimePicker, TextInput, Select, TextArea, UserSearchInput, SignatureInput, ImageInput, Accordion} from 'library';

function get_form_elements(){
    var elements = [
		<Accordion index={6921} header={<div className={"question-card"} name={"6921_0"} excel_reference={1} style={{fontSize: '24px'}}  >
				<Paragraph text={"Employee Recognition  "} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"6922_0,6923_0,6924_0,6925_0,6926_0,6927_0,6928_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"6922_0"} label={"1. Which company does the employee work for that you would like to recognize?  "} options={[{"text": "", "value": ""}, {"text": "Kleen-Tech", "value": "Kleen-Tech"}, {"text": "LANL", "value": "LANL"}, {"text": "SNL/CA", "value": "SNL/CA"}]} radio={true} excel_reference={2}  />
			<TextInput className={"question-card"} name={"6923_0"} label={"Your name (optional)"} excel_reference={3}  />
			<Select className={"question-card"} name={"6924_0"} label={"Company  you work for (optional)?"} options={[{"text": "", "value": ""}, {"text": "Kleen-Tech", "value": "Kleen-Tech"}, {"text": "LANL", "value": "LANL"}, {"text": "SNL/CA", "value": "SNL/CA"}]} radio={true} excel_reference={4}  />
			<TextInput className={"question-card"} name={"search_value"} label={"Who would you like to nominate?"} excel_reference={5}  />
			<TextInput className={"question-card"} name={"6926_0"} label={"What is the position title for your nominee (optional)? "} excel_reference={6}  />
			<TextInput className={"question-card"} name={"6927_0"} label={"What is the location of the nominee (optional)?  "} excel_reference={7}  />
			<TextInput className={"question-card"} name={"6928_0"} label={"Why does this person deserve to be recognized (the more details you provide, the better \u2013 please include the who, what, when, and how)?   "} excel_reference={8}  />
		</Accordion>,
    ];

    return elements;
}

export default get_form_elements;

import 'babel-polyfill';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';

import React, { Component } from 'react';
import './App.css';
import { ajaxWrapper, setGlobalState, clear_tokens } from 'functions';
import { Wrapper } from 'library';
import * as Sentry from '@sentry/browser';
import { Detector } from 'react-detect-offline';

//Admin
import AppList from './pages/admin/appList.js';
import ModelList from './pages/admin/modelsList.js';
import ModelMaker from './pages/model_builder/djangoModelMaker.js';
import InstanceList from './pages/admin/modelInstances.js';
import Instance from './pages/admin/instance.js';
import InstanceTable from './pages/admin/modelInstancesTable.js';

import LogIn from './pages/custom_project_pages/usermanagement/login.js';
import SignUpPage from './pages/custom_project_pages/usermanagement/signupform.js';
import PasswordReset from './pages/custom_project_pages/usermanagement/passwordreset.js';
import PasswordResetRequestPage from './pages/custom_project_pages/usermanagement/passwordresetrequest.js';

import ErrorBoundary from 'projectLibrary/error_boundary.js';
import BrokenComponent from 'projectLibrary/broken_component.js';

import UserList from './pages/custom_project_pages/usermanagement/userlist.js';
import EditUser from './pages/custom_project_pages/usermanagement/edituser.js';
import AdminEditUser from './pages/custom_project_pages/usermanagement/adminedituser.js';

import Viewfilegroup from './pages/custom_project_pages/startingpages/viewfilegroup.js';
import FileManager from './pages/custom_project_pages/startingpages/filemanager.js';
import CSSClasses from './pages/custom_project_pages/startingpages/cssclasses.js';
import EditCSSClass from './pages/custom_project_pages/startingpages/editcssclass.js';

//API Querying
import APIDocs from './pages/admin/apiDocs.js';
import IndexPage from './projectLibrary/landing_page.js';
import Profile from './projectLibrary/profile.js';
import ProcessSubmission from './projectLibrary/process/submission.js';
import ProcessResults from './projectLibrary/process/results.js';
import ProcessEdit from './projectLibrary/process/edit.js';

import EasyLogin from './projectLibrary/easy_login.js';
import Devices from './projectLibrary/devices.js';
import ManageUserDetails from './projectLibrary/manage_user_details.js';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            csrfmiddlewaretoken: undefined,
            user: {},
        };

        this.load_global_context = this.load_global_context.bind(this);
        this.load_user = this.load_user.bind(this);
    }

    componentDidMount() {
        this.load_global_context(window.secretReactVars.global_context);

        var params = this.getURL();
        var path = params[0].toLowerCase();

        var token = localStorage.getItem('token');

        var loginNoRedirects = [
            'login',
            'logout',
            'signup',
            'passwordresetrequest',
            'passwordreset',
            'admin',
            'easy_login',
            'process',
        ];

        if (token) {
            //ajaxWrapper("GET", "/users/user/", {}, this.load_user.bind(this), clear_tokens);
            this.load_user(window.secretReactVars.logged_in_user);

            this.setState({ token: token });
            if (path.indexOf('login') > -1 || path == '') {
                window.location.href = '/profile/';
            }
        } else if (
            params[0] == 'process' &&
            (params[2] == 'submission' || params[2] == 'review')
        ) {
            //Special exception for public processes
            this.setState({ loaded: true });
        } else if (
            loginNoRedirects.indexOf(path) == -1 &&
            window.location.pathname != '/'
        ) {
            window.location.href = '/login/';
        } else {
            this.setState({ loaded: true });
        }
    }

    load_user(result) {
        if (!result) {
            clear_tokens();
        } else {
            console.log('Load User Result', result);
            window.cmState.setGlobalState('user', result);
            this.setState({
                user: result,
                loaded: true,
            });
        }
    }

    logOut() {
        console.log('Log Out');
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('token_time');
        window.location.href = '/login/';
    }

    load_global_context(value) {
        window.secretReactVars['csrfmiddlewaretoken'] =
            value['csrfmiddlewaretoken'];
        window.secretReactVars['twofactor'] = value['twofactor'];
        window.secretReactVars['secure_uploads'] = value['secure_uploads'];

        this.setState({
            csrfmiddlewaretoken: value.csrfmiddlewaretoken,
            twofactor: value['twofactor'],
            secure_uploads: value['secure_uploads'],
        });
    }

    getURL() {
        var url = window.location.pathname;
        if (url[0] == '/') {
            url = url.substring(1);
        }
        if (url[url.length - 1] == '/') {
            url = url.substring(0, url.length - 1);
        }
        var url_split = url.split('/');

        var params = {};
        for (var index in url_split) {
            params[index] = url_split[index];
        }

        var href = window.location.href;
        if (href.indexOf('?') > -1) {
            console.log('Here');
            var post_params = href.split('?')[1];
            var split_params = post_params.split('&');
            console.log('Post Params', post_params, split_params);

            for (var index in split_params) {
                var temp = split_params[index];
                var temp_split = temp.split('=');
                params[temp_split[0]] = temp_split[1];
            }
        }

        return params;
    }

    detect_online(online) {
        if (online) {
            window.cmState.setGlobalState('online', true);
        } else {
            window.cmState.setGlobalState('online', false);
        }

        return null;
    }

    render() {
        var params = this.getURL();
        var param_dict = {};
        for (var index in params) {
            param_dict[index.toString()] = params[index];
        }
        window.cmState.setGlobalState('params', param_dict);
        window.cmState.setGlobalState('location', window.location);

        var adminPages = [
            'applist',
            'models',
            'modelinstances',
            'modelinstancestable',
            'instance',
            'manage_users',
        ];

        var loggedInPages = [];
        var route = params[0].toLowerCase();

        var loading = <h1>Loading . . . </h1>;
        var content = null;

        if (this.state.loaded) {
            if (
                adminPages.indexOf(route) > -1 &&
                this.state.loaded &&
                !this.state.user.is_staff
            ) {
                //window.location = '/';
                console.log('Not an admin', this.state.loaded, this.state.user);
            } else if (
                loggedInPages.indexOf(route) > -1 &&
                this.state.loaded &&
                typeof this.state.user.id != 'undefined'
            ) {
                //window.location = '/login/';
                console.log('Need to be logged in');
            } else if (route == 'logout') {
                this.logOut();
            } else if (route == 'login') {
                content = <LogIn />;
            } else if (route == 'signup') {
                content = <SignUpPage />;
            } else if (route == 'passwordreset') {
                content = <PasswordReset />;
            } else if (route == 'passwordresetrequest') {
                content = <PasswordResetRequestPage />;
            } else if (route == 'users') {
                content = <UserList />;
            } else if (route == 'edituser') {
                content = <EditUser />;
            } else if (route == 'adminedituser') {
                content = <AdminEditUser />;
            } else if (route == 'filegroup') {
                content = <Viewfilegroup id={params[1]} />;
            } else if (route == 'filemanager') {
                content = <FileManager />;
            } else if (route == 'css') {
                content = <CSSClasses />;
            } else if (route == 'css_class') {
                content = <EditCSSClass />;
            } else if (route == 'applist') {
                content = <AppList user_id={this.state.token} />;
            } else if (route == 'models') {
                content = (
                    <ModelList app={params[1]} user_id={this.state.token} />
                );
            } else if (route == 'modelinstances') {
                content = (
                    <InstanceList
                        app={params[1]}
                        model={params[2]}
                        user_id={this.state.token}
                    />
                );
            } else if (route == 'modelinstancestable') {
                content = <InstanceTable app={params[1]} model={params[2]} />;
            } else if (route == 'modelmaker') {
                content = <ModelMaker user_id={this.state.token} />;
            } else if (route == 'instance') {
                content = (
                    <Instance
                        app={params[1]}
                        model={params[2]}
                        id={params[3]}
                        user_id={this.state.token}
                    />
                );
            } else if (route == 'apidocs') {
                content = <APIDocs />;
            } else if (route == 'manage_users') {
                content = <ManageUserDetails />;
            } else if (route == '') {
                content = <IndexPage />;
            } else if (route == 'easy_login') {
                content = <EasyLogin string={params[1]} />;
            } else if (route == 'devices') {
                content = <Devices />;
            } else if (route == 'profile') {
                content = <Profile archived={params['archived']} />;
            } else if (route == 'process') {
                if (params[2] == 'submission') {
                    content = (
                        <ProcessSubmission
                            id={params[1]}
                            search_value={params['search_value']}
                            double_value={params['double_value']}
                        />
                    );
                }
                if (params[2] == 'review') {
                    content = (
                        <ProcessSubmission
                            id={params[1]}
                            review={true}
                            search_value={params['search_value']}
                            double_value={params['double_value']}
                        />
                    );
                } else if (params[2] == 'results') {
                    content = <ProcessResults process_id={params[1]} />;
                } else if (params[2] == 'edit') {
                    content = <ProcessEdit id={params[1]} />;
                }
            } else if (route == 'broken_page') {
                content = <BrokenComponent />;
            }
        }

        if (this.state.loaded == false) {
            return (
                <div className="App">
                    <ErrorBoundary>
                        <Wrapper
                            content={<div></div>}
                            loaded={this.state.loaded}
                        />
                        <Detector
                            polling={{ url: '/ping/' }}
                            render={({ online }) => this.detect_online(online)}
                        />
                    </ErrorBoundary>
                </div>
            );
        } else {
            return (
                <div className="App">
                    <ErrorBoundary>
                        <Wrapper
                            style={{ paddingTop: '60px' }}
                            content={content}
                            loaded={this.state.loaded}
                        />
                        <Detector
                            polling={{ url: '/ping/' }}
                            render={({ online }) => this.detect_online(online)}
                        />
                    </ErrorBoundary>
                </div>
            );
        }
    }
}

export default App;
